import React, { useState, useRef, useEffect } from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout"
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import Modal from "../../components/Modal";
import ImageAreaDoAtendente from "../../assets/images/illustration-areadoatendente.svg";
import ImageTalk from "../../assets/images/icon-talk.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AuthService, { isAuthenticated, TOKEN_KEY, CLIENT, UID, USER } from "../../services/AuthService"

import "moment/locale/pt-br";
import "react-day-picker/lib/style.css";
import "./style.css";
import Alert from "../../components/Alert";

interface FormData {
  email: string;
  password: string
}

export default function Login() {
  const [passwordShown, setPasswordShown] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated()) {
      history.push('/area_atendente');
    }
  }, []);

  const handleSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      formRef.current?.setErrors({});
      setLoading(true);
      if (formData.email == '' || formData.password == '') {
        setError("Preencha usuário e senha para continuar!");
        setLoading(false);
      }
      else {
        await AuthService.autenticar(formData.email, formData.password)
          .then((response) => {
            localStorage.setItem(USER, JSON.stringify(response.data.data));
            localStorage.setItem(CLIENT, response.headers[CLIENT]);
            localStorage.setItem(UID, response.headers[UID]);
            history.push('/area_atendente');
          }).catch((err) => {
            setLoading(false);
            setError("Houve um problema com o login, verifique suas credenciais.");
          }).finally(() => {
            setLoading(false);
          });
      }
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleHideModal = () => {
    setShowModal(false)
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <div className="container-login">
      <Layout title="Área do Atendente" className="full-container full-container-centered">
        <div className="row">
          <div className="col-sm-6 mb-5">
            <img className="img-fluid" src={ImageAreaDoAtendente} alt="Imagem da área do atendente" />
          </div>
          <div className="col-sm-6">
            <div className="col">
              <p className="font-weight-bold h1">Área do Atendente</p>
              <p>Acesse com seu e-mail e senha</p>
            </div>
            <Form className="form-login" ref={formRef} onSubmit={handleSubmit}>
              {error && (<Alert>{error}</Alert>)}
              <div className="row">
                <div className="col-12">
                  <Input label="Email" name="email" maxLength={50} autoFocus placeholder={"Digite seu Email"} />
                </div>
                <div className="col-12">
                  <div className="pass-wrapper">
                    <Input type={passwordShown ? "text" : "password"}
                      label="Senha" name="password" maxLength={50}
                      placeholder={"Digite sua senha"} data-toggle="password" />
                    <a onClick={togglePasswordVisiblity} className="icon-show-password" >
                      {passwordShown ? <FontAwesomeIcon icon={faEye} style={{ color: 'var(--green2)' }} /> : <FontAwesomeIcon icon={faEyeSlash} style={{ color: 'var(--green2)' }} />}
                    </a>
                  </div>
                </div>
                <div className="col-12 text-right">
                  <a className="link-forgot-password" href="#" onClick={handleShowModal}>Esqueceu sua senha?</a>
                </div>
                <div className="col-12 h6 mt-5">
                  Tem dúvidas? <a className="link-contact-us" href="#" onClick={handleShowModal}>Fale conosco</a>
                </div>
                <div className="col-12 mt-4">
                  <Button className="btn btn-primary btn-login" label="Acessar" loading={loading} />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Layout>
      <Modal show={showModal} onHide={handleHideModal} centered>
        <div className="container">
          <div className="text-center">
            <p>
              <img className="img-fluid" src={ImageTalk} alt="Imagem da área do atendente" />
            </p>
            <p className="modal-title">
              Fale com nosso suporte
            </p>
            <p className="modal-text-email mt-3 mb-5">
              suporte@pgm.fortaleza.ce.gov.br
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}
