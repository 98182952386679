import React, { useState } from 'react';
import Select, { ActionMeta, OptionsType, OptionTypeBase, StylesConfig } from 'react-select';
import './styles.scss';

interface Option {
  value: string;
  label: string;
}

type MultiSelectProps = {
  onChangeSelected: (options: Option[]) => void;
  optionType: Option[];
  contributorDocument: string;
  indexOf: string;
  placeholder: string
}

export function MultiSelect(props: MultiSelectProps) {

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const handleSelectChange = (
    selected: OptionsType<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    setSelectedOptions(Array.from(selected));
    props.onChangeSelected(Array.from(selected));
  };

  const customStyles: StylesConfig<Option, true> = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'var(--green2)',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      backgroundColor: '#E8E8E8',
      color: '#1A1818',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? 'transparent' : provided.borderColor,
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    }),
  };

  const CheckboxOption: React.FC<OptionTypeBase> = ({
    children,
    isSelected,
    innerProps,
  }) => (
    <div className="custom-option" {...innerProps}>
      <input
        className='custom-checkbox'
        type="checkbox"
        checked={isSelected}
        onChange={() => null}
      />
      {children}
    </div>
  );

  return (
    <div className='selectContainer'>
      <Select
        name={`${props.indexOf}`}
        id={`${props.contributorDocument}`}
        isMulti
        options={props.optionType}
        value={selectedOptions}
        onChange={handleSelectChange}
        components={{ Option: CheckboxOption }}
        styles={customStyles}
        placeholder={props.placeholder}
      />
    </div>
  );
}
