import React, { useEffect, useState } from "react";
import BalcaoVirtualService, { Balcao } from "../../services/BalcaoVirtualService";
import iconAtendenteCirculado from "../../assets/images/icon/icon-atendente-circulado.svg";
import "./style.css";
import ConsultModal from "../ContributorUpdateFormModal/ConsultModal";
import ConsultarService from "../../services/ConsultarService";
import { EmptyList } from "./EmptyList";
import WebBrowserAlert from "../WebBrowserAlert";
;

interface Props {
  show: boolean;
  onHide?(): void;
}

export default function ModalBalcaoVirtual({ show, onHide }: React.PropsWithChildren<Props>) {

  const [balcoes, setBalcoes] = useState<Balcao[]>([]);
  const [showModalBalcaoVirtual, setShowModalBalcaoVirtual] = useState(false);
  const [desklink, setdesklink] = useState("")
  const [attendantEmail, setAttendantEmail] = useState("");
  const [balcaoId, setBalcaoId] = useState<number | undefined>();
  const [avaliable, setAvaliable] = useState(show);

  const handleShowBalcaoVirtual = async (link : string, attendant_id : number, id: number) => {
    setdesklink(link);
    setShowModalBalcaoVirtual(true);
    setBalcaoId(id);
    await ConsultarService
    .attendant(attendant_id)
    .then((response) => {
      setAttendantEmail(response.email)
    })
  }

  const handleHideBalcaoVirtual = () => {
    setShowModalBalcaoVirtual(false);
  }

  const getBalcoes = async () => {
    await BalcaoVirtualService
      .balcoes()
      .then((response) => {
        setBalcoes(response.filter((b) => b.status !== 'unavailable'));
      })
  }

  useEffect(() => {
    if (avaliable) {
      setTimeout(() => {
        getBalcoes();
      }, 5000)
    }
  }, [balcoes])

  useEffect(() => {
    setAvaliable(show)
    if (show) {
      getBalcoes();
      $('#modalBalcao').modal('show');
    } else {
      $('#modalBalcao').modal('hide');
    }
  }, [show]);

  if (!show) {
    return null;
  }

  $('#modalBalcao').on('hide.bs.modal', () => {
    if (onHide) onHide();
  });

  const btnCardBalcao = (className: string, link: string, btnText: string, attendant_id: number, id: number) => {
    return (
      <a
        onClick={(e) => {e.preventDefault(); handleShowBalcaoVirtual(link, attendant_id, id)}}
        className={`btn mt-4 p-3 btn-modal ${className}`}
        href={'#'}>
        {btnText}
      </a>
    )
  }

  const verificarStatusBalcao = (status: string) => {
    switch (status) {
      case "available":
        return "Disponível para chamada. =)";
      case "busy":
        return "Atendente já está em chamada. Aguarde disponibilidade.";
      case "unavailable":
        return "Atendente não disponível no momento.";
    }
  };

  const renderBtn = (status: string, link: string, attendant_id: number, id: number) => {
    switch (status) {
      case "available":
        return (btnCardBalcao("available", link, "Iniciar sua chamada", attendant_id, id));
      case "busy":
        return (btnCardBalcao("disable", "#", "Ocupado", attendant_id, id));
      case "unavailable":
        return (btnCardBalcao("disable-unavailable", "#", "Indisponível", attendant_id, id));
    }
  };

  const formatAttendantNameToList = (name: string) => {
    if(name) {
      var names = name.split(' ');

      if (names.length === 1) {
        return <h5 className="card-title message"> <strong>{name}</strong></h5>
      }

      var firstName = names.shift();
      var lastName = names.join(' ');

      return (
        <h5 className="card-title ">
          <strong>
            <span>{firstName} <br/> {lastName}</span>
          </strong>
        </h5>
      )
    }
    return <h5 className="card-title message"> <strong>-</strong></h5>
  }

  return (
    <div id="modalBalcao" className="modal fade show modal-balcao-virtual" tabIndex={-1} role="dialog" style={{ display: "block" }}>
      { !showModalBalcaoVirtual || !balcaoId ?
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <button type="button" className="close btn-close-modal" data-dismiss="modal" aria-label="Close" onClick={onHide}>
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="header-balcao-virtual text-center ">
              <h5 className="modal-title mt-3 balcao-virtual-title">
                <strong>Inicie sua chamada</strong>
                <hr />
              </h5>
              <div className="px-5">
                <span className="h6 m-auto sub-title-modal">
                  Antes de iniciar seu atendimento,<br/>
                  tenha seu documento de identidade e comprovante de endereço em mãos.
                </span>
              </div>
            </div>
            <WebBrowserAlert />
            <div className="modal-body">
              <div className="container w-100">
                <div className="row d-flex justify-content-center">
                  {balcoes?.map((guiche, i) => {
                    return (
                      <div key={i} className="card column-4 mx-2 my-2 py-5 text-center col-md-4 col-lg-3">
                        <div className="container">
                          <img className="card-img-top icon-top-card" src={iconAtendenteCirculado} />
                        </div>
                        <div className="card-body">
                          <h5 className="card-title "><strong>Balcão</strong></h5>
                          {formatAttendantNameToList(guiche.attendant_name)}
                          <div className="card-balcao-info">
                            <p className="card-text">
                              {verificarStatusBalcao(guiche.status)}
                            </p>
                          </div>
                          {[8, 9].includes(guiche.id)
                            ? <p className="card-text mb-1">Prioritário para pagamento à vista</p>
                            : <p className="card-text alert-message"></p>}
                          {renderBtn(guiche.status, guiche.meet_link, guiche.attendant_id, guiche.id)}
                        </div>
                      </div>
                    )
                  })}
                  {balcoes.length === 0 && <EmptyList />}
                </div>
              </div>
            </div>
          </div>
        </div> :
        <div className="ContributorConsultModal mx-auto">
          <div className="m-3">
            <ConsultModal
              onHide={handleHideBalcaoVirtual}
              desklink={desklink}
              attendant={attendantEmail}
              balcaoId={balcaoId}
              stopReload={() => setAvaliable(false)}
            />
          </div>
        </div>
      }
    </div>
  );
}
