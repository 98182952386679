import React, { useEffect } from "react";
import "./style.css";

interface Props {
  show: boolean;
  onHide?(): void;
  logout?(): void;
}

export default function ModalConfirm({ show, onHide, logout }: React.PropsWithChildren<Props>) {
  useEffect(() => {
    if (show) {
      $('#modalLogout').modal('show');
    } else {
      $('#modalLogout').modal('hide');
    }
  }, [show]);

  if (!show) {
    return null;
  }

  $('#modalLogout').on('hide.bs.modal', () => {
    if (onHide) onHide();
  });

  const signOut = () => {
    $('#modalLogout').modal('hide');
    if (logout) logout();
  }

  return (
    <div id="modalLogout" className="modal fade show modal-confirm" tabIndex={-1} role="dialog" style={{ display: "block" }}>
      <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div className="modal-content">
          <button type="button" className="close btn-close-modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="header-modal-confirm text-center mb-4">
            <h5>
              <strong>Deseja Mesmo Sair?</strong>
            </h5>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="row text-center">
                  <span className="h6  sub-title-modal">
                    Ao sair, seu status no Balcão Virtual
                  <br /> aparecerá como indisponível
                </span>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center mb-2">
            <button className="btn btn-cancel-modal" data-dismiss="modal">Cancelar</button>
            <button className="btn btn-sair-modal" onClick={signOut}>Sair</button>
          </div>
        </div>
      </div>
    </div>
  );
}
