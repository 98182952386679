import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

interface Props {
  label?: string;
}

export default function Loader({ label }: Props) {
  return (
    <>
      <FontAwesomeIcon icon={faCircleNotch} spin className="fa-spin-fast" /> {label}
    </>
  );
}
