import React, { useState } from "react";
import "./style.scss";

const reloadSvg = require('../../views/VideoCall/images/refresh.svg');

export function ReloadConsultDebts() {

  return (
    <div className="reloadLabelContainer">
      <a href="#" className="reloadLabelIconContainerr">
        <img className="reloadLabelIcon" src={reloadSvg} />
      </a>
      <div className="reloadTextLabel">
        <span className="text">Atualizar dados e negociações</span>
      </div>
    </div>
  );
}
