import React from "react";
import Layout from "../../components/Layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

export default function LeisRegulamentadoras() {

  return (
    <Layout title="Leis regulamentadoras" header balcaoVirtual menuName="institucional">
      <p>
        Conheça a base jurídica da PGM.
      </p>
      <div className="leis">
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <a href="leis_complementares/lei_complementar_6.pdf" target="_blank">Lei Complementar nº 6, de 29 de maio de 1992</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <a href="leis_complementares/lei_complementar_9.pdf" target="_blank">Lei Complementar nº 9, de 29 de julho de 1994</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <a href="leis_complementares/lei_complementar_10.pdf" target="_blank">Lei Complementar nº 10, de 29 de setembro de 1995</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <a href="leis_complementares/lei_complementar_16.pdf" target="_blank">Lei Complementar nº 16, de 24 de maio de 2004</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <a href="leis_complementares/lei_complementar_71.pdf" target="_blank">Lei Complementar nº 71, de 23 de novembro de 2009</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <a href="leis_complementares/lei_complementar_122.pdf" target="_blank">Lei Complementar nº 122, de 30 de novembro de 2012</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <a href="leis_complementares/lei_complementar_151.pdf" target="_blank">Lei Complementar nº 151, de 04 de julho de 2013</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <a href="leis_complementares/lei_complementar_185.pdf" target="_blank">Lei Complementar nº 185, de 19 de dezembro de 2014</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <a href="leis_complementares/lei_complementar_252.pdf" target="_blank">Lei Complementar nº 252, de 03 de julho de 2018</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <a href="leis_complementares/lei_complementar_253.pdf" target="_blank">Lei Complementar nº 253, de 03 de julho de 2018</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <a href="leis_complementares/lei_complementar_274.pdf" target="_blank">Lei Complementar nº 274, de 20 de dezembro de 2019</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faFilePdf} />
          <a href="leis_complementares/lei_complementar_315.pdf" target="_blank">Lei Complementar nº 315, de 23 de dezembro de 2021</a>
        </div>
      </div>
    </Layout >
  )
}
