import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Show from "../../../components/Show";
import "./styles.css";
import moment from "moment";
import Format from "../../../helpers/Format";

interface Props {
  receiptType: string | undefined;
  code: string | undefined;
  balance: number | undefined;
  requestedAt: string | undefined;
}

export default function ItemHeader({ receiptType, code, balance, requestedAt, children }: React.PropsWithChildren<Props>) {
  const [show, setShow] = useState(true);

  const handleToggleShow = () => setShow(!show);

  const icon = show ? faChevronDown : faChevronUp;
  const iconTitle = show ? "Clique para esconder" : "Clique para visualizar";

  return (
    <div className="item-situacao">
      <button onClick={handleToggleShow}>
        <div className="infos">
          <strong className="receiptType">{receiptType}</strong>
          <p className="paymentRequestCode">Nº DO PARCELAMENTO: {code}</p><br/>
          <p className="paymentRequestBalance">SALDO DEVEDOR: {Format.currency(balance ?? 0)}</p>
          <p className="paymentRequestDate">SOLICITADO EM: {moment(requestedAt).format('DD/MM/YYYY')}</p>
        </div>
        <div className="icon">
          <FontAwesomeIcon icon={icon} title={iconTitle} />
        </div>
      </button>
      <Show if={show}>
        <div className="item-situacao-content">
          {children}
        </div>
      </Show>
    </div>
  );
}
