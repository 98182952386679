import React from 'react';
import './style.scss';

type InputContributorProps = {
  value: string | undefined;
  setValue: any | undefined;
};

export function InputContributor(props: InputContributorProps) {

  return (
    <input className="contributorField" type="text" onChange={(e) => props.setValue(e.target.value)} value={props.value}>

    </input>
  );
}
