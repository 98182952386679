import React from "react";
import Format from "../../helpers/Format";
import "./styles.css";

interface Props {
  exercicio: string;
  valor: number;
  status: string;
}

export default function ItemDebito({ exercicio, valor, status}: Props) {
  return (
    <div className="item-debito">
      <div className="row">
        <div className="col-12 col-sm-4">
          <strong>Exercício: {exercicio}</strong>
        </div>
        <div className="col-12 col-sm-4">
          Valor: {Format.currency(valor)}
        </div>
        <div className="col-12 col-sm-4">
          Status: {status}
        </div>
      </div>
    </div>
  );
}
