import React, { useEffect } from "react";
import Card from "../../Card";
import iconConsultarDebitos from "../../../assets/images/icon/consultar-debitos.svg";
import iconConsultarParcelamentos from "../../../assets/images/icon/consultar-parcelamentos.svg";
import iconValidarDocumento from "../../../assets/images/icon/validar-documento.svg";
import "./style.css";

interface Props {
  show: boolean;
  onHide?(): void;
}

export default function ModalInfoPortal({ show, onHide }: React.PropsWithChildren<Props>) {

  useEffect(() => {
    if (show) {
      $('#myModal').modal('show')
    } else {
      $('#myModal').modal('hide')
    }
  }, [show]);

  if (!show) {
    return null;
  }

  $('#myModal').on('hide.bs.modal', () => {
    if (onHide) onHide();
  });

  const handleShowRefis = () => {
    $('#myModal').modal('hide');
  }

  return (
    <div id="myModal" className="modal fade modal-info-portal" tabIndex={-1} role="dialog" style={{ display: "block" }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button type="button" className="close btn-close-modal" data-dismiss="modal" aria-label="Close" onClick={onHide}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="header-modal-info-portal mb-4">
            <div className="text-center">
              <h5 className="h2 title-text">
                <strong>Portal do Contribuinte</strong>
                <hr />
              </h5>
              <div className="px-5">
                <span className="h5 m-auto sub-title-modal">
                  Consulte débitos, parcelamentos e valide assinaturas.
                </span>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="card-group mt-3 mb-4">
                <Card title="Consultar débitos" icon={iconConsultarDebitos} action={handleShowRefis} to="/consultar/debitos/em_aberto" yellowStyle targetBlank />
                <Card title="Consultar parcelamentos" icon={iconConsultarParcelamentos} to="/consultar/parcelamentos" yellowStyle targetBlank />
                <Card title="Validar Assinatura" icon={iconValidarDocumento} to="/validar-documento" yellowStyle targetBlank />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
