import React, { useEffect, useState } from 'react';
import './style.scss';
import ConsultarService, { Contributor } from '../../../services/ConsultarService';
import { InputContributor } from './InputContributor';
import Skeleton from 'react-loading-skeleton';
import { cpfOrCnpjMask } from '../../../helpers/Masks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import BalcaoVirtualService from '../../../services/BalcaoVirtualService';
import { callType } from '..';
const warningImg = require('../images/warning.svg');

type ContributorDataProps = {
  document: string;
  type?: callType;
  balcalId: number;
};


export function ContributorData({ document, type, balcalId }: ContributorDataProps) {
  const [edit, setEdit] = useState(true);
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [complement, setComplement] = useState('');
  const [cep, setCep] = useState('');
  const [state, setState] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [contributor, setContributor] = useState<Contributor["data"]>();
  const [seeData, setSeeData] = useState(false);

  useEffect(() => {
    if (type === 'attendant') {
      getData();
    }
  }, [document]);

  const getData = () => {
    ConsultarService
        .contributor({contributor_document: document})
        .then((response) => {
          setContributor(response.data);
          setAddress(response.data.address.street);
          setNumber(response.data.address.number);
          setNeighborhood(response.data.address.neighborhood);
          setCity(response.data.address.city);
          setComplement(response.data.address.complement);
          setCep(response.data.address.cep);
          setState(response.data.address.state);
          setEmail(response.data.contact.email);
          setPhone(response.data.contact.cellphone);
        })
  }

  const getGenders = (gender: string) => {
    switch (gender) {
      case 'male':
        return 'Masculino'
      case 'female':
        return 'Feminino'
      default:
        return 'Não informado'
    }
  };

  const handleSeeData = () => {
    if (type === 'attendant' && !seeData) {
      setSeeData(true);
      BalcaoVirtualService.setShowData(balcalId);
    }

    if (type === 'contributor' && !seeData) {
      BalcaoVirtualService.balcaoPorID(balcalId.toString())
        .then((res) => {
          if (res.show_data) {
            setSeeData(true);
            getData();
          }
        })
    }
  }

  const updateInfo = () => {
    ConsultarService.contributorAddress({
      contributor_document: document,
      address: {
        street: address,
        street_number: number,
        neighborhood: neighborhood,
        city: city,
        state: state,
        cep: cep,
        address_complement: complement,
        main: true,
      }
    }).then(() => {
      ConsultarService.contributorNewContact(
        {
          contributor_document: document,
          contributor_contact_histories: {
            telephone: phone,
            cell_phone: phone,
            email: email,
            current_contact: true,
          }
        }
      ).then(() => {
        ConsultarService
        .contributor({contributor_document: document})
        .then((response) => {
          setContributor(response.data);
        })
      });
    });
  };

  const renderButton = () => {
    if (type === 'attendant') {
      if (edit) {
        return (
          <div className='contributorEdit'>
            <a href="#/" onClick={() => {setEdit(!edit)}} className='contributorDataEdit'>Editar</a>
          </div>
        );
      }
      return (
        <div className='contributorEdit'>
          <a href="#/" onClick={() => {setEdit(!edit)}} className='contributorDataEditCancel'>Cancelar</a>
          <a href="#/" onClick={() => {setEdit(!edit); updateInfo();}} className='contributorDataEdit'>Atualizar</a>
        </div>
      );
    }
  }

  return (
    <div className='contributorDataContainer'>
      <div className='row'>
        {
          type === 'attendant' ? (
            <>
              <div className='contributorDataItem col-sm-12'>
                <span className='contributorDataLabel'>Nome: </span>
                <span className='contributorDataValue'>{contributor ? contributor.name : <Skeleton count={1} />}</span>
              </div>

              <div className='contributorDataItem col-sm-6'>
                <span className='contributorDataLabel'>Tipo: </span>
                <span className='contributorDataValue'>{contributor ? (contributor.cpf ? 'Pessoa Física' : 'Pessoa Jurídica') : <Skeleton count={1} />}</span>
              </div>

              <div className='contributorDataItem col-sm-6'>
                <span className='contributorDataLabel'>Gênero: </span>
                <span className='contributorDataValue'>{contributor ? getGenders(contributor.gender) : <Skeleton count={1} />}</span>
              </div>

              <div className='contributorDataItem col-sm-6'>
                <span className='contributorDataLabel'>{contributor && contributor.cnpj ? 'CNPJ: ' : 'CPF: '} </span>
                <span className='contributorDataValue'>{contributor ? cpfOrCnpjMask(contributor.cnpj || contributor.cpf).mask() : <Skeleton count={1} />}</span>
              </div>

              <div className='contributorDataItem col-sm-6'>
                <span className='contributorDataLabel'>Data de nascimento: </span>
                <span className='contributorDataValue'>{contributor ? contributor.birth_date : <Skeleton count={1} />}</span>
              </div>
            </>
          ) : (
            <div className='d-flex dataItem'>
              <div className='dataImg'>
                <img src={warningImg} />
              </div>
              <span className='dataText'>Atendente liberará a visualização dos seus dados após a confirmação de sua identidade. Confira se os dados estão corretos e informe ao atendente.</span>
            </div>
          )
        }

        <div className='contributorDataHeader col-sm-11'>
          <span>Endereço</span>
        </div>
        <a
          className={`buttonSeeData ${seeData && 'buttonSeeDataActive'}`}
          onClick={() => handleSeeData()}
          href='#/'
        >
          <FontAwesomeIcon icon={seeData ? faEye : faEyeSlash}/>
        </a>
        <div className='contributorDataItem col-sm-12'>
          <span className='contributorDataLabel'>Endereço: </span>
          { !edit ?
            <InputContributor value={address} setValue={setAddress} /> :
            <span className='contributorDataValue'>{contributor ? contributor.address.street : <Skeleton count={1} />}</span>
          }
        </div>

        <div className='contributorDataItem col-sm-6'>
          <span className='contributorDataLabel'>Número: </span>
          { !edit ?
            <InputContributor value={number} setValue={setNumber} /> :
            <span className='contributorDataValue'>{contributor ? contributor.address.number : <Skeleton count={1} />}</span>}
        </div>

        <div className='contributorDataItem col-sm-6'>
          <span className='contributorDataLabel'>Complemento: </span>
          { !edit ?
            <InputContributor value={complement} setValue={setComplement} /> :
            <span className='contributorDataValue'>{contributor ? contributor.address.complement : <Skeleton count={1} />}</span>}
        </div>

        <div className='contributorDataItem col-sm-6'>
          <span className='contributorDataLabel'>Bairro: </span>
          { !edit ?
            <InputContributor value={neighborhood} setValue={setNeighborhood} /> :
            <span className='contributorDataValue'>{contributor ? contributor.address.neighborhood : <Skeleton count={1} />}</span>}
        </div>

        <div className='contributorDataItem col-sm-6'>
          <span className='contributorDataLabel'>CEP: </span>
          { !edit ?
            <InputContributor value={cep} setValue={setCep} /> :
            <span className='contributorDataValue'>{contributor ? contributor.address.cep : <Skeleton count={1} />}</span>}
        </div>

        <div className='contributorDataItem col-sm-6'>
          <span className='contributorDataLabel'>Município: </span>
          { !edit ?
            <InputContributor value={city} setValue={setCity} /> :
            <span className='contributorDataValue'>{contributor ? contributor.address.city : <Skeleton count={1} />}</span>}
        </div>

        <div className='contributorDataItem col-sm-6'>
          <span className='contributorDataLabel'>Estado: </span>
          { !edit ?
            <InputContributor value={state} setValue={setState} /> :
            <span className='contributorDataValue'>{contributor ? contributor.address.state : <Skeleton count={1} />}</span>}
        </div>

        <div className='contributorDataHeader col-sm-11'>
          <span>Contato</span>
        </div>
        <div className='contributorDataItem col-sm-12'>
          <span className='contributorDataLabel'>E-mail: </span>
          { !edit ?
            <InputContributor value={email} setValue={setEmail} /> :
            <span className='contributorDataValue'>{contributor ? contributor.contact.email : <Skeleton count={1} />}</span>}
        </div>

        <div className='contributorDataItem col-sm-12'>
          <span className='contributorDataLabel'>Telefone (WhatsApp): </span>
          { !edit ?
            <InputContributor value={phone} setValue={setPhone} /> :
            <span className='contributorDataValue'>{contributor ? contributor.contact.cellphone : <Skeleton count={1} />}</span>}
          {renderButton()}
        </div>
      </div>
    </div>
  );
}
