import { getWithConfig, postCustomize, put, destroy } from "../config/api";
import { ServerData } from "../helpers/Service";
import { getToken, getClient, getUid } from "./AuthService"

export interface User {
  id: number;
  email: string;
  name: string;
  password?: string;
  cpf?: string,
  roles: Role[];
}

export interface Role {
  id: number;
  name: string;
}

export default class UsuarioService {

  public static async users() {
    const token = getToken(), client = getClient(), uid = getUid();
    return getWithConfig<User[]>("usuarios/",
      { headers: { 'Access-Token': token, 'client': client, 'Uid': uid } })
      .then((response) => response);
  }

  public static async create(email: string, name: string, password: string, roles: [number]) {
    const token = getToken(), client = getClient(), uid = getUid();
    return postCustomize<ServerData<User>>("usuarios/", { email, name, password, roles },
      { headers: { 'Access-Token': token, 'client': client, 'Uid': uid } })
      .then((response) => response);
  }

  public static async update(id: number, email: string, name: string, password: string, roles: [number]) {
    const token = getToken(), client = getClient(), uid = getUid();
    return put<ServerData<User>>(`usuarios/${id}`, password ? { email, name, password, roles } : { email, name, roles },
      { headers: { 'Access-Token': token, 'client': client, 'Uid': uid } })
      .then((response) => response)
  }

  public static async destroy(id: number) {
    const token = getToken(), client = getClient(), uid = getUid();
    return destroy<ServerData<User>>(`usuarios/${id}`,
      { headers: { 'Access-Token': token, 'client': client, 'Uid': uid } })
      .then((response) => response);
  }

  public static async roles() {
    const token = getToken(), client = getClient(), uid = getUid();
    return getWithConfig<Role[]>("roles/",
      { headers: { 'Access-Token': token, 'client': client, 'Uid': uid } })
      .then((response) => response);
  }

}
