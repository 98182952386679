import React from "react";
import Format from "../../helpers/Format";
import { Billet } from "../../services/ConsultarService";
import { URLS, env } from "../../config/api";
import "./styles.css";

interface Props {
  boleto: Billet;
  onHide(): void;
}

export default function Boleto({ boleto, onHide }: Props) {
  return (
    <div className="content-boleto">
      <p>
        <span className="valor">{Format.currency(boleto.value)}</span><br />
        <span className="vencimento">{Format.date(boleto.due_date, "long")}</span>
      </p>
      <p>
        <span className="mensagem">
          Utilize o número do código de barras abaixo para realizar o pagamento da sua dívida.
        </span>
      </p>
      <p>
        <span className="codigo-barras">{boleto.barcode}</span>
      </p>
      <p>
        <span className="observacao">
          Observação: NÃO ACEITAMOS pagamentos via <br /> transferência bancária ou PIX.
        </span>
      </p>
      <div className="actions">
        <a
          href={`${URLS[env()]}/boletos/${boleto.token}/baixar?tipo=${boleto.type}&origin=portal`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary btn-block">
          Visualizar boleto
        </a>
      </div>
    </div>
  );
}
