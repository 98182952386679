import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "../../helpers/Utils";
import Breadcrumb from "../Breadcrumb";
import "./style.css";

import logo from "../../assets/images/logo.svg";
import EnviromentMessage from "../EnviromentMessage";

interface Props {
  items: { label: string; to?: string; active?: boolean }[];
}

export default function Container({ children, items }: React.PropsWithChildren<Props>) {
  const query = useQuery();
  const digital = Boolean(query.get("digital"));

  return (
    <>
      <EnviromentMessage />
      {!digital && (
        <nav className="navbar navbar-light navbar-main">
          <div className="container">
            <Link to="/" className="navbar-brand pl-2" title="Voltar para o início">
              <img src={logo} alt="PGM"/>
            </Link>
          </div>
        </nav>
      )}
      <div className={`container ${digital && "container-digital"}`}>
        {!digital && <Breadcrumb items={items} />}
        {children}
      </div>
    </>
  );
}
