import React, { ReactNode } from "react";

import './styles.css';

interface ValuesContent {
  title: string;
  children: ReactNode;
}

export function ValuesContent(props: ValuesContent) {
  return (
    <div className="values-content">
      <h4>{ props.title }</h4>
      <div>
        { props.children }
      </div>
    </div>
  )
}
