import React from "react";
import {Link} from "react-router-dom";
import Layout from "../../components/Layout";
import "./style.css";
import Noticia from "../../components/Noticia";
import ImgNew2 from "../../assets/images/landingPage/app-green-balcao-virtual.webp";
import ImgNew4 from "../../assets/images/landingPage/atendente_de_verde.webp";
import ImgNew5 from "../../assets/images/landingPage/hotelaria-chave.jpg";

export default function Noticias() {
  return (
    <Layout title="Notícias" header className="" balcaoVirtual menuName="institucional">
      <div className="">
        <div id="noticias" className="noticias-lp mt-5">
          <div className="container">
            <Noticia
              title="News! Portal PGM está de cara nova"
              reference="/noticia"
              day="20 de agosto de 2021"
              description={<>
              <p>Visando melhor experiência do contribuinte junto ao Portal PGM, a equipe da CGETI
              (Coordenadoria de Gestão e Tecnologia) desenvolveu um ambiente digital focado em realizar os atendimentos
              de modo mais ágil, prático e fácil.
              Agora você pode ser atendido sem precisar sair de casa... <Link to="/noticia" className="link-new">Ler notícia completa</Link>
              </p>
              </>}
              image={ImgNew2}
            />
          </div>
        </div>
        <div id="noticias" className="noticias-lp mt-2">
          <div className="container">
            <Noticia
              title="Você pode ser atendido sem sair de casa"
              reference="/atendimento_sem_sair_de_casa"
              day="28 de outubro de 2022"
              description={<>
              <p>
                O atendimento presencial ocorrerá mediante o agendamento prévio, para os seguintes casos:
              </p>
              <ul>
                <li><strong> Grandes negociações:</strong> com mais de 150 Certidões de Dívidas Ativas (CDA);</li>
                <li><strong> Prioridades:</strong> pessoas portadoras de deficiência física, idosos com idade igual ou superior a 60 anos, gestantes, lactantes e... <Link to="/atendimento_sem_sair_de_casa" className="link-new">Ler mais</Link></li>
              </ul>
              </>}
              image={ImgNew4}
            />
          </div>
        </div>
        <div id="noticias" className="noticias-lp mt-2">
          <div className="container">
            <Noticia
              title="Modalidade de Transação Tributária proposta para a Rede Hoteleira"
              reference="/transacao_tributaria"
              day="24 de novembro de 2022"
              description={<>
              <p>
                As concessões do Município de Fortaleza envolverão créditos tributários de
                IPTU e de ISSQN inscritos em Dívida Ativa e poderão reduzir em até 100% o
                valor das multas moratórias, dos juros de mora, da correção monetária e dos
                honorários advocatícios, bem como, preveem um parcelamento em até...
                <Link to="/transacao_tributaria" className="link-new">Ler mais</Link>
              </p>
              </>}
              image={ImgNew5}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
