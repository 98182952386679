import React from "react";

import './style.css';

type TimeLineContentProps = {
  date: string,
  year: string,
  management: string,
  person: string,
  until: string,
  last?: boolean;
}

export function TimeLineContent(props: TimeLineContentProps) {
  return (
    <div className="content-timeline">
      <div className="date">
        <span>{ props.date }</span>
        <strong>{ props.year }</strong>
      </div>

      <div className="line-timeline">
        <div className="circle"></div>
        { !props.last && <div className="rectangule"></div> }
      </div>

      <div className="infos">
        { (props.year === '2001' && props.person === 'Lucíola Maria de Aquino Cabral')
           && <p>Gestão interina por <strong>Rômulo Guilherme Leitão</strong></p> }

        <div className="management">
          <p>{ props.management }</p>
        </div>

        <div className="person">
          <p>{ props.person }</p>
        </div>

        <div className="until">
          <p>{ props.until }</p>
        </div>
      </div>
    </div>
  )
}
