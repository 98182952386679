import React, { useState } from "react";
import styles from './styles.module.scss';
import {
  faChevronDown, faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type SelectProps = {
  options: any;
  onSelect: any;
}

export default function Select(props: SelectProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('');
  const { options, onSelect } = props;

  const handleSelectChange = (event: any) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedLabel = event.target.options[selectedIndex].text;

    const value = event.target.value;
    setSelectedOption(value);
    onSelect(selectedLabel);
  };

  return (
    <div className={styles.container}>
      <select
        className={`${styles.select} ${selectedOption === '' && styles.noSelected}`}
        value={selectedOption}
        onChange={handleSelectChange}
        onFocus={() => setOpen(false)}
        onBlur={() => setOpen(true)}
      >
        <option disabled value="">Escolha o motivo</option>
        {options.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {
        open ? <FontAwesomeIcon
          className={styles.icon}
          icon={faChevronDown}
        /> : <FontAwesomeIcon
          className={styles.icon}
          icon={faChevronUp}
        />
      }
    </div>
  )
}
