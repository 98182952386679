import React from 'react';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const refreshImg = require('../images/refresh.svg');
const eyeImg = require('../images/eye.svg');
const epgmImg = require('../images/epgm.png');
const checkImg = require('../images/check.svg');

export function AttendantIntructions() {
  return (
    <div className='attendantInstructionsContainer'>
      <div className='attendantInstructionsItem'>
        <div  className='attendantInstructionsImg'>
          <img src={checkImg} />
        </div>
        <p className='attendantInstructionsText'>Confirme a identidade do contribuinte pedindo a ele que mostre um documento oficial com foto na vídeo-chamada.</p>
      </div>

      <div className='attendantInstructionsItem'>
        <div  className='attendantInstructionsImg'>
          <img src={eyeImg} />
        </div>
        <p className='attendantInstructionsText'>Após a confirmação de identidade, confira se os dados de endereço e contato estão atualizados. Você pode compartilhar esses dados para o contribuinte clicando no ícone {<FontAwesomeIcon icon={faEye}/>} na aba "Dados do contribuinte".</p>
      </div>

      <div className='attendantInstructionsItem'>
        <div  className='attendantInstructionsImg'>
          <img src={epgmImg} />
        </div>
        <p className='attendantInstructionsText'>Além do painel ao lado onde você poderá consultar e gerar novas negociações com o contribuinte, você poderá acessar todo o extratodas dívidas clicando no ícone da ePGM.</p>
      </div>

      <div className='attendantInstructionsItem'>
        <div  className='attendantInstructionsImg'>
          <img src={refreshImg} />
        </div>
        <p className='attendantInstructionsText'>Ao clicar no ícone verde na lateral da direita da sua tela, você atualizará as negociações realizadas. Faça isso quando o contribuinte realizar por conta própria alguma negociação. Caso seja você quem gerou, peça para o contribuinte clicar nesse botão após a geração da negociação.</p>
      </div>
    </div>
  );
}
