import React, { useState, useRef, useEffect } from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import Select, { Option } from "../../components/Form/SelectReact";
import UsuarioService, { User, Role } from "../../services/UsuarioService";
import { URLS, env, isServerError } from "../../config/api";
import { TOKEN_KEY, getToken, getUser } from "../../services/AuthService"

import * as Yup from "yup";
import Modal from "../../components/Modal";
import Input from "../../components/Form/Input";
import Alert from "../../components/Alert";
import "./style.css";

interface FormData {
  id: string;
  email: string;
  name: string;
  password: string;
  acesso: [];
}

interface Props {
  show: boolean;
  onHide?(): void;
}

export default function Usuarios({ show, onHide }: React.PropsWithChildren<Props>) {

  const errorServer = "Não foi possível estabelecer uma conexão com o servidor. Tente novamente mais tarde.";
  const formRef = useRef<FormHandles>(null);
  const [error, setError] = useState("");
  const [editable, setEditable] = useState(false);
  const [editIdSelected, setEditIdSeleted] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Option[]>([]);
  const [creating, setCreating] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState<number>();

  const initializeUser = () => {
    const userTemp: User = {
      id: 0,
      email: "",
      name: "",
      password: "",
      roles: []
    }
    return userTemp;
  }

  const [usuario, setUsuario] = useState<User>(initializeUser)

  const loadUsers = async () => {
    await UsuarioService
      .users()
      .then((response) => { setUsers(response.data) })
  }

  useEffect(() => {
    async function loadRoles() {
      await UsuarioService.roles()
        .then((response) => {
          response.data.forEach((option) => {
            const optionTemp = {
              value: option.id,
              label: option.name,
            }
            roles.push(optionTemp)
          })
        });
    }

    const user = JSON.parse(getUser() || "");
    const adminTemp = user.roles.map((option: any) => { return option.activities.includes("admin:all") });
    if (Boolean(adminTemp[0])) {
      loadRoles();
      loadUsers();
    }
  }, [])

  const handleSubmit: SubmitHandler<FormData> = async (formData) => {
    setError("");
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object<FormData>().shape({
        email: Yup.string().required("E-mail é obrigatório"),
        name: Yup.string().required("Nome é obrigatório"),
        password: creating ? Yup.string().required("Senha é obrigatória") : Yup.string(),
        acesso: Yup.string().required("Acesso é obrigatório"),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      const user = {
        id: Number(formData.id),
        email: formData.email,
        name: formData.name,
        password: String(formData.password),
        role: Number(formData.acesso)
      }

      if (creating) {
        await UsuarioService
          .create(user.email, user.name, user.password, [user.role])
          .then((response) => {
            setError("Usuário criado com sucesso!");
          }).catch((err) => {
            if (err instanceof Error) {
              setError(errorServer);
            } else if (isServerError(err)) {
              setError(err.error);
            } else {
              console.error(err);
            }
          }).finally(() => { });
      } else {
        await UsuarioService
          .update(user.id, user.email, user.name, user.password, [user.role])
          .then((response) => {
            setError("Usuário atualizado com sucesso!");
          }).catch((err) => {
            if (err instanceof Error) {
              setError(errorServer);
            } else if (isServerError(err)) {
              setError(err.error);
            } else {
              console.error(err);
            }
          }).finally(() => { });
      }
      loadUsers();
      setCreating(false);
      setEditable(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<{}>((o, c) => ({ ...o, [c.path]: c.message }), {});
        formRef.current?.setErrors(errors);
      }
    }
  };

  const newUser = () => {
    clearFields();
    users.push(usuario);
    setEditIdSeleted(usuario.id);
    setCreating(true);
    setEditable(false);
  };

  const startEditing = async (id: number) => {
    clearFields();
    setEditable(true);
    setCreating(false);
    setEditIdSeleted(id);
    const userEdit = users.filter(element => element.id === id)[0];
    setEmail(userEdit.email);
    if (userEdit.roles.length > 0) {
      setRole(userEdit.roles[0].id);
    }
  }

  const destroy = async (id: number) => {
    try {
      setEditable(false);
      setCreating(false)
      setEditIdSeleted(id)
      await UsuarioService
        .destroy(id)
        .then((response) => {
          setError("Usuário excluído com sucesso!");
        }).catch((err) => {
          if (err instanceof Error) {
            setError(errorServer);
          } else if (isServerError(err)) {
            setError(err.error);
          } else {
            console.error(err);
          }
        }).finally(() => {
          loadUsers();
          setCreating(false);
          setEditable(false);
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<{}>((o, c) => ({ ...o, [c.path]: c.message }), {});
        formRef.current?.setErrors(errors);
      }
    }
  }

  const cancel = () => {
    if (creating) {
      users.pop();
    }
    clearFields();
    setEditable(false);
    setCreating(false);
  }

  const clearFields = () => {
    setEmail("");
    setName("");
    setPassword("");
    setRole(undefined);
    formRef.current?.setErrors({});
  }

  return (
    <div className="modal-usuarios">
      <Modal show={show} onHide={onHide} centered >
        <div className="text-center ">
          <span className="h3 modal-title-usuarios mt-3">
            <strong>Gerenciar Usuários</strong>
            <hr />
          </span>
          <div className="px-5">
            <span className="sub-title-modal">
              Insira e-mail, senha e nível de acesso de cada atendente.
            </span>
          </div>
        </div>
        <div className="content-modal-usuarios mt-5">
          <div className="container">
            <Form className="form-usuarios" ref={formRef} autoComplete={"off"} onSubmit={handleSubmit}>
              {error && (<Alert>{error}</Alert>)}
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">NOME</th>
                      <th scope="col">E-MAIL</th>
                      <th scope="col">SENHA</th>
                      <th scope="col">ACESSO</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="users-form">
                    {users?.map((user, index) => {
                      return (
                        <tr key={index}>
                          {editIdSelected === user.id && (editable || creating) ?
                            <>
                              <td>
                                <Input name="name" maxLength={50} autoFocus placeholder={"Nome do atendente"}
                                  value={name} onChange={(e) => { setName(e.target.value) }} />
                              </td>
                              <td>
                                <Input name="email" maxLength={50} placeholder={"seuemail@gmail.com"}
                                  value={email} onChange={(e) => { setEmail(e.target.value) }} />
                              </td>
                              <td>
                                <Input name="password" type="password" maxLength={50} autoComplete="new-password" placeholder={"Digite a senha"}
                                  value={password} onChange={(e) => { setPassword(e.target.value) }} />
                              </td>
                              <td className="select-role">
                                <Select
                                  label=""
                                  name="acesso"
                                  optionValue={role}
                                  onchange={e => setRole(Number(e))}
                                  placeholder="Escolha o acesso"
                                  options={roles} />
                              </td>
                              <td className="btn-group-form">
                                <Input name="id" type="text" maxLength={50} value={user.id} hidden />
                                <button className="btn-save">Salvar</button>
                                <button type="button" onClick={cancel} className="btn-cancel">Cancelar</button>
                                {creating === false && <a href="#" ><FontAwesomeIcon icon={faTrash} onClick={() => destroy(user.id)} color="#DE5262" /> </a>}
                              </td>
                            </>
                            :
                            <>
                              <td>
                                <span>{user.name}</span>
                              </td>
                              <td>
                                <span>{user.email}</span>
                              </td>
                              <td>
                                <span>*******</span>
                              </td>
                              <td className="select-role">
                                <span>{user.roles.length > 0 ? user.roles[0].name : " "}</span>
                              </td>
                              <td className="btn-group-form">
                                {creating === false && <a href="#" aria-disabled="true" onClick={() => startEditing(user.id)} ><FontAwesomeIcon icon={faEdit} color="#1EB1E7" /> </a>}
                              </td>
                            </>
                          }
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="link-new-user">
                <button type="button" onClick={newUser} disabled={editable || creating}> <FontAwesomeIcon icon={faPlus} /> Novo Atendente</button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
}
