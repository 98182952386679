import { post } from "../config/api";

export default class AtendimentoService {

  public static async salvarInformacoesAtendimento(id: number, document: string, reasons: string[], debt_knowledge: string[], solution: string[], attendance_id: string) {
    const params = {
      attendant_id: id,
      contributor_document: document,
      reasons,
      debt_knowledge,
      solution,
      attendance_id,
    };

    return post("/attendance_info", params)
      .then((response) => response);
  }

  public static async salvarInformacoesContributor(id: number, document: string, rate: number, attendance_id: string, comment?: string) {
    const params = {
      attendant_id: id,
      contributor_document: document,
      rate: rate,
      comment: comment,
      attendance_id
    };

    return post("/attendance_rating", params)
      .then((response) => response);
  }
}
