import React from "react";
import { extractDebtsAgilis } from "../../config/constants";
import styles from "./style.module.scss";

interface props {
  contributorDocument: string;
  contributorIn: any;
}

const agilisImg = require('../../views/VideoCall/images/agilis-divida-logo.svg');

export function AgilisButtonNavigate(props: props) {

  const redirect = () => {
    const params = encodeURIComponent(JSON.stringify({
      contributor: props.contributorIn,
      processNumber: undefined,
      inscription: undefined,
      protestProtocol: undefined,
      cda: undefined,
      installmentNumber: undefined,
      origin: undefined,
      showExtinctDebits: true,
    }));

    window.open(`${extractDebtsAgilis}/debt_box_search?search=${params}`)
  }

  return (
    <div className={styles.agilisLabelContainer}>
      <a href="#" className={styles.agilisLabelIconContainerr} onClick={redirect}>
        <img className={styles.agilisLabelIcon} src={agilisImg} />
      </a>
      <div className={styles.agilisTextLabel}>
        <span className={styles.text}>Abrir extrato no</span>
      </div>
    </div>
  );
}
