import React, { useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import ConsultarService, { ResponseParcelamentos } from "../../services/ConsultarService";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import ItemParcelamento from "../../components/ItemParcelamento";
import { useQuery } from "../../helpers/Utils";
import { isCpfOrCnpj } from "../../helpers/Validates";
import { cpfOrCnpjMask, onlyAlphanumeric } from "../../helpers/Masks";
import { isServerError } from "../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import ContributorForm from "../../components/ContributorUpdateForm/ContributorForm";

interface FormData {
  documento: string;
  valor: string;
}

export default function ConsultarParcelamentos() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ResponseParcelamentos>();
  const [error, setError] = useState("");
  const [contributorDocument, setContributorDocument] = useState('');

  const contributorName = data?.contributor_name_without_mask;
  const formRef = useRef<FormHandles>(null);

  const query = useQuery();
  const d = query.get("d");
  const n = query.get("n");

  const submitAttendance = async () => {
    var contributorDocment = (document.getElementById("contributorDocment") as HTMLInputElement).value.replace(/\D/g,'');

    setContributorDocument(contributorDocment);
    const contributor_document = contributorDocment;
    const contributor_attendance_histories = {'attendance_place':'Portal do Contribuinte - Consultar Parcelamento',
                                              'attendant':'Assitente Virtual',
                                              'attendance_subject':'portal_of_taxpayer_installment_consult'};

    const attendanceData = {contributor_document, contributor_attendance_histories}

    await ConsultarService
      .attendanceRegistration(attendanceData)

  };

  const handleSubmitConsultarDebitos = async (documento: string, valor: string) => {

    setLoading(true);
    await ConsultarService
      .parcelamentos(documento, valor)
      .then((response) => {
        setData(response);
        if (response.is_updated){
          submitAttendance();
        };
      }).catch((err) => {
        if (isServerError(err)) {
          setError(err.error);
          if (err.error == "Não foram encontrados parcelamentos ativos."){
            submitAttendance();
          }
        } else {
          console.error(err);
        }
      }).finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      setData(undefined);
      setError("");

      formRef.current?.setErrors({});

      const schema = Yup.object<FormData>().shape({
        documento: Yup.string()
          .test("cpfOrCnpj", "CPF/CNPJ é inválido", (value) => {
            if (typeof value === "string") {
              return isCpfOrCnpj(value);
            }

            return false;
          })
          .required("CPF/CNPJ é obrigatório"),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      await handleSubmitConsultarDebitos(formData.documento, formData.valor);
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<{}>((o, c) => ({ ...o, [c.path]: c.message }), {});
        formRef.current?.setErrors(errors);
      }
    }
  };

  const handleEffectSubmit = async () => {
    if (formRef && formRef.current && d && n) {
      formRef.current.setFieldValue("documento", cpfOrCnpjMask(d).mask());
      formRef.current.setFieldValue("valor", n);
      await handleSubmitConsultarDebitos(d, n);
    }
  };

  useEffect(() => {
    handleEffectSubmit();
  }, [formRef, formRef.current?.getFieldValue("documento")]);

  return (
    <Layout title="Consultar Parcelamentos" className="consultar-parcelamentos" balcaoVirtual balcaoTitle={"Tem dúvidas?"} menuName="servicos">
      <a href="/servicos" className="link-direction">
        <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
        Ver todos os serviços
      </a>
      <span className="h3 container-title mt-4">Consultar Parcelamentos</span>
      <p className={d ? "d-none" : ""}>Você pode informar apenas um CPF ou CNPJ. Para uma busca específica, preencha o N° do parcelamento.</p>
      <Form className={`search ${d ? "d-none" : ""}`} ref={formRef} onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-5 mt-3">
            <Input id={'contributorDocment'} label="CPF/CNPJ" name="documento" mask={cpfOrCnpjMask} autoFocus placeholder={"Digite seu CPF ou CNPJ"} />
          </div>
          <div className="col-12 col-sm-6 col-lg-5 mt-3">
            <Input label="Nº do Parcelamento" name="valor" mask={onlyAlphanumeric} placeholder={"Digite o nº do parcelamento"} />
          </div>
          <div className="col-12 col-sm-6 col-lg-2 col-search mt-3">
            <Button label="Pesquisar" loading={loading} block />
          </div>
        </div>
      </Form>
      {d && loading && <Loader label="Carregando parcelamentos..." />}
      {error && <p className="alert alert-warning">{error}</p>}
      {data?.is_updated ?
        data && data.payment_requests.map((paymentRequest) => (
          <ItemParcelamento
            key={paymentRequest.id}
            id={paymentRequest.id}
            numero={paymentRequest.code}
            data={paymentRequest.requested_at}
            valor={paymentRequest.balance}
            valorPago={paymentRequest.amount_paid_main}
            totalParcelas={paymentRequest.number_installments}
            status={paymentRequest.status}
            status_i18n={paymentRequest.status_pt_br}
            opened={!!d}
            contributorDocument={contributorDocument}
            contributorName={contributorName}
          />
        )) :
        <ContributorForm
          contributor_name={data?.contributor_name}
          is_update={data?.is_updated}
          attendance_place={'Portal do Contribuinte - Consultar Parcelamentos'}
        />
      }
    </Layout>
  );
}
