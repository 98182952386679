import React from "react";
import { extractDebtsEPGM } from "../../config/constants";
import "./style.scss";

interface props {
  contributorDocument: string;
}

const epgmImg = require('../../views/VideoCall/images/epgm.svg');

export function EpgmButtonNavigate(props: props) {

  const redirect = () => {
    window.open(`${extractDebtsEPGM}${props.contributorDocument}`)
  }

  return (
    <div className="epgmLabelContainer">
      <a href="#" className="epgmLabelIconContainerr" onClick={redirect}>
        <img className="epgmLabelIcon" src={epgmImg} />
      </a>
      <div className="epgmTextLabel">
        <span className="text">Abrir extrato no</span>
      </div>
    </div>
  );
}
