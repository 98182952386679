import React from "react";
import { Link } from "react-router-dom";

interface Props {
  items: { label: string; to?: string; active?: boolean }[];
}

export default function Breadcrumb({ items }: Props) {
  if (items.length === 0) {
    return null;
  }

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {items.map((item) => {
          const klass = ["breadcrumb-item"];
          let ariaCurrent: React.AriaAttributes["aria-current"];

          if (item.active) {
            klass.push("active");
            ariaCurrent = "page";
          }

          return (
            <li key={item.label} className={klass.join(" ")} aria-current={ariaCurrent}>
              {item.to && (<Link to={item.to}>{item.label}</Link>)}
              {item.active && item.label}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
