import React, { useState} from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPhone } from "@fortawesome/free-solid-svg-icons";
import whatsappIcon from "../../assets/images/icon-whatsapp-verde.svg";

import ModalBalcaoVirtual from "../../components/ModalBalcaoVirtual";
import ImageApp from "../../assets/images/landingPage/atendente_de_verde.webp";

export default function Noticia() {

  const [showModalBalcaoVirtual, setShowModalBalcaoVirtual] = useState(false);

  const handleShowBalcaoVirtual = async () => {
    setShowModalBalcaoVirtual(true);
  }

  const handleHideBalcaoVirtual = () => {
    setShowModalBalcaoVirtual(false);
  }

  return (
    <>
      <Layout title="Notícia" className="" balcaoVirtual>
        <div className="">
          <div id="noticia" className="">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="noticia">
                  <div className="col-4 float-right mt-4">
                    <img className="img-news d-none d-sm-block align-items-center w-100 h-100 quadrangular" src={ImageApp} alt="" />
                  </div>
                  <div className="content d-block col-md-8 col-sm-12">
                    <a href="/noticias" className="link-direction">
                      <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                      Ver todas as notícias
                    </a>
                    <h3 className="title mt-4">Você pode ser atendido sem sair de casa</h3>
                    <small className="data-new">28 de outubro de 2022</small>
                    <p className="description-new mt-4">
                      O <strong>atendimento presencial</strong> ocorrerá mediante o agendamento prévio, para os seguintes casos:
                    </p>
                    <ul className="description-new">
                      <li><strong>Grandes negociações:</strong> com mais de 150 Certidões de Dívidas Ativas (CDA);</li>
                      <li><strong>Prioridades:</strong> pessoas portadoras de deficiência física, idosos com idade igual ou superior a 60 anos, gestantes, lactantes e pessoas acompanhadas por crianças de colo;</li>
                      <li><strong>Espólio:</strong> assuntos relacionados a espólios em Dívida Ativa;</li>
                    </ul>
                    <p className="description-new mt-4">
                      Ressaltamos que qualquer outro caso de atendimento que não esteja citado acima, acesse: <a className="my-link" href="/#atendimento">Balcão Virtual</a> ou baixe o aplicativo <a className="my-link" href="/#atendimento">Dívida Ativa</a>.
                    </p>
                    <br/>
                    <h4 className="title">Contato</h4>
                    <p className="description-new mt-4">
                      Atendimento de Dívida Ativa <br/>
                      (Procuradoria da Fazenda)
                    </p>
                    <div className="contact-div">
                      <FontAwesomeIcon icon={faPhone} style={{ color: 'var(--green4)' }} /> | <img src={whatsappIcon} alt="WhatsApp" width={25} /> <span>(85) 98419-2045</span>
                    </div>
                    <div className="contact-div">
                      <FontAwesomeIcon icon={faPhone} style={{ color: 'var(--green4)' }} /> | <img src={whatsappIcon} alt="WhatsApp" width={25} /> <span>(85) 98419-2042</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalBalcaoVirtual show={showModalBalcaoVirtual} onHide={handleHideBalcaoVirtual} />
      </Layout>
    </>
  );
}
