import React, { useState} from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import ModalBalcaoVirtual from "../../components/ModalBalcaoVirtual";
import PortalAntesDepois from "../../assets/images/landingPage/portal-antexXdepois.webp";
import AppDividaAtiva from "../../assets/images/landingPage/img-appdividaativa.webp";
import ImageApp from "../../assets/images/landingPage/app-green-balcao-virtual.webp";

export default function Noticia() {

  const [showModalBalcaoVirtual, setShowModalBalcaoVirtual] = useState(false);

  const handleShowBalcaoVirtual = async () => {
    setShowModalBalcaoVirtual(true);
  }

  const handleHideBalcaoVirtual = () => {
    setShowModalBalcaoVirtual(false);
  }

  return (
    <>
      <Layout title="Notícia" className="" balcaoVirtual>
        <div className="">
          <div id="noticia" className="">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="noticia">
                  <div className="col-2 float-right">
                    <img className="img-news d-none d-sm-block rounded-circle align-items-center" src={ImageApp} alt="" />
                  </div>
                  <div className="content d-block col-md-10 col-sm-12">
                    <a href="/noticias" className="link-direction">
                      <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                      Ver todas as notícias
                    </a>
                    <h3 className="title mt-4">News! Portal PGM está de cara nova</h3>
                    <small className="data-new">01 de agosto de 2021</small>
                    <p className="description-new mt-4">
                      Visando melhor experiência do contribuinte junto ao <strong>Portal PGM</strong>
                      , a equipe da CGETI (Coordenadoria de Gestão e
                      Tecnologia) desenvolveu um ambiente digital focado em
                      realizar os atendimentos de modo mais ágil, prático e
                      fácil. Agora você pode ser <strong>atendido sem precisar sair de casa.</strong>
                    </p>
                    <p className="description-new">
                      A principal inovação implantada foi o <strong>lançamento do Balcão Virtual</strong>, são 15 salas
                      do Google Meet para chamadas,
                      em tempo real, com os atendentes da PGM que ficam online
                      de segunda à sexta-feira, das 8h30 às 16h30 para atender
                      as chamadas.
                    </p>
                    <p className="description-new">
                      Você pode escolher uma sala para ser atendido <a href="#" onClick={handleShowBalcaoVirtual} className="link">clicando aqui</a> ou diretamente via <Link to="/" className="link">Portal.</Link>
                    </p>
                    <p className="description-new">
                      A estrutura do Portal PGM também mudou. Toda a aparência foi modernizada e otimizada para
                      navegação via desktop e smartphones. As novas cores e identidade da <strong>Prefeitura de Fortaleza</strong> foram
                      aplicadas e incluídas seções de conteúdo como a área Institucional, incluindo: Quem é Quem e Notícias.
                    </p>
                    <img src={PortalAntesDepois} alt="" className="img-fluid" />
                    <h5 className="mt-4 mb-4">Aplicativo Dívida Ativa</h5>
                    <p className="description-new">
                      A novidade também se estende ao aplicativo Dívida Ativa
                      para <a href="https://apps.apple.com/br/app/d%C3%ADvida-ativa-fortaleza/id1499380698?l=en" target="_blank" className="link"> iOS</a> e <a href="https://play.google.com/store/apps/details?id=br.gov.ce.fortaleza.pgm.dividaativa" target="_blank" className="link">Android.</a> Além de receber cara nova, ganhou a
                      atualização de funcionalidades e fluxos, otimizando a
                      experiência do usuário na hora de consultar e quitar suas
                      dívidas. No App, o <strong>contribuinte pode consultar débitos,
                      parcelamentos, gerar boletos à vista ou parcelados e
                      acessar o Balcão Virtual </strong> para realizar chamada, em tempo
                      real, com um atendente.
                    </p>
                    <img src={AppDividaAtiva} alt="" className="img-fluid" />
                    <p className="description-new mt-4">
                      Após o lançamento do <strong>novo Portal PGM e a atualização do
                      aplicativo Dívida Ativa</strong>, a otimização segue continuamente.
                      Caso você deseje enviar suas sugestões de conteúdo ou
                      esclarecer dúvidas, entre em contato com um de nossos
                      atendentes via <a href="https://wa.me/message/M2NDPYILPBCID1" target="_blank" className="link"> WhatsApp </a>ou <a href="#" onClick={handleShowBalcaoVirtual} className="link">Balcão Virtual.</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalBalcaoVirtual show={showModalBalcaoVirtual} onHide={handleHideBalcaoVirtual} />
      </Layout>
    </>
  );
}
