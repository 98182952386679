import React from "react";
import Home from "../../components/LandingPage/Home"
import "./style.css";

import Section2 from "../../components/LandingPage/Section2"
import Section6 from "../../components/LandingPage/Section6"
import Section7 from "../../components/LandingPage/Section7"
import { Cookie } from "../../components/LandingPage/Cookie";

export default function LandingPage() {
  return (
    <>
      <div className="landingPage">
        <Home />
        <div className="section2">
          <div className="container">
            <Section2 />
          </div>
        </div>
        <Section6 />
        <Section7 />
        <Cookie />
      </div>
    </>
  )
}
