import moment from "moment";
import "moment/locale/pt-br";

interface StyleDate {
  default: string;
  long: string;
  datetime: string;
}

export default class Format {
  public static currency(currency: number): string {
    return currency.toLocaleString("pt-BR", { currency: "BRL", style: "currency" });
  }

  public static date(date: string, style?: keyof StyleDate) {
    const styleSelected: keyof StyleDate = style ? style : "default";
    const format: StyleDate = {
      default: "DD/MM/YYYY",
      long: "LL",
      datetime: "LLL",
    };

    return moment(date).format(format[styleSelected]).toString();
  }
}
