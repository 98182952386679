import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

interface Props extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string;
  name: string;
}

export default function TextArea({ label, name, ...rest }: React.PropsWithChildren<Props>) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, registerField, error } = useField(name);
  const klass = ["form-control"];

  if (error) {
    klass.push("is-invalid");
  }

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value && inputRef.current) {
      inputRef.current.value = value;
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue(ref) {
        return ref.value.split(/\n|,| /)
          .map((item: string) => item.trim())
          .filter((item: string) => item !== '');
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className="form-group">
      {label && <label className="form-label">{label}</label>}
      <textarea className={klass.join(" ")} ref={inputRef} onChange={onChange} {...rest} />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}
