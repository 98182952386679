import React from "react";
import Loader from "../Loader";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  label: string;
  size?: "default" | "large" | "small";
  loadingLabel?: string;
  loading?: boolean;
  block?: boolean;
  cansearch?: boolean
}

export default function Button({ label, size, block, loadingLabel, loading, disabled, cansearch, ...rest }: React.PropsWithChildren<Props>) {
  const klass = [
    "btn",
    "btn-primary",
  ];

  if (block) {
    klass.push("btn-block ");
  }

  if (size === "small") {
    klass.push("btn-sm");
  } else if (size === "large") {
    klass.push("btn-lg");
  }

  if(cansearch == false) {
    klass.push("btn-disabled");
  }

  return (
    <div className="form-group">
      <button className={klass.join(" ")} disabled={loading || disabled} {...rest} id='searchbuttonid'>
        {loading && <Loader label={loadingLabel} />}
        {!loading && label}
      </button>
    </div>
  );
}
