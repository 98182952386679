import React from "react";
import ReactDOM from "react-dom";
import Router from "./config/router";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/global.css";
import './styles/colors.scss'

require('dotenv').config({ path: '../.env' })

ReactDOM.render(<Router />, document.getElementById("root"));
