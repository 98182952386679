import React from "react";
import styles from './styles.module.scss';
import PointRight from '../../assets/images/icon/pointing-right.png';
import ChromeIcon from '../../assets/images/icon/icons8-chrome-240.png';
import EdgeIcon from '../../assets/images/icon/icons8-ms-edge-novo-240.png';
import MozillaIcon from '../../assets/images/icon/icon_mozilla_240.png';

type WebBrowserAlertProps = {
  start?: boolean;
};

export default function WebBrowserAlert(props: WebBrowserAlertProps) {
  return (
    <div className={`${styles.container} ${props.start !== undefined ? styles.start : styles.middle}`}>
      <div className={styles.content}>
        <div>
          <img  src={PointRight} alt="pointRight" className={styles.icon}/>
          <b> Dica: para evitar problemas de compatibilidade</b> e garantir a melhor experiência <br/> <span>no Balcão Virtual,</span>
          <b> use os seguintes navegadores (ou versão superior):</b>
        </div>
        <div className={styles.browsers}>
          <div className={styles.browser}>
            <img src={ChromeIcon} alt="chrome"/>
            Google Chrome: 126.0.6478.61,
          </div>
          <div className={styles.browser}>
            <img src={EdgeIcon} alt="edge"/>
            Microsoft Edge: 125.0.2535.92 e
          </div>
          <div className={styles.browser}>
            <img src={MozillaIcon} alt="mozilla"/>
            Mozilla Firefox: 123.0.1
          </div>
        </div>
      </div>
    </div>
  );
}
