import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import Layout from "../../components/Layout";
import Format from "../../helpers/Format";
import ItemValidarDocumento from "../../components/ItemValidarDocumento";
import { onlyAlphanumeric } from "../../helpers/Masks";
import DocumentoAssinadoService, { ResponseValidarDocumento } from "../../services/DocumentoAssinadoService";
import { URLS, env, isServerError } from "../../config/api";
import "./style.css";

interface FormData {
  codigo: string;
}

export default function ValidarDocumentoAssinado() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ResponseValidarDocumento>();
  const [error, setError] = useState("");
  const formRef = useRef<FormHandles>(null);

  const handleSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      setData(undefined);
      setError("");
      setLoading(true);

      formRef.current?.setErrors({});

      const schema = Yup.object<FormData>().shape({
        codigo: Yup.string().required("Código de verificação é obrigatório"),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      await DocumentoAssinadoService
        .validar(formData.codigo)
        .then((response) => {
          setData(response);
        }).catch((err) => {
          if (isServerError(err)) {
            setError(err.error);
          } else {
            setError("Não foi possível estabelecer uma conexão com o servidor. Tente novamente mais tarde.");
            console.error(err);
          }
        }).finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<{}>((o, c) => ({ ...o, [c.path]: c.message }), {});
        formRef.current?.setErrors(errors);
      }
    }
  };

  return (
    <Layout title="Validar assinatura" balcaoVirtual menuName="servicos">
      <a href="/servicos" className="link-direction">
        <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
        Ver todos os serviços
      </a>
      <span className="h3 container-title mt-4">Validar assinatura</span>
      <p className="mt-3">Para validar um documento assinado digitalmente no sistema e-PGM, informe o código de verificação do documento.</p>
      <Form ref={formRef} onSubmit={handleSubmit} className="search mt-4">
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-5">
            <Input label="Código de Verificação" name="codigo" mask={onlyAlphanumeric} autoFocus placeholder={"Digite o código"} />
          </div>
          <div className="col-12 col-sm-6 col-lg-2 col-search">
            <Button label="Validar" loading={loading} block />
          </div>
        </div>
      </Form>
      {error && <p className="alert alert-warning">{error}</p>}
      {data && (
        <div className="validar-documento">
          <div className="header">
            <div className="row">
              <div className="col-6"><strong>Código de verificação:</strong></div>
              <div className="col-6">{data.control_code}</div>
            </div>
            <div className="row">
              <div className="col-6"><strong>Data da verificação:</strong></div>
              <div className="col-6">{Format.date(data.date.toString(), "datetime")}</div>
            </div>
            <div className="row">
              <div className="col-6"><strong>Nome do documento:</strong></div>
              <div className="col-6 truncated">{data.filename}</div>
            </div>
            <div className="row">
              <div className="col-6"><strong>Download do documento:</strong></div>
              <div className="col-6">
                <a
                  href={`${URLS[env()]}/documento_assinado/${data.control_code}/download`}
                  title="Download do documento"
                  target="_blank"
                  className="btn btn-secondary">
                  <FontAwesomeIcon icon={faFilePdf} title="Download do documento" /> Download do Documento
                </a>
              </div>
            </div>
          </div>
          <div className="assinaturas">
            <p className="titulo">Assinaturas do documento</p>
            {data.signatures.map((signature, index) => (
              <ItemValidarDocumento
                key={index}
                name={signature.name}
                date={signature.date}
              />
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
}
