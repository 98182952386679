import React from "react";
import Layout from "../../components/Layout";
import PgmDebitos from "../../assets/images/illustration-pgm-debitos.svg"
import PgmAssinatura from "../../assets/images/illustration-pgm-assinatura.svg"
import PgmParcelamentos from "../../assets/images/illustration-pgm-parcelamentos.svg"
import ServicoItem from "../../components/ServicoItem";
import "./style.css";

export default function Servicos() {
  return (
    <Layout title="Serviços" className="services" balcaoVirtual menuName="servicos">
      <span className="h3 container-title">Conheça os serviços da PGM</span>
      <p className="">Escolha como podemos te ajudar.</p>
      <ServicoItem
        title={"Consulta de dívidas"}
        id={"One"}
        image={PgmDebitos}
        href={"/consultar/debitos/em_aberto"}
        linkText={"Consultar dívidas"}
        readMore
        length={190}
        text="Uma vez esgotado o prazo para pagamentos dos créditos tributários e não tributários do município,
          essa dívida é enviada para a PGM iniciar o processo e realizar a cobrança judicial ou extrajudicialmente.
          Aqui no Portal, você pode realizar a pesquisa para saber se existem débitos
          pendentes e gerar boleto à vista ou simular e gerar um parcelamento."/>
      <ServicoItem
        title={"Consulta de parcelamentos"}
        id={"Two"}
        image={PgmParcelamentos}
        href={"/consultar/parcelamentos"}
        linkText={"Consultar parcelamentos"}
        readMore
        length={190}
        text="Após realizado o parcelamento da dívida inscrita na Dívida Ativa do Município de Fortaleza,
          você pode consultar as parcelas pagas e gerar os boletos das parcelas a vencer ou vencidas.
          Aqui no Portal PGM você pode consultar seus parcelamentos ativos."/>
      <ServicoItem
        title={"Consulta de assinatura"}
        id={"Three"}
        image={PgmAssinatura}
        href={"/validar-documento"}
        linkText={"Validar assinatura"}
        text="Os documentos que foram assinados digitalmente pelos procuradores da PGM, a partir de 2021,
        podem ter sua autenticidade validada no Portal PGM."/>
    </Layout>
  );
}
