import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ConsultarService, { Parcela, Billet, Inscricao } from "../../services/ConsultarService";
import moment from "moment";
import Modal from "../Modal";
import Alert from "../Alert";
import Boleto from "../Boleto";
import AlertOverdueInstallmentModal from "../AlertaParcelamentoAtrasado";
import Button from "../Form/Button";
import Loader from "../Loader";
import ItemParcela from "../ItemParcela";
import Format from "../../helpers/Format";
import { isServerError } from "../../config/api";
import { useQuery } from "../../helpers/Utils";
import "./styles.css";
import { cpfOrCnpjMask } from "../../helpers/Masks";

interface Props {
  id: number;
  numero: string;
  data: string;
  valor: number;
  valorPago: number;
  totalParcelas: number;
  status: string;
  status_i18n: string;
  opened?: boolean;
  contributorDocument?: string | null;
  contributorName?: string;
}

export default function ItemParcelamento({ id, numero, data, valor, valorPago, totalParcelas, status, status_i18n, opened , contributorDocument,
  contributorName}: Props) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [loading, setLoading] = useState(false);
  const [loadingInscricoes, setLoadingInscricoes] = useState(false);
  const [loadingBoleto, setLoadingBoleto] = useState(false);

  const [show, setShow] = useState(false);
  const [showModalBoleto, setShowModalBoleto] = useState(false);
  const [alertOverdueInstallments, setAlertOverdueInstallments] = useState(false);
  const [parcelasAtrasadas, setParcelasAtrasadas] = useState<Parcela[]>([]);
  const [parcelasSelecionadas, setParcelasSelecionadas] = useState<Parcela[]>([]);
  const [showModalInscricoes, setShowModalInscricoes] = useState(false);

  const [error, setError] = useState("");
  const [errorBoleto, setErrorBoleto] = useState("");
  const [errorInscricoes, setErrorInscricoes] = useState("");

  const [alert, setAlert] = useState("");

  const [parcelamentoId, setParcelamentoId] = useState<number>(id);
  const [parcelasPagas, setParcelasPagas] = useState<Parcela[]>([]);
  const [parcelasNaoPagas, setParcelasNaoPagas] = useState<Parcela[]>([]);
  const [inscricoes, setInscricoes] = useState<Inscricao[]>([]);
  const [boleto, setBoleto] = useState<Billet>();

  const [totalSelecionado, setTotalSelecionado] = useState(0);
  const [totalDevedor, setTotalDevedor] = useState(0);

  const query = useQuery();
  const d = query.get("d");
  const n = query.get("n");
  const c = query.get("c");

  const ticketGeneration = async (c: any) => {
    try {
      setLoadingBoleto(true);
      await ConsultarService
        .gerarBoletoParcelamento(c,[1])
        .then((response) => {
          setBoleto(response);
        }).catch((err) => {
          if (isServerError(err)) {
            setErrorBoleto(err.error);
          } else {
            console.error(err);
          }
        }).finally(() => {
          setShowModalBoleto(true);
          setLoadingBoleto(false);
        });
    } catch (err) {
      setLoadingBoleto(false);
    }
  };

  const removeModalClass = () => {
    const bodyclass = document.body.className
    if (bodyclass == "modal-open"){
      document.body.removeAttribute("style")
      document.body.removeAttribute("class")
    }
  };

  useEffect(() => {
    handleEffectSubmit();
    removeModalClass();
  }, []);

  const handleEffectSubmit = async () => {
    if (d && n && c) {
      return (
        ticketGeneration(c)
      )
    }
  };

  useEffect(() => {
    if (opened && buttonRef.current) {
      buttonRef.current.click();
    }
  }, [opened, buttonRef]);

  const handleLoadParcelas = async () => {
    if (parcelasNaoPagas.length || parcelasPagas.length) {
      setShow(!show);
    } else {
      setLoading(true);
      try {
        await ConsultarService
          .parcelas(id)
          .then((response) => {
            setError("");
            setShow(true);
            setParcelamentoId(response.payment_request_id);
            setParcelasPagas(response.paid_installments);
            setParcelasNaoPagas(response.unpaid_installments);
            setTotalDevedor(response.unpaid_installments.reduce((t, i) => t + i.total, 0));

            if (["lost", "canceled"].includes(status)) {
              setAlert("O seu parcelamento encontra-se cancelado ou perdido. Para regularizar sua situação, efetue um novo parcelamento.");
            }
          }).catch((err) => {
            if (isServerError(err)) {
              setError(err.error);
            } else {
              console.error(err);
            }
          }).finally(() => {
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const handleSelect = (parcelaSelecionada: Parcela) => () => {
    setParcelasNaoPagas(parcelasNaoPagas.map((parcela) => {
      if (parcela.installment_number === parcelaSelecionada.installment_number) {
        if (status === "active") {
          parcela.selected = !parcela.selected;
        } else if (status === "confirmed") {
          if (parcelaSelecionada.installment_number === 1) {
            parcela.selected = !parcela.selected;
          }
        }
      }

      return parcela;
    }));
    setTotalSelecionado(parcelasNaoPagas.filter((i) => i.selected).reduce((t, i) => t + i.total, 0));
  };

  const handleVisualizarInscricoes = async () => {
    try {
      setLoadingInscricoes(true);
      await ConsultarService
        .inscricoes(parcelamentoId)
        .then((response) => {
          setInscricoes(response);
          setShowModalInscricoes(true);
        }).catch((err) => {
          if (isServerError(err)) {
            setErrorInscricoes(err.error);
          } else {
            console.error(err);
          }
        }).finally(() => setLoadingInscricoes(false));
    } catch (err) {
      setLoadingInscricoes(false);
    }
  };

  const handleBillet = async (installment_number: number[]) => {
    try {
      await ConsultarService
        .gerarBoletoParcelamento(parcelamentoId, installment_number)
        .then((response) => {
          setAlertOverdueInstallments(false);
          setBoleto(response);
        }).catch((err) => {
          if (isServerError(err)) {
            setErrorBoleto(err.error);
          } else {
            console.error(err);
          }
        }).finally(() => {
          setShowModalBoleto(true);
          setLoadingBoleto(false);
        });
    } catch (err) {
      setLoadingBoleto(false);
    }
  };

  const handleGerarBoleto = async () => {
    try {
      setLoadingBoleto(true);
      let currentDate = moment().format("YYYY-MM-DD");
      const installmentsSelected = parcelasNaoPagas.filter((i) => i.selected);
      setParcelasSelecionadas(installmentsSelected);
      const parcelasVencidas = parcelasNaoPagas.filter((i) => Date.parse(i.due_date) < Date.parse(currentDate));
      const hasAllElems = parcelasVencidas.map((i) => i.installment_number)
        .every(element => installmentsSelected.map((i) => i.installment_number).includes(element));

      setParcelasAtrasadas(parcelasVencidas);
      if (hasAllElems) {
        await ConsultarService
        .gerarBoletoParcelamento(parcelamentoId, installmentsSelected.map((i) => i.installment_number))
        .then((response) => {
          setBoleto(response);
        }).catch((err) => {
          if (isServerError(err)) {
            setErrorBoleto(err.error);
          } else {
            console.error(err);
          }
        }).finally(() => {
          setShowModalBoleto(true);
          setLoadingBoleto(false);
        });
      } else {
        setAlertOverdueInstallments(true);
        setShowModalBoleto(true);
        setLoadingBoleto(false);
      }
    } catch (err) {
      setLoadingBoleto(false);
    }
  };

  const handleHideOverdueInstallment = () => {
    setAlertOverdueInstallments(false);
  };

  const handleHideBoleto = () => {
    setShowModalBoleto(false);
    setBoleto(undefined);
  };

  const handleHideInscricoes = () => {
    setShowModalInscricoes(false);
  };

  return(
    <div className="item-parcelamento">
      <button ref={buttonRef} onClick={handleLoadParcelas}>
        <div className="left">
          <div className="contributor">Contribuinte: {contributorName} - { cpfOrCnpjMask(contributorDocument as string).mask() }</div>
          <div className="numero">Número do Parcelamento: {numero}</div>
          <div className="data">DATA DA SOLICITAÇÃO DO PARCELAMENTO: {Format.date(data)}</div>
        </div>
        <div className="right">
          <div className="valor">{Format.currency(valor)}</div>
          <div className="valor-pago">PAGO {Format.currency(valorPago)}</div>
          <div className="status">{status_i18n}</div>
        </div>
        <div className="icon">
          {loading && <Loader />}
          {!loading && <FontAwesomeIcon icon={show ? faChevronUp : faChevronDown} />}
        </div>
      </button>
      {show && (
        <>
          <div className="item-parcelamento-parcelas">
            {alert && <Alert>{alert}</Alert>}
            <Modal title="Inscrições" show={showModalInscricoes} onHide={handleHideInscricoes}>
              {errorInscricoes && <Alert>{errorInscricoes}</Alert>}
              {inscricoes.map((inscricao) => (
                <div key={inscricao.id} className="item-inscricao">
                  <div className="item-inscricao-actions">
                    <button className="option" disabled>
                      <strong>Inscrição Administrativa: {inscricao.administrative_inscription}</strong><br />
                      {inscricao.inscription_date && <span className="data">Data de inscrição: {Format.date(inscricao.inscription_date)}</span>}
                      <span className="valor">Saldo devedor: {Format.currency(inscricao.balance)}</span><br />
                    </button>
                  </div>
                </div>
              ))}
            </Modal>
            <p>Clique nas parcelas não pagas para selecionar.</p>
            <div className="inscricoes">
              <p>Visualize as inscrições desse parcelamento no botão ao lado.</p>
              <Button
                label="VISUALIZAR"
                onClick={handleVisualizarInscricoes}
                loading={loadingInscricoes}
                size="small"
              />
            </div>
            {parcelasPagas.map((parcela) => (
              <ItemParcela
                key={parcela.installment_number}
                parcela={parcela.installment_number}
                totalParcelas={totalParcelas}
                valor={parcela.total}
                data={parcela.due_date}
                billet_date={null}
                status="pago"
                disabled
              />
            ))}
            {parcelasNaoPagas.map((parcela) => (
              <ItemParcela
                key={parcela.installment_number}
                parcela={parcela.installment_number}
                totalParcelas={totalParcelas}
                valor={parcela.total}
                data={parcela.due_date}
                status="aberto"
                billet_date={parcela.generate_billet_date}
                selected={parcela.selected}
                onSelect={handleSelect(parcela)}
              />
            ))}
          </div>
          <Modal show={showModalBoleto} onHide={handleHideBoleto}>
            {errorBoleto && <Alert>errorBoleto</Alert>}
            {boleto && <Boleto boleto={boleto} onHide={handleHideBoleto} />}
            {alertOverdueInstallments && (
              <AlertOverdueInstallmentModal
                installmentNumber={numero}
                installmentsOverdue={parcelasAtrasadas}
                selectedInstallments={parcelasSelecionadas}
                loadingBoleto={loadingBoleto}
                handleOnClick={handleBillet}
                totalParcelas={totalParcelas}
              />
            )}
          </Modal>
          <footer>
            <div className="total">
              Total selecionado: <strong>{Format.currency(totalSelecionado)}</strong>
            </div>
            <div className="total">
              Saldo devedor: <strong>{Format.currency(totalDevedor)}</strong>
            </div>
            <Button
              label="Gerar Boleto"
              loadingLabel="Gerando boleto..."
              disabled={totalSelecionado === 0}
              loading={loadingBoleto}
              onClick={handleGerarBoleto}
            />
          </footer>
        </>
      )}
      {error && (
        <div className="error">
          <Alert>{error}</Alert>
        </div>
      )}
    </div>
  );
}
