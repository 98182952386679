import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { isServerError } from "../../config/api";
import ConsultarService, { ResponseDebitos } from "../../services/ConsultarService";
import "./styles.css";
import Show from "../Show";
import ItemTipoReceita from "../ItemTipoReceita";
import Lottie from "lottie-react"
import loadingData from "../../assets/lotties/loading.json"
import { EpgmButtonNavigate } from "../EpgmButtonNavigate/indesx";
import { ReloadConsultDebts } from "../ReloadConsultDebts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import OverMaxDebts from "../OverMaxDebts";
import { AgilisButtonNavigate } from "../AgilisButtonNavigate";

interface Props {
  contributorData: ([]) => void;
  contributorDocument?: string;
  userType?: string;
  attendant_id?: number;
}

export default function ConsultDebtsVirtualDesk(props: Props) {
  const [data, setData] = useState<ResponseDebitos>();
  const [error, setError] = useState("");
  const [documento, setDocumento] = useState("");
  const [show, setShow] = useState(false);
  const [tabname , setTabname] = useState("");
  const [totalTributesbalance, setTotalTributesBalance] = useState<Array<any>>([]);
  const [totalTributesbalanceRefis, setTotalTributesBalanceRefis] = useState<Array<any>>([]);
  const [enabledRefis, setEnebledRefis] = useState(false);
  const [value, setValue] = useState(0);
  const [overMaxDebts, setOverMaxDebts] = useState(false);
  const [contributorIn, setContributorIn] = useState();

  useEffect(() => {
    handleSubmit();
  },[props.contributorDocument]);

  const formRef = useRef<FormHandles>(null);

  const handleReloadSearch = () => {
    formRef.current?.submitForm();
  };

  const setContributorData = ([contributorName, totalTributes, totalInscriptions, totalBalance, totalBalanceRefis, refisEnabled]: Array<any>) => {
    props.contributorData([contributorName, totalTributes, totalInscriptions, totalBalance, totalBalanceRefis, refisEnabled])
  }

  const handleSubmit = async () => {
    try {
      setData(undefined);

      await ConsultarService
        .debitos(props.contributorDocument as string, 'document', '')
        .then((response) => {
          if(!response.over_max_debts){
            setData(response)
            const currentTab = Object.keys(response.data)[0]
            document.getElementById(currentTab)?.classList.add("tabTipoReceitaSelected")
            document.getElementById(currentTab+"Mobile")?.classList.add("tabTipoReceitaSelected")
            setTabname(currentTab)
          } else if (response.over_max_debts) {
            setOverMaxDebts(true);
          }

          let totalIns = 0;
          let tributeValues = 0;
          let tributevaluesRefis = 0;
          let isrefisEnabled: boolean = false;
          let tributesQuantity: string = '';
          let contributorName: string = '';
          let totalPertribute: {type: string, balance: number};
          let totalPertributeRefis: {type: string, balance: number};
          let tributeBalance: number;
          let tributeBalanceRefis: number;
          let groupTributes: Array<any> = [];
          let groupTributesRefis: Array<any> = [];

          Object.keys(response.data).forEach((tipo) => {
            const situacoes = Object.keys(response.data[tipo]);
            tributesQuantity = tipo;
            totalIns += response.data[tipo][situacoes[0]].total_inscriptions;
            tributeValues += response.data[tipo][situacoes[0]].total_balance;
            tributevaluesRefis += response.data[tipo][situacoes[0]].balance_to_legal_action;
            isrefisEnabled = response.data[tipo][situacoes[0]].refis_enabled;
            contributorName = response.contributor_name_without_mask;
            tributeBalance = response.data[tipo][situacoes[0]].total_balance;
            tributeBalanceRefis = response.data[tipo][situacoes[0]].balance_to_legal_action;
            totalPertribute = {type: tipo, balance: tributeBalance};
            totalPertributeRefis = {type: tipo, balance: tributeBalanceRefis};
            tributeBalance = response.data[tipo][situacoes[0]].total_balance;
            tributeBalanceRefis = response.data[tipo][situacoes[0]].balance_to_legal_action;
            groupTributes.push(totalPertribute)
            groupTributesRefis.push(totalPertributeRefis)
            tributeBalance = 0;
            tributeBalanceRefis= 0;
          });
          setEnebledRefis(isrefisEnabled);
          setTotalTributesBalance(groupTributes);
          setTotalTributesBalanceRefis(groupTributesRefis);
          setDocumento(props.contributorDocument as string);
          setContributorData([contributorName, groupTributes.length, totalIns, tributeValues, tributevaluesRefis, isrefisEnabled])
          setContributorIn(response.contributor_in);
        }).catch((err) => {
          if (isServerError(err)) {
            setError(err.error);
            if (err.error == "Débitos não encontrados para os dados informados."){
            }
          } else {
            setError("Não foi possível estabelecer uma conexão com o servidor. Tente novamente mais tarde.");
            console.error(err);
          }
        })
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<{}>((o, c) => ({ ...o, [c.path]: c.message }), {});
        formRef.current?.setErrors(errors);
      }
    }
  };

  const ActiveTab = (e: { currentTarget: { id: any; }; }) => {
    var tabName = e.currentTarget.id
    setTabname(tabName);
    setShow(!show)
    const selectedtab = document.querySelectorAll(".tabTipoReceita")
    Array.from(selectedtab).map((item) => {
      item.classList.remove("tabTipoReceitaSelected")
    })
    const elementId = document.getElementById(tabName);
    elementId?.classList.add("tabTipoReceitaSelected");
  }

  const ActiveTabMobile = (e: { currentTarget: { id: any; }; }) => {
    var tabName = e.currentTarget.id
    setTabname(tabName);
    setShow(!show)
    const selectedtab = document.querySelectorAll(".tabTipoReceitaMobile")
    Array.from(selectedtab).map((item) => {
      item.classList.remove("tabTipoReceitaSelected")
    })
    const elementId = document.getElementById(tabName);
    elementId?.classList.add("tabTipoReceitaSelected");
  }

  const MoveCarousel = (currentarrow : any) => {
    const arrrowvalue = currentarrow.classList.value
    const lenghtofElement = (document.querySelectorAll(".MainContainner")).length
    var actualValue = value
    const maxValue = 201 * (lenghtofElement - 1)
    if (arrrowvalue === "arrowLeft" && value != 0) {
      setValue(actualValue + 201)
    }else if(arrrowvalue === "arrowRight" && value != (maxValue * -1)) {
      setValue(actualValue - 201)
    }
  }

  const reloadConsult = () => {
    setData(undefined);
    handleSubmit()
  }

  const verifyUserType = (type: string) => {
    if(type == 'attendant') {
      return(
        <div className="shortcuts">
          <div className="agilisRedirect">
            <AgilisButtonNavigate
              contributorDocument={props.contributorDocument as string}
              contributorIn={contributorIn}
            />
          </div>
          <div className="epgmRedirect">
            <EpgmButtonNavigate contributorDocument={props.contributorDocument as string}/>
          </div>
          <div className="reloadButton" onClick={reloadConsult}>
            <ReloadConsultDebts/>
          </div>
        </div>
      )
    }else {
      return(
        <div className="shortcutsHidden">
          <div className="epgmRedirectHidden">
            <AgilisButtonNavigate
              contributorDocument={props.contributorDocument as string}
              contributorIn={contributorIn}
            />
          </div>
          <div className="epgmRedirectHidden">
            <EpgmButtonNavigate contributorDocument={props.contributorDocument as string}/>
          </div>
          <div className="reloadButton" onClick={reloadConsult}>
            <ReloadConsultDebts/>
          </div>
        </div>
      )
    }

  }

  return(
    <>
      { verifyUserType(props.userType as string) }
      {data ?
        <div className="contentBody">
          {
            data &&
            <div className="tabsContentItens">
              <span className="arrowLeft" onClick={(e) => {MoveCarousel(e.currentTarget)}}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>
              <div className="carouselVisibility">
                <div className ="containerTabs">
                  {data && Object.keys(data.data).map((tipoReceita) => (
                    <div className="MainContainner">
                  <div className="receiptType">
                    <div className="tabTipoReceita" id={tipoReceita} onClick={ActiveTab}>
                      <div className="recipTittle">{tipoReceita}</div>
                        {data && Object.values(data).map((item) => {
                          const itemObject = item[tipoReceita]
                          if (itemObject != null || itemObject !== undefined) {
                            const totalInscriptions = itemObject["Em aberto"].total_inscriptions;
                            const totalLegalAction = itemObject["Em aberto"].total_inscriptions_legal_action;
                            const totalProtested = itemObject["Em aberto"].total_inscriptions_protested;
                            return(
                              <span className="inscriptionsInfo">
                                <span className="recipInformation"><strong className="labelName">Inscrições em aberto: </strong> {totalInscriptions}</span>
                                <span className="recipInformation"><strong className="labelName">Inscrições protestadas: </strong> {totalProtested}</span>
                                <span className="recipInformation"><strong className="labelName">Inscrições ajuizadas: </strong> {totalLegalAction}</span>
                              </span>
                            )
                          }
                        })}
                        <Show if={tabname === tipoReceita}>
                          <div className="selectedTab"></div>
                        </Show>
                    </div>
                  </div>
                  <div>
                    <div className="tipoReceitaMobile">
                      <div className="tabTipoReceitaMobile" id={tipoReceita+"Mobile"} onClick={ActiveTabMobile}>
                        <div className="recipTittle">{tipoReceita}</div>
                        {data && Object.values(data).map((item) => {
                          const itemObject = item[tipoReceita]
                          if (itemObject != null || itemObject !== undefined) {
                            const totalInscriptions = itemObject["Em aberto"].total_inscriptions;
                            return(
                              <span className="recipInformation">{totalInscriptions} Inscrições em aberto </span>
                            )
                          }
                        })}
                        <Show if={tabname+"Mobile" === tipoReceita+"Mobile" || tabname === tipoReceita+"Mobile"}>
                          <div className="selectedTab"></div>
                        </Show>
                      </div>
                    </div>
                  </div>
                </div>
                  ))}
                </div>
              </div>
              <span className="arrowRight" onClick={(e) => {MoveCarousel(e.currentTarget)}}>
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </div>
          }
          {
            data && Object.keys(data.data).map((tipoReceita) => (
              <div className="contentTypeVirtualDesk">
                <Show if={tabname === tipoReceita || tabname === tipoReceita+"Mobile"}>
                  <ItemTipoReceita
                    key={tipoReceita}
                    documento={documento}
                    title={tipoReceita}
                    status={data.data[tipoReceita]}
                    reloadSearch={handleReloadSearch}
                    totalBalances={totalTributesbalance}
                    totalBalancesRefis={totalTributesbalanceRefis}
                    enabledRefis={enabledRefis}
                    attendant_id={props.attendant_id}
                    showAddress={props.userType == 'attendant'}
                  />
                </Show>
              </div>
            ))
          }
        </div> :
        <div className="loadingScreen">
          <div className="loadingIcon">
            <Lottie
              animationData={loadingData}
              autoplay={true}
              loop={true}
            />
          </div>
          {
            overMaxDebts
              ? <strong className="loadingMessage">A busca retornou mais de 150 Inscrições*</strong>
              : <span className="loadingMessage">Carregando dados, por favor aguarde.</span>
          }
        </div>
      }
    </>
  )
}
