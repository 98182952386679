import React from "react";
import Container from "../../components/Container";
import "./style.css";
import { Link } from "react-router-dom";

export default function PaginaNaoEncontrada() {
  return (
    <Container items={[]}>
      <div className="page-error">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-8">
          <h1>Opa!</h1>
          <h2>Não estamos conseguindo encontrar a página que você está procurando.</h2>
          <h6>Código de erro: 404</h6>
          <ul className="list-unstyled">
            <li>Aqui estão alguns links úteis:</li>
            <li><Link to="/">Página Inicial</Link></li>
            <li><Link to="/consultar/debitos/em_aberto">Consultar Dívidas</Link></li>
            <li><Link to="/consultar/parcelamentos">Consultar parcelamentos</Link></li>
            <li><Link to="/validar-documento">Validar Documento Assinado</Link></li>
            <li><Link to="/paineis/duvidas_frequentes">Dúvidas Frequentes</Link></li>
          </ul>
          </div>
        </div>
      </div>
    </Container>
  );
}
