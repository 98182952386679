
import { get, post } from "../config/api";

export interface ResponseProtest {
  id: number,
  code: string,
  balance: number,
  receipt_type: string,
  requested_at: string,
  type: string,
  can_generate_billet: boolean,
  contributor: Contributor,
  inscription: Inscription
}

export interface Contributor {
  id: number,
  name: string,
  document: string,
  cpf: string | null,
  cnpj: string | null,
  has_requirements: boolean,
  missing_address: boolean,
  contact: Contact,
  address: Address,
}

export interface Contact {
  email: string | null,
  phone: string | null,
  cellphone: string | null
}

export interface Address {
  street: string,
  street_number: string,
  neighborhood: string,
  city: string,
  state: string,
  cep: string,
  address_complement: string
}

export interface Inscription {
  id: number,
  cda_number: string,
  original_value: number,
  administrative_inscription: string,
  fiscal_date: string,
  inscription_date: string,
  state: State
}

export interface State {
  code: string,
  description: string
}

export default class ProtestoService {
  public static async protestos(cdaNumber: string, password: string) {
    return get<ResponseProtest>("/payment_requests/protested", {
      cda_number: cdaNumber,
      password: password,
      });
  }

  public static async documentos(paymentRequestId: number, documentType: string) {
    return post<any>("/payment_requests/protested/document", {
      payment_request_id: paymentRequestId,
      document_type: documentType,
      });
  }

  public static async gerar_multiplos(email: string, password: string, protest_protocols: string[]) {
    return post<any>("/payment_requests/protested/send_billets", {
      email: email,
      password: password,
      protest_protocols: protest_protocols,
      });
  }
}
