import React, { useEffect } from "react";
import Opovo from '../../../assets/images/namidia-opovo.png';
import DiarioDoNordeste from '../../../assets/images/diario_nordeste-logo.png';
import LogoPrefeitura from '../../../assets/images/logo-pgm-padrao.svg';
import { Link, animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

export default function Carosel() {

  useEffect(() => {
    loadCarousel();
  }, [])

  const loadCarousel = () => {
    $('#recipeCarousel').carousel({
      interval: 10000
    })

    $('.carousel .carousel-item').each(function () {
      var minPerSlide = 3;
      var next = $(this).next();
      if (!next.length) {
        next = $(this).siblings(':first');
      }
      next.children(':first-child').clone().appendTo($(this));

      for (var i = 0; i < minPerSlide; i++) {
        next = next.next();
        if (!next.length) {
          next = $(this).siblings(':first');
        }

        next.children(':first-child').clone().appendTo($(this));
      }
    });
  }

  return (
    <div className="section6">
      <div className="container">
        <Link id="scroll-up" className=" d-block scroll-up ml-auto mr-5 py-2" smooth={true} duration={500} to="home">
          <FontAwesomeIcon className="mx-1" icon={faArrowCircleUp} /> Voltar ao topo
        </Link>
      </div>
    </div>
  );
}
