import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faHouseUser  } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import Button from "../../../components/Form/Button";
import ProtestoService, { ResponseProtest } from "../../../services/ProtestoService";
import Format from "../../../helpers/Format";
import moment from "moment";
import { isServerError } from "../../../config/api";

interface Props {
  data: ResponseProtest;
}

export default function Item({ data }: React.PropsWithChildren<Props>) {
  const [loading, setLoading] = useState(false);
  const [loadingCda, setLoadingCda] = useState(false);
  const [error, setError] = useState("");

  const getButtonLabel = (type: string) => {
    if (type === "dam") {
      return "Gerar DAM"
    }
    return "Gerar Boleto"
  }

  const getLoadingLabel = (type: string) => {
    if (type === "dam") {
      return "Gerando DAM..."
    }
    return "Gerando Boleto..."
  }

  const downloadPDF = (base64String: string, document_type: string) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = document_type === "cda" ? "certidao.pdf" : "boleto.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleSubmitDocument = async (id: number, type: string) => {
    if (type === "cda") {
      setLoadingCda(true)
    } else {
      setLoading(true);
    }
    setError("");
    try {
      await ProtestoService
        .documentos(id, type)
        .then((response) => {
          downloadPDF(response.document, type);
        }).catch((err) => {
          if (err instanceof Error) {
            setError("Não foi possível estabelecer uma conexão com o servidor. Tente novamente mais tarde.");
          } else if (isServerError(err)) {
            setError(err.error);
          } else {
            console.error(err);
          }
        }).finally(() => {
          setLoading(false);
          setLoadingCda(false);
        });
    } catch (err) {
      setLoading(false);
      setLoadingCda(false);
    }
  };

  return (
    <>
      <div className="item-protesto">
      <div className="protesto-infos">
        <div className="header">
          <p className="cda">CDA: {data.inscription.cda_number}</p>
        </div>
        <div className="content">
          <div className="column">
            <p className="inscrição-administrativa">
              <span className="inscrição-administrativa-label">Inscrição Administrativa: </span>
              <span className="inscrição-administrativa-value">{data.inscription.administrative_inscription}</span>
            </p>
            <p className="valor">
              <span className="valor-label">Valor Original: </span>
              <span className="valor-value">{Format.currency(data.inscription.original_value)}</span>
            </p>
          </div>
          <div className="column">
            <p className="data">
              <span className="data-label">Data da inscrição: </span>
              <span className="data-value">{moment(data.inscription.inscription_date).format('DD/MM/YYYY')}</span>
            </p>
            <p className="fase">
              <span className="fase-label">Fase: </span>
              <span className="fase-value">{data.inscription.state.description}</span>
            </p>
          </div>
          <div className="column">
            <p className="exercicio">
              <span className="exercicio-label">Exercício: </span>
              <span className="exercicio-value">{data.inscription.fiscal_date}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="button">
        <Button
          label="Gerar CDA"
          loadingLabel="Gerando CDA..."
          disabled={false}
          loading={loadingCda}
          size="large"
          onClick={() => handleSubmitDocument(data.id, "cda")}
        />

        <Button
          label={getButtonLabel(data.type)}
          loadingLabel={getLoadingLabel(data.type)}
          disabled={!Boolean(data.can_generate_billet)}
          loading={loading}
          size="large"
          onClick={() => handleSubmitDocument(data.id, "dam")}
        />
      </div>
      </div>
      {error && <p className="alert alert-warning">{error}</p>}
    </>
  );
}
