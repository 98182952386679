import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

interface Props {
  id?: string;
  title: string;
  icon: string;
  to: string;
  yellowStyle?: boolean;
  targetBlank?: boolean;
  action?(event: React.MouseEvent<HTMLElement>): void;
}

export default function Card({id, title, icon, to, yellowStyle, targetBlank, action}: Props) {
  const styleClass = yellowStyle ? "pgm-card-yellow" : "";
  const btnTarget = targetBlank ? "_blank" : "";

  return (
    <Link id={id} to={to} className={`pgm-card ${styleClass}`} target={btnTarget} onClick={action}>
      <span className="icon">
        <img src={icon} alt={title} width={48} height={48}/><br/>
      </span>
      <span className="label">{title}</span>
    </Link>
  );
}
