import React, { useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import { useQuery } from "../../helpers/Utils";
import { isCpfOrCnpj } from "../../helpers/Validates";
import { cpfOrCnpjMask } from "../../helpers/Masks";
import { isServerError } from "../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import ProtestoService, { ResponseProtest } from "../../services/ProtestoService";
import Show from "../../components/Show";
import ItemHeader from "./ItemHeader";
import Item from "./Item";
import TextArea from "../../components/Form/TextArea";

interface FormData {
  cdaNumber: string;
  password: string;
}

interface FormDataMultiple {
  protest_protocols: string[];
  password: string;
  email: string;
}

export default function ConsultarProtesto() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ResponseProtest | undefined>();
  const [formType, setFormType] = useState('default');
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const formRef = useRef<FormHandles>(null);

  const query = useQuery();
  const d = query.get("d");
  const n = query.get("n");

  const handleSubmitConsultarProtesto = async (cda: string, password: string) => {
    setSuccess("");
    setLoading(true);
    await ProtestoService
      .protestos(cda, password)
      .then((response) => {
        setData(response);
      }).catch((err) => {
        if (isServerError(err)) {
          setError(err.error);
        } else {
          console.error(err);
        }
      }).finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitGerarMultiplos = async (email: string, password: string, protest_protocols: string[]) => {
    setLoading(true);
    setSuccess("");
    await ProtestoService
      .gerar_multiplos(email, password, protest_protocols)
      .then((response) => {
        setSuccess(`Sucesso! ${response.total} protocolos foram processados e serão enviados por e-mail em até 2h.`)
      }).catch((err) => {
        if (isServerError(err)) {
          setError(err.error);
        } else {
          console.error(err);
        }
      }).finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      setData(undefined);
      setError("");

      formRef.current?.setErrors({});

      const schema = Yup.object<FormData>().shape({
        cdaNumber: Yup.string().required("Número da CDA é obrigatório"),
        password: Yup.string().required("Senha não pode ficar em branco"),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      await handleSubmitConsultarProtesto(formData.cdaNumber, formData.password);
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<{}>((o, c) => ({ ...o, [c.path]: c.message }), {});
        formRef.current?.setErrors(errors);
      }
    }
  };

  const handleEffectSubmit = async () => {
    if (formRef && formRef.current && d && n) {
      formRef.current.setFieldValue("cdaNumber", d);
      formRef.current.setFieldValue("password", n);
      await handleSubmitConsultarProtesto(d, n);
    }
  };

  useEffect(() => {
    handleEffectSubmit();
  }, [formRef, formRef.current?.getFieldValue("cdaNumber")]);

  const toggleFormType = () => {
    setData(undefined);
    setError("");
    setSuccess("");
    setFormType(formType === 'default' ? 'custom' : 'default');
  };

  const handleSubmitMultiples: SubmitHandler<FormDataMultiple> = async (formData) => {
    try {
      setData(undefined);
      setError("");

      formRef.current?.setErrors({});

      const schema = Yup.object<FormDataMultiple>().shape({
        email: Yup.string()
          .email("E-mail inválido")
          .required("E-mail é obrigatório"),
        password: Yup.string()
          .required("Senha é obrigatória"),
          protest_protocols: Yup.array()
          .transform((value, originalValue) => originalValue === "" ? [] : value)
          .of(Yup.string().required("Protocolo de protesto é obrigatório"))
          .test("not-empty", "Pelo menos um protocolo é necessário", value => value.length > 0),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      await handleSubmitGerarMultiplos(formData.email, formData.password, formData.protest_protocols);
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<{}>((o, c) => ({ ...o, [c.path]: c.message }), {});
        formRef.current?.setErrors(errors);
      }
    }
  };

  return (
    <Layout title="Títulos Protestados" className="consultar-protesto" protest balcaoVirtual balcaoTitle={"Tem dúvidas?"} menuName="servicos">
      <a href="/servicos" className="link-direction">
        <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
        Ver todos os serviços
      </a>
      <span className="h3 container-title mt-4">Títulos Protestados</span>
      <div className="alert alert-warning text-center">
        <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
        <span>Este serviço é de uso exclusivo dos cartórios.</span>
      </div>
      <div className="toggle-container">
        <div className="switch-label">
          <label className="form-label">Gerar múltiplos boletos</label>
          <label className="switch">
            <input type="checkbox" checked={formType === 'custom'} onChange={toggleFormType} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <Show if={formType === "default"}>
        <Form className={`search ${d ? "d-none" : ""}`} ref={formRef} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-5 mt-3">
              <Input id={'cdaNumber'} label="Número da CDA" name="cdaNumber" autoFocus placeholder={"Digite o número da CDA"} />
            </div>
            <div className="col-12 col-sm-6 col-lg-5 mt-3">
              <Input label="Senha do cartório" name="password" type="password" placeholder={"Digite a senha"} />
            </div>
            <div className="col-12 col-sm-6 col-lg-2 col-search mt-3">
              <Button label="Pesquisar" loading={loading} block />
            </div>
          </div>
        </Form>
      </Show>
      <Show if={formType === "custom"}>
        <Form className={`search ${d ? "d-none" : ""}`} ref={formRef} onSubmit={handleSubmitMultiples}>
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-12 mt-3">
              <TextArea
                id={'protestProtocols'}
                label="Números de protocolo"
                name="protest_protocols"
                autoFocus
                placeholder={"Digite os protocolos"}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mt-3">
              <Input label="Email" name="email" placeholder={"Digite o e-mail destino"} />
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mt-3">
              <Input label="Senha do cartório" name="password" type="password" placeholder={"Digite a senha"} />
            </div>
            <div className="col-12 col-sm-6 col-lg-3 col-search mt-3">
              <Button label="Gerar boletos" loading={loading} block />
            </div>
          </div>
        </Form>
      </Show>
      {d && loading && <Loader label="Carregando..." />}
      {error && <p className="alert alert-danger">{error}</p>}
      {success && <p className="alert alert-success">{success}</p>}
      {data &&
        <>
          <div className="row mb-1">
            <div className="col-12 mt-auto">
              <div className="total">
                <strong>Contribuinte:</strong> {data?.contributor.name} - {cpfOrCnpjMask(data?.contributor.document).mask()}
              </div>
              <div className="total">
                <strong>Número da CDA:</strong> {data?.inscription.cda_number.replace(/\D/g, '')}
              </div>
            </div>
          </div>
          <div className="contentInformation">
            <ItemHeader
              key={data?.id}
              balance={data?.balance}
              code={data?.code}
              receiptType={data?.receipt_type}
              requestedAt={data?.requested_at}
            >
              <Item data={data} />
            </ItemHeader>
          </div>
        </>
      }
    </Layout>
  );
}
