import React, { useState, useCallback } from 'react';
import "./style.css";

interface DefaultProps {
  message?: string;
  textButton?: string;
}

export const Cookie: React.FC<DefaultProps> =
  ({ message, textButton }) => {
    const [cookies, setCookies] = useState(() => {
      return !!localStorage.getItem('@CookiesDefault:ReactJS');
    });

    const handleAcceptCookies = useCallback(() => {
      localStorage.setItem('@CookiesDefault:ReactJS', 'accept');
      setCookies(true);
    }, []);

    return (
      <>
        {!cookies && (
          <div className="container">
            <div className="row box-cookies">
              <div className="col-12 col-sm-12 col-lg-9 col-xl-9">
                <p className="msg-cookies">
                  {message ? message : <span><strong>PGM e os cookies:</strong> utilizamos cookies para melhorar sua experiência no site e analisar como você interage. Ao continuar navegando, você concorda com o uso de cookies.</span>}
                </p>
              </div>
              <div className="col-12 col-sm-12 col-lg-3 col-xl-3">
                <button className="btn-cookies" onClick={handleAcceptCookies}>{textButton ? textButton : 'Continuar e fechar!'}</button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
