interface MaskInput {
  unmask(): string;
  mask(): string;
}

export function cellphoneMask(value: string): MaskInput {
  const cleanValue = value.replace(/\D/g, "");

  return {
    unmask: () => cleanValue,
    mask: () => cleanValue
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d{1,4})/, "$1-$2"),
  };
}

export function cpfMask(value: string): MaskInput {
  const cleanValue = value.replace(/\D/g, "");

  return {
    unmask: () => cleanValue,
    mask: () => cleanValue
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2"),
  };
}

export function cpfOrCnpjMask(value: string): MaskInput {
  const strFrom = 0;
  const cpfLength = 11;
  const cleanValue = value.replace(/\D/g, "");

  if (cleanValue.length <= cpfLength) {
    return {
      unmask: (): string => cleanValue,
      mask: (): string => cleanValue
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2"),
    };
  }
  const cnpjLength = 14;

  return {
    unmask: () => cleanValue,
    mask: () => cleanValue.substr(strFrom, cnpjLength)
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})/, "$1-$2"),
  };
}

export function onlyAlphanumeric(value: string): MaskInput {
  const cleanValue = value.replace(/\W/g, "");

  return {
    unmask: () => cleanValue,
    mask: () => cleanValue,
  };
}

export function zipCodeMask(value: string): MaskInput {
  const cleanValue = value.replace(/\D/g, "");

  return {
    unmask: () => cleanValue,
    mask: () => cleanValue
      .replace(/(\d{2}[.\s]?)/, "$1.")
      .replace(/(\d{3}[-.\s]?)/, "$1-")
      .replace(/(\d{3})/, "$1"),

  };
}
