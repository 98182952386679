import React, { useState } from "react";
import NavBar from "../../components/LandingPage/NavBar";
import CurvaTopo from "../../assets/images/curva-topo-creme.svg";
import ModalBalcaoVirtual from "../../components/ModalBalcaoVirtual";
import Menu from "../../components/Menu"
import "./style.css";
import WebBrowserAlert from "../WebBrowserAlert";

interface Props {
  title?: string;
  balcaoVirtual?: boolean;
  balcaoTitle?: string;
  className?: string;
  menuInternal?: boolean;
  balcaoSelecionado?: number;
  container?: boolean;
  menuName?: string;
  header?: boolean;
  protest?: boolean;
}

export default function Layout({ children, title, balcaoVirtual, balcaoTitle, className,
   menuInternal, balcaoSelecionado, container, menuName, header, protest }: React.PropsWithChildren<Props>) {

  const [showModalBalcaoVirtual, setShowModalBalcaoVirtual] = useState(false);

  const handleShowBalcaoVirtual = async () => {
    setShowModalBalcaoVirtual(true);
  }

  const handleHideBalcaoVirtual = () => {
    setShowModalBalcaoVirtual(false);
  }

  document.title = `Portal da PGM - ${title}`;
  return (
    <div className={`layout ${className}`}>
      {container == false ?
        <>
          <div className="container">
            <NavBar menuName={menuName}></NavBar>
          </div>
          {children}
        </> :
        <>
          <div className="container">
            <NavBar menuName={menuName}></NavBar>
            <div className="my-5 px-3 layout-body">
              <div className="row">
              {header &&
                <div className="col-6 d-flex align-items-center">
                  <span className="h3 container-title">
                    {title}
                  </span>
                </div>
              }
                {
                  menuInternal && (
                   <>
                    <WebBrowserAlert start/>
                    <div className="col-6">
                      <Menu balcaoSelecionado={balcaoSelecionado}></Menu>
                    </div>
                   </>
                  )
                }
              </div>
              {children}
            </div>
          </div>
        </>
      }
      {balcaoVirtual &&
        <div className={`sac-container ${protest ? 'protest-true' : ''}`}>
          <div className="image-separator-container">
            <img className="w-100" src={CurvaTopo} alt="" />
          </div>
          <div className="atendimento-balcao-virtual pt-5">
            <p className="px-3">
              <span className="h2 font-weight-bold">{balcaoTitle ? balcaoTitle : "Quer ser atendido online?"}</span><br />
              <span className="h5">Acesse o nosso Balcão Virtual.</span>
            </p>
            <button type="button" className="btn layout-btn-balcao-virtual" onClick={handleShowBalcaoVirtual}>
              Acessar Balcão Virtual
            </button>
          </div>
          <ModalBalcaoVirtual show={showModalBalcaoVirtual} onHide={handleHideBalcaoVirtual} />
        </div>
      }
      <div className="footer">
        <span className="h6">Desenvolvido pela Coordenadoria de Tecnologia, Governança e Inovação da Procuradoria Geral do Município de Fortaleza.</span>
      </div>
    </div>
  );
}
