import React, { useEffect, useState } from 'react';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset, faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import { InputContributor } from '../ContributorData/InputContributor';
import { useHistory } from 'react-router-dom';
import BalcaoVirtualService from '../../../services/BalcaoVirtualService';
import AtendimentoService from '../../../services/AtendimentoService';

type RatingProps = {
  balcaoId: string;
  attendantId: any;
  attendantName: string;
}

export function Rating(props: RatingProps) {
  const [clicked, setClicked] = useState([false, false, false, false, false]);
  const [comment, setComment] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [attendant, setAttendant] = useState(0);
  const history = useHistory();
  useEffect(() => {
    BalcaoVirtualService.balcaoPorID(props.balcaoId).then((r) => {
      setAttendant(r.attendant_id)
    })
  },[]);

  const handleStarClick = (e: any, index: any) => {
    e.preventDefault();
    setDisabled(false)
    let clickStates = [...clicked];
    for (let i = 0; i < 5; i++) {
      if (i <= index) clickStates[i] = true;
      else clickStates[i] = false;
    }

    setClicked(clickStates);
  };

  const handleSubmitRate = () => {
    const document = localStorage.getItem('document') || '';
    const attendanceId = localStorage.getItem('attendance_id') || '';
    const rate = clicked.filter((value) => value).length;
    AtendimentoService.salvarInformacoesContributor(attendant, document, rate, attendanceId, comment).then(() => {
      history.push('/');
    });
  };

  return (
    <div className="rating-container">
      <div className="container">
        <div className="rating">
          <p className='auxiliar-text'>Por favor, avalie nosso atendimento.</p>
          <div className='atendente-icon'>
            <FontAwesomeIcon icon={faHeadset} />
          </div>
          <p className='atendente-nome'>{props.attendantName}</p>
          <div className="rating-stars">
            <div>
              <FontAwesomeIcon icon={faStar}
                onClick={(e) => handleStarClick(e, 0)}
                className={clicked[0] ? 'clickedstar' : 'emptystar'}
              />
              <FontAwesomeIcon icon={faStar}
                onClick={(e) => handleStarClick(e, 1)}
                className={clicked[1] ? 'clickedstar' : 'emptystar'}
              />
              <FontAwesomeIcon icon={faStar}
                onClick={(e) => handleStarClick(e, 2)}
                className={clicked[2] ? 'clickedstar' : 'emptystar'}
              />
              <FontAwesomeIcon icon={faStar}
                onClick={(e) => handleStarClick(e, 3)}
                className={clicked[3] ? 'clickedstar' : 'emptystar'}
              />
              <FontAwesomeIcon icon={faStar}
                onClick={(e) => handleStarClick(e, 4)}
                className={clicked[4] ? 'clickedstar' : 'emptystar'}
              />
            </div>
          </div>
          <textarea value={comment} onChange={(e) => setComment(e.target.value)} placeholder='Digite um comentário aqui...' className="input-atendente">
          </textarea>
          <button disabled={disabled}
                onClick={handleSubmitRate} className='avaliar-button'>
            <span>
              Enviar avaliação
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
