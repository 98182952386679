import React from "react";
import { env } from "../../config/api";

interface Props {
  message: string;
  color: string;
  backgroundColor: string;
}

function MessageEnv({message, color, backgroundColor}: Props) {
  return (
    <nav className="navbar navbar-light navbar-fixed-top" style={{backgroundColor, justifyContent: "center", padding: 0}}>
      <span className="navbar-text" style={{color, padding: 0, fontSize: 12 }}>
        <strong>{message}</strong>
      </span>
    </nav>
  );
}

export default function EnviromentMessage() {
  if (env() === "development") {
    return <MessageEnv message="VOCÊ ESTÁ NO AMBIENTE DE DESENVOLVIMENTO!" color="#FFFFFF" backgroundColor="#c0392b" />;
  }

  if (env() === "staging") {
    return <MessageEnv message="VOCÊ ESTÁ NO AMBIENTE DE HOMOLOGAÇÃO!" color="#8a6d3b" backgroundColor="#fffa0080" />;
  }

  return null;
}
