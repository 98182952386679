import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import "./style.css";
import iconPortal from "../../../assets/images/illustration-portal-do-contribuinte.svg";
import IconDividaAtiva from "../../../assets/images/illustration-app-dividaativa.svg";
import iconBalcao from "../../../assets/images/icon-balcaovirtual-maisrecomendado.svg";
import iconPresencial from "../../../assets/images/landingPage/illustration-presencial.svg";
import ModalBalcaoVirtual from "../../ModalBalcaoVirtual";
import ModalInfoPortal from "../../LandingPage/ModalInfoPortal";

export default function Section2() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const balcao =  searchParams.get('balcao') === 'true';

  const [showModalBalcaoVirtual, setShowModalBalcaoVirtual] = useState(balcao);
  const [showModalInfoPortal, setShowModalInfoPortal] = useState(false);

  const handleShowBalcaoVirtual = async () => {
    setShowModalBalcaoVirtual(true);
  }

  const handleHideBalcaoVirtual = () => {
    setShowModalBalcaoVirtual(false);
  }

  const handleHideInfoPortal = () => {
    setShowModalInfoPortal(false);
  }

  const handleShowInfoPortal = () => {
    setShowModalInfoPortal(true);
  }

  return (
    <>
      <div id="atendimento" className="atendimento">
        <div className="container mb-5">
          <div className="row">
            <div className="title-top text-center col-lg-12 col-md-12 col-xs-12">
              <h5 className="title-small">Nossos serviços online</h5>
              <h1 className="title">
                Escolha como prefere realizar seu atendimento
              </h1>
            </div>
            <div className="row text-center align-items-stretch mb-5">
              <div className="pt-4 pb-4 col-xs-12 col-md-12 col-lg-4 d-flex">
                <div className="my-card">
                  <img className="img-fluid m-3" src={iconPortal} alt="" />
                  <h2 className="mb-4 title-card">
                    Portal do Contribuinte
                  </h2>
                  <p className="description-atendimento mb-4">
                    Você consulta seus débitos, gera boleto à vista ou parcelado e acessa demais contatos da PGM.
                  </p>
                  <a className="my-link" href="#atendimento" onClick={handleShowInfoPortal} >
                    Acessar Portal
                  </a>
                </div>
              </div>
              <div className="pt-4 pb-4 col-xs-12 col-md-12 col-lg-4 d-flex">
                <div className="my-card2">
                  <img className="img-fluid m-3" src={iconBalcao} alt="" />
                  <h2 className="mb-4 title-card">
                    Balcão Virtual
                  </h2>
                  <p className="description-atendimento mb-4">
                    Converse com um de nossos atendentes em tempo real agora mesmo em uma de nossas salas online.
                  </p>
                  <a className="my-link" href="#atendimento" onClick={handleShowBalcaoVirtual}>
                    Ver salas online
                  </a>
                </div>
              </div>
              <div className="pt-4 pb-4 col-xs-12 col-md-12 col-lg-4 d-flex">
                <div className="my-card3">
                  <img className="img-fluid m-3" src={IconDividaAtiva} alt="" />
                  <h2 className="mb-4 title-card">
                    App Dívida Ativa
                  </h2>
                  <p className="description-atendimento mb-4">
                    Você também pode baixar o aplicativo para consultar seus débitos e gerar boleto à vista ou parcelado.
                  </p>
                  <div className="col-12">
                    <a className="my-link" href="https://play.google.com/store/apps/details?id=br.gov.ce.fortaleza.pgm.dividaativa" target="_blank">
                      Android
                    </a>
                    <span> e </span>
                    <a className="my-link" href="https://apps.apple.com/br/app/d%C3%ADvida-ativa-fortaleza/id1499380698" target="_blank">
                      iOS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalBalcaoVirtual show={showModalBalcaoVirtual} onHide={handleHideBalcaoVirtual} />
      <ModalInfoPortal show={showModalInfoPortal} onHide={handleHideInfoPortal} />
    </>
  );
}
