import React from "react";
import Format from "../../helpers/Format";
import "./styles.css";

interface Props {
  parcela: number;
  totalParcelas: number;
  valor: number;
  data: string;
  status: "pago" | "aberto";
  billet_date: string | null;
  disabled?: boolean;
  selected?: boolean;
  onSelect?(): void;
}

export default function ItemParcela({ parcela, totalParcelas, valor, data, status, billet_date, selected, disabled, onSelect}: Props) {
  const klass: string[] = ["item-parcela"];
  const klassLabel: string[] = ["label"];

  const emAtraso = new Date(`${data}T24:59:59`) < new Date();
  const label = status === "pago" ? "PAGO" : (emAtraso ? "EM ATRASO" : "EM ABERTO");

  if (status === "pago") {
    klassLabel.push("label-pago");
  } else if (emAtraso) {
    klassLabel.push("label-atraso");
  } else {
    klassLabel.push("label-aberto");
  }

  if (selected) {
    klass.push("selected");
  }

  if (disabled) {
    klass.push("disabled");
  }

  return (
    <button className={klass.join(" ")} disabled={disabled} onClick={onSelect}>
      <div className="parcela">
        {parcela}/{totalParcelas}
      </div>
      <div className="infos">
        <strong>{Format.currency(valor)}</strong><br />
        Vencimento da Parcela: {Format.date(data)}
      </div>
      {
        billet_date !== null &&
        <div className="billet_date">
          BOLETO GERADO EM {Format.date(billet_date)}
        </div>
      }
      <div className="status">
        <div className={klassLabel.join(" ")}>{label}</div>
      </div>
    </button>
  );
}
