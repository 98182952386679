import React from "react";
import Layout from "../../components/Layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

export default function CentralLicitacao() {

  return (
    <Layout title="Central de Licitações de Fortaleza" header balcaoVirtual menuName="institucional">
      <p>
        Acesso aos Serviços da CLFOR.
      </p>
      <div className="central">
        <div>
          <FontAwesomeIcon icon={faLink} />
          <a href="https://compras.sepog.fortaleza.ce.gov.br/publico/index.asp" target="_blank">Acesso à compras</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faLink} />
          <a href="https://compras.sepog.fortaleza.ce.gov.br/publico/modelo-minutas.asp" target="_blank">Acesso à minutas</a>
        </div>
        <table className="table ">
          <thead>
            <tr>
              <th className="border-left-yellow" scope="col">Setor</th>
              <th className="title-table" scope="col">
                <div className="border-left border-white" style={{width: 100}}>
                  Ramal
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" className="border-left-yellow">RECEPÇÃO DA PRESIDÊNCIA</th>
              <td className="border-left">3452-3477</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">PROTOCOLO - Wellington</th>
              <td className="border-left">3452-3483</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">ASJUR – Dra. Lucrecia </th>
              <td className="border-left">3452-3473</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">CPL - Andrea</th>
              <td className="border-left">3452-3481</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">CEL - Conceição</th>
              <td className="border-left">3488-8911</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">COTEC - Sebastião</th>
              <td className="border-left">3452-3476</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">COPUB – Clécie</th>
              <td className="border-left">3452-3482</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">COCAF - Praciano</th>
              <td className="border-left">3452-3470</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">COAFI - Marcelo</th>
              <td className="border-left">3452-1789</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">COPEN - Elka</th>
              <td className="border-left">3105-1375</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">COAUD - Bhrenda</th>
              <td className="border-left">3488-8913</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">COPEP - Lena</th>
              <td className="border-left">3105-1157</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">CORED - Joaquim</th>
              <td className="border-left">3452-3480</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">COARP - Fernanda</th>
              <td className="border-left">3452-3475</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Layout >
  )
}
