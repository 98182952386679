import React from "react";
import Container from "../../components/Container";
import "./style.css";

export default function ExecucaoFiscal() {
  return (
    <Container items={[{ label: "Página Inicial", to: "/" }, { label: "Orientações sobe execução fiscal", active: true }]}>
      <p>
        A Procuradoria Geral do Município, por meio da Procuradoria Fiscal (PROFIS)
        promove a cobrança judicial da Dívida Ativa tributária e não tributária do
        município de Fortaleza, desenvolvendo ações estratégicas tendentes à recuperação
        de créditos fiscais e defendendo os interesses municipais nas ações e processos
        de qualquer natureza fiscal, inclusive, mandados de segurança, mandados de segurança
        coletivos, mandados de injunção e habeas data, quando relativos à matéria tributária
        e financeira, bem como com a propositura e acompanhamento das execuções fiscais,
        inclusive, com a instituição de grupo especial voltado aos grandes devedores.
      </p>
      <p>
        A PROFIS atua, também, no âmbito extrajudicial, com a análise e emissão de
        parecer em processos administrativos de sua competência e junto ao Contencioso
        Administrativo Tributário (CAT) visando à garantia do cumprimento da legalidade
        tributária nos julgamentos administrativos. Orienta, de igual modo, a Secretaria
        das Finanças (SEFIN) nos assuntos pertinentes à Administração Tributária.
      </p>
      <p>
        É oferecido ao contribuinte um setor de atendimento, no qual o cidadão poderá
        obter todas as informações sobre os débitos e condições facilitadas de pagamento,
        contando, ainda, com um plantão de Procuradores para esclarecimentos processuais.
        E, agora, por meio do Canal da Dívida Ativa, o contribuinte também pode solucionar
        uma série de questões, via internet.
      </p>
    </Container>
  );
}
