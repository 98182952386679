import React, { useState, useEffect } from "react";
import BalcaoVirtualService from "../../services/BalcaoVirtualService";
import "./style.css";
import ModalConfirm from "../../components/ModalConfirm";
import Usuarios from "../../views/Usuarios"
import { useHistory } from "react-router-dom";
import { logoutAtendente, getUser } from "../../services/AuthService"

interface Props {
  balcaoSelecionado?: number;
}

export default function Menu({ balcaoSelecionado }: React.PropsWithChildren<Props>) {
  const history = useHistory();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalUsers, setShowModalUsers] = useState(false);
  const [admin, setAdmin] = useState<Boolean>()

  useEffect(() => {
    const user = JSON.parse(getUser() || "");
    const adminTemp = user.roles.map((option: any) => { return option.activities.includes("admin:all") });
    setAdmin(Boolean(adminTemp[0]))
  }, []);

  const logout = async () => {
    if (balcaoSelecionado) {
      BalcaoVirtualService.changeStatus("unavailable", balcaoSelecionado, '')
    }
    logoutAtendente();
    history.push('/login');
  };

  const handleShowModalConfirm = () => {
    setShowModalConfirm(true);
  };

  const handleHideModalConfirm = () => {
    setShowModalConfirm(false);
  };

  const handleHideModalUsers = () => {
    setShowModalUsers(false);
  };

  const handleOpenModalUsers = () => {
    setShowModalUsers(true);
  };

  return (
    <div className="menu-internal">
      <nav className="navbar">
        <div className="dropdown">
          <button className="btn dropdown-toggle btn-options"
            type="button" id="dropdownMenuButton"
            data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            Opções
          </button>
          <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            {admin ?
              <>
                <li><a className="dropdown-item" href="#" onClick={handleOpenModalUsers}>Gerenciar usuários</a></li>
                <li><hr className="dropdown-divider" /></li>
              </> : <></>
            }
            <li><a className="dropdown-item btn-logout" href="#" onClick={handleShowModalConfirm}>Sair</a></li>
          </ul>
        </div>
      </nav>
      <ModalConfirm show={showModalConfirm} onHide={handleHideModalConfirm} logout={logout} />

      <Usuarios show={showModalUsers} onHide={handleHideModalUsers} />
    </div>
  );
}
