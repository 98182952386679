import React from "react";

type NewsProps = {
  title?: string;
  description?: any;
  day?: string;
  image?: string;
  reference?: string;
}

export default function Noticia(props:NewsProps) {
  return (
    <div>
        <div className="row align-items-center justify-content-center">
          <div className="card-new">
            <div className="img-new col-2 float-right">
              <img src={props.image} alt="" className="d-none d-sm-block rounded-circle align-items-center" />
            </div>
            <div className="card-conteudo col-md-10 col-sm-12">
              <h4 className="title-new">
               <a href={props.reference}>{props.title}</a>
              </h4>
              <small className="data-new">{props.day}</small>
              <p className="description-new mt-3">
                {props.description}
              </p>
              <hr className="noticias-linha" />
            </div>
          </div>
        </div>
    </div>
  )
}
