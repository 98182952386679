import { get } from "../config/api";

export interface Signature {
  name: string;
  date: Date;
}

export interface ResponseValidarDocumento {
  date: Date;
  control_code: string;
  filename: string;
  signatures: Signature[];
}

export default class DocumentoAssinadoService {
  public static async validar(codigo: string) {
    return get<ResponseValidarDocumento>(`/documento_assinado/${codigo}/validar`)
      .then((response) => response);
  }
}
