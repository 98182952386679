import { auth } from "../config/api";
import { ServerData } from "../helpers/Service";

export const TOKEN_KEY = "access-token";
export const CLIENT = "client";
export const UID = "uid";
export const USER = "user";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getClient = () => localStorage.getItem(CLIENT);
export const getUid = () => localStorage.getItem(UID);
export const getUser = () => localStorage.getItem(USER);

export const logoutAtendente = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(CLIENT);
  localStorage.removeItem(UID);
  localStorage.removeItem(USER);
};

export default class AuthService {
  public static async autenticar(email: string, password: string) {
    return auth<ServerData<Boolean>>("/auth/sign_in", { email, password }
    ).then((response) => response);
  }
}
