import React, { useState } from 'react';
import './style.scss';
import { AttendantIntructions } from '../AttendantIntructions';
import { ContributorData } from '../ContributorData';
import { ContributorIntructions } from '../ContributorInstructions';

type InstructionProps = {
  document: string;
  type: 'attendant' | 'contributor' | undefined;
  balcalId: number;
};

export function Instructions(props: InstructionProps) {
  const [selected, setSelected] = useState('instructions');

  const tabs = [
    {
      id: 'instructions',
      text: 'Instruções de atendimento',
      content: props.type === 'contributor' ? <ContributorIntructions/> : <AttendantIntructions />,
    },
    {
      id: 'data',
      text: 'Dados do contribuinte',
      content: <ContributorData document={props.document} type={props.type} balcalId={props.balcalId}/>,
    },
  ];

  return (
    <div className='instructionsContainer'>
      <div className='tabs'>
        {
          tabs.map((tab) => (
            <a
              href='#/'
              className={`tab ${selected === tab.id ? 'selected' : ''}`}
              onClick={() => setSelected(tab.id)}
            >
              <span>{tab.text}</span>
            </a>
          ))
        }
      </div>
      <div className='instructionsContent'>
        {
          tabs.find((t) => t.id === selected)?.content
        }
      </div>
    </div>
  );
}
