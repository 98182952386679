import React from "react";
import { timeLineInfos } from "./timeLineInfos";
import { TimeLineContent } from "./TimeLineContent";

import './style.css';

export function TimeLine() {
  return (
    <div className="timeline">
      <div className="initial">
        <div className="figure">
          <div className="circle-initial"></div>
          <div className="points"></div>
        </div>
        <div className="text">
          <strong>Lei de criação</strong>
          <div className="infos">
            <p>Lei nº 5154, de 25 de maio de 1979</p>
            <p>Ementa: Cria a Procuradoria-Geral do Município e dá outras providências.</p>
            <p>Prefeito de Fortaleza – Lúcio Alcântara</p>
          </div>
        </div>
      </div>
      {
        timeLineInfos.map(timeline => {
          return (
            <TimeLineContent
              date={timeline.date}
              year={timeline.year}
              management={timeline.management}
              person={timeline.person}
              until={timeline.until}
              last={timeline?.last}
            />
          )
        })
      }
    </div>
  );
}
