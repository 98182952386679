import React from "react";
import styels from "./styles.module.scss"
import Button from "../Form/Button";
import { cpfOrCnpjMask } from "../../helpers/Masks";

interface ContributorProps {
  contributorName: string;
  contributorDocument: string;
  totalBalance: string;
  receiptTypes: string[];
}

export default function OverMaxDebts(props: ContributorProps) {

  const redirectToVirtualDesk = () => {
    window.open("https://portal.pgm.fortaleza.ce.gov.br/?balcao=true")
  }

  const receipTypeRender = (receiptType: string[]) => {
    props.receiptTypes.map((receipt) => (receipt))
    return (
      receiptType.map((rt: string) => (
        <span>{rt}{receiptType.length > 0 && rt !== receiptType[receiptType.length -1] && ', '}</span>
      ))
    )
  };

  return(
    <div className={styels.container}>
      <div className={styels.mainInfo}>
        <span><strong>Contribuinte: </strong> {props.contributorName} - {cpfOrCnpjMask(props.contributorDocument).mask()}</span>
        <span><strong>Total de inscrições: </strong>mais de 150 inscrições *</span>
        <span><strong>Saldo devedor total: </strong>{props.totalBalance}</span>
        <span><strong>Tributos em Dívida Ativa:</strong> {receipTypeRender(props.receiptTypes)}</span>
      </div>
      <div className={styels.virtualDeskInfo}>
        <span>
          *Sua busca retornou mais de 150 inscrições. Para ter acesso ao extrato completo <strong>entre em contato com nossos atendentes</strong> no Balcão Virtual.
        </span>
      </div>
      <div className={styels.buttonContainer}>
        <Button
          label="Iniciar atendimento"
          onClick={() => redirectToVirtualDesk()}
        />
      </div>
    </div>
  );
}
