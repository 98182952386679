import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Show from "../Show";
import "./styles.css";

interface Props {
  title: string;
  subtitle?: string;
  showed: boolean;
}

export default function ItemSituacao({ title, subtitle, showed, children }: React.PropsWithChildren<Props>) {
  const [show, setShow] = useState(showed);

  const handleToggleShow = () => setShow(!show);

  const icon = show ? faChevronDown : faChevronUp;
  const iconTitle = show ? "Clique para esconder" : "Clique para visualizar";

  return (
    <div className="item-situacao">
      <button onClick={handleToggleShow}>
        <div className="infos">
          <strong>{title}</strong><br/>
          {subtitle}
        </div>
        <div className="icon">
          <FontAwesomeIcon icon={icon} title={iconTitle} />
        </div>
      </button>
      <Show if={show}>
        <div className="item-situacao-content">
          {children}
        </div>
      </Show>
    </div>
  );
}
