import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import Select from 'react-select';

interface OptionProps {
  label: string;
  value: string | number;
}

export type Option = JSX.IntrinsicElements["option"] & OptionProps;

interface Props extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  label?: string;
  name: string;
  options: Option[];
  placeholder: string;
  loading?: boolean;
  disabled?: boolean;
  searchable?: boolean;
  optionValue?: any;
  defaultValue?: any;
  onchange(value: any): void;
}

export default function SelectReact({ label, name, options, placeholder, loading, disabled, searchable, optionValue, defaultValue, onchange }: React.PropsWithChildren<Props>) {

  const selectRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  const klass = [""];

  if (error) {
    klass.push("is-invalid");
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => ref.state.value ? ref.state.value.value : "",
      setValue: (ref: any, value: any) => {
        ref.select.setValue(value || null);
      },
      clearValue: (ref: any) => {
        ref.select.clearValue();
      }
    });
  }, [fieldName, registerField]);

  return (
    <div className="form-group">
      {label && <label className="form-label">{label}</label>}
      <Select
        name={name}
        className={klass.join(" ")}
        placeholder={placeholder}
        isLoading={loading}
        ref={selectRef}
        isOptionDisabled={(option) => Boolean(option.disabled)}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: 'orangered',
            primary: 'var(--green2)',
            color: "red",
          },
        })}
        styles={{
          dropdownIndicator: defaultStyles => ({
            ...defaultStyles,
            color: '#1EB1E7',
          }),
          option: (styles, { data, isDisabled, getValue }) => {
            return {
              ...styles,
              color: isDisabled
                ? '#ccc'
                : data.color,
              cursor: isDisabled ? 'not-allowed' : 'pointer',
            };
          },
          singleValue: (styles, { data }) => {
            return {
              ...styles,
              color: data.color
                ? data.color
                : '#272A2E'
            };
          },
        }}
        options={options}
        isDisabled={disabled}
        defaultValue={defaultValue}
        value={options.find(option => option.value == optionValue)}
        isSearchable={searchable}
        onChange={(e) => { onchange(e?.value) }}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}
