import React from "react";
import Format from "../../helpers/Format";
import "./styles.css";

interface Props {
  name: string;
  date: Date;
}

export default function ItemValidarDocumento({ name, date }: Props) {
  return (
    <div className="item-validar-documento">
      <div className="content">
        <div className="nome">{name}</div>
        <div className="data">{Format.date(date.toString(), "datetime")}</div>
      </div>
    </div>
  );
}
