import React, { useState } from "react";
import ModalBalcaoVirtual from "../ModalBalcaoVirtual";

interface Props {
  title: string;
  id: string;
  image: any;
  text: string;
  length?: number;
  linkText?: string;
  href?: string;
  showModalBalcao?: boolean;
  readMore?: boolean;
}

export default function ServicoItem({ title, id, image, text,
  length, href, linkText, showModalBalcao, readMore }: React.PropsWithChildren<Props>) {

  const [showModalBalcaoVirtual, setShowModalBalcaoVirtual] = useState(false);

  const handleShowBalcaoVirtual = () => {
    setShowModalBalcaoVirtual(true);
  }

  const handleHideBalcaoVirtual = () => {
    setShowModalBalcaoVirtual(false);
  }

  const changeClass = (classReadLess: string, classReadMore: string) => {
    const divBlockReadMore = document.querySelector(`#blockReadMore${id}`);
    const divBlockReadLess = document.querySelector(`#blockReadLess${id}`);
    divBlockReadLess?.setAttribute("class", classReadLess);
    divBlockReadMore?.setAttribute("class", classReadMore);
  }

  const handleShowText = () => {
    changeClass("d-block", "d-none");
  }

  const handleHideText = () => {
    changeClass("d-none", "d-block");
  }

  return (
    <>
      <div className="row mt-5 ">
        <div className="col-12 col-sm-4 col-lg-4">
          <img src={image} className="img-fluid" />
        </div>
        <div className="services-description col-12 col-sm-8 col-lg-8 mt-3 mt-md-0">
          <h3><b>{title}</b></h3>
          <p>
            {readMore ?
              <>
                <span id={`blockReadMore${id}`}>
                  {text.slice(0, length)}
                  …
                  <button id={`readMore${id}`} onClick={handleShowText} className="link-home">Ler mais</button>
                </span>
                <span id={`blockReadLess${id}`} className="d-none">
                  {text}
                  <button id={`readLess${id}`} onClick={handleHideText} className="link-home">Ler menos</button>
                </span>
              </>
              : text}
          </p>
          <p className="mt-4">
            {showModalBalcao ? <a href={href} className="link-home" onClick={handleShowBalcaoVirtual}>{linkText}</a>
              : <a href={href} className="link-home">{linkText} </a>}
          </p>
        </div>
      </div>
      <hr className="line-bottom-container my-3" />
      <ModalBalcaoVirtual show={showModalBalcaoVirtual} onHide={handleHideBalcaoVirtual} />
    </>
  );
}
