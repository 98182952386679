import axios, { AxiosError, AxiosResponse } from "axios";
import { logoutAtendente, TOKEN_KEY } from "../services/AuthService"

export const URLS = {
  development: `http://${window.location.host.replace("5000", "5100")}`,
  staging: "https://hom-api.portal.pgm.fortaleza.ce.gov.br",
  production: "https://api.portal.pgm.fortaleza.ce.gov.br",
};

type Environments = "production" | "staging" | "development";

export function env(): Environments {
  const host = window.location.host;

  if (host.indexOf("hom.portal.pgm.fortaleza.ce.gov.br") >= 0) {
    return "staging";
  }

  if (host.indexOf("portal.pgm.fortaleza.ce.gov.br") >= 0) {
    return "production";
  }

  return "development";
}

const api = axios.create({
  baseURL: URLS[env()],
  responseType: "json",
  timeout: 3600000,
  timeoutErrorMessage: "A solicitação que fez demorou muito. Por favor tente novamente.",
  headers: {
    "Content-Type": "application/json",
    "X-Request-Client": "Portal",
  },
});

export default api;

interface ServerError {
  error: string;
}

export function isServerError(error: ServerError | unknown): error is ServerError {
  if (typeof error === "object" && error !== null && error.hasOwnProperty("error")) {
    // tslint:disable-next-line: strict-type-predicates
    return (error as ServerError).error !== undefined;
  }

  return false;
}

api.interceptors.response.use(function (response) {

  if(response.headers["access-token"]) {
    localStorage.setItem(TOKEN_KEY, response.headers[TOKEN_KEY])
  }
  return response;
}, function (error) {
  if (401 === error.response.status) {
    logoutAtendente();
    window.location.href = '/login'
  }
  return Promise.reject(error);
});

export async function get<I>(url: string, params = {}) {
  return api
    .get<I>(url, { params })
    .then((response: AxiosResponse<I>) => response.data)
    .catch((error: AxiosError<ServerError>) => {
      if (error.response) { throw error.response.data; }
      throw error;
    });
}

export async function post<I>(url: string, params = {}, config = {}) {
  return api
    .post<I>(url, params, config)
    .then((response: AxiosResponse<I>) => response.data)
    .catch((error: AxiosError<ServerError>) => {
      if (error.response) { throw error.response.data; }
      throw error;
    });
}

export async function postCustomize<I>(url: string, params = {}, config = {}) {
  return api
    .post<I>(url, params, config)
    .then((response: AxiosResponse<I>) => response)
    .catch((error: AxiosError<ServerError>) => {
      if (error.response) { throw error.response.data; }
      throw error;
    });
}

export async function auth<I>(url: string, params = {}, config = {}) {
  return api
    .post<I>(url, params, config)
    .then((response: AxiosResponse<I>) => response)
    .catch((error: AxiosError<ServerError>) => {
      if (error.response) { throw error.response.data; }
      throw error;
    });
}

export async function getWithConfig<I>(url: string, config = {}) {
  return api
    .get<I>(url, config)
    .then((response: AxiosResponse<I>) => response)
    .catch((error: AxiosError<ServerError>) => {
      if (error.response) { throw error.response.data; }
      throw error;
    });
}

export async function put<I>(url: string, params = {}, config = {}) {
  return api
    .put<I>(url, params, config)
    .then((response: AxiosResponse<I>) => response)
    .catch((error: AxiosError<ServerError>) => {
      if (error.response) { throw error.response.data; }
      throw error;
    });
}

export async function destroy<I>(url: string, params = {}) {
  return api
    .delete<I>(url, params)
    .then((response: AxiosResponse<I>) => response)
    .catch((error: AxiosError<ServerError>) => {
      if (error.response) { throw error.response.data; }
      throw error;
    });
}
