import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import Layout from "../../components/Layout";
import ItemTipoReceita from "../../components/ItemTipoReceita";
import Select, { Option } from "../../components/Form/SelectReact";
import Format from "../../helpers/Format";
import { isCpfOrCnpj } from "../../helpers/Validates";
import { cpfOrCnpjMask, onlyAlphanumeric } from "../../helpers/Masks";
import ConsultarService, { ResponseDebitos } from "../../services/ConsultarService";
import { isServerError } from "../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import ContributorForm from "../../components/ContributorUpdateForm/ContributorForm";
import Show from "../../components/Show";
import OverMaxDebts from "../../components/OverMaxDebts";


interface FormData {
  documento: string;
  tipo: string;
  valor: string;
}

type TipoOpcao = "document" | "administrative" | "cda" | "judicial";

interface Opcao extends Option {
  value: TipoOpcao;
}

type Labels = {
  [key in TipoOpcao]: string;
};

export default function ConsultarDebitos() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ResponseDebitos>();
  const [label, setLabel] = useState("");
  const [error, setError] = useState("");
  const [totalInscricoes, setTotalInscricoes] = useState(0);
  const [totalSaldoDevedor, setTotalSaldoDevedor] = useState(0);
  const [documento, setDocumento] = useState("");
  const [show, setShow] = useState(false);
  const [tabname , setTabname] = useState("");
  const [value, setValue] = useState(0);
  const [totalTributesbalance, setTotalTributesBalance] = useState<Array<any>>([]);
  const [totalTributesbalanceRefis, setTotalTributesBalanceRefis] = useState<Array<any>>([]);
  const [enabledRefis, setEnebledRefis] = useState(false);
  const [overMaxDebts, setOverMaxDebts] = useState(false);
  const [overMaxDebtsData, setOverMaxDebtsData] = useState<any>();

  const contributorName = data?.contributor_name_without_mask;

  const formRef = useRef<FormHandles>(null);
  const options: Opcao[] = [
    { label: "CPF/CNPJ", value: "document", selected: true },
    { label: "CPF/CNPJ e Inscrição Administrativa", value: "administrative" },
    { label: "CPF/CNPJ e Número da CDA", value: "cda" },
    { label: "CPF/CNPJ e Processo Judicial", value: "judicial" },
  ];
  const labels: Labels = {
    document: "",
    administrative: "Inscrição Administrativa",
    cda: "Número da CDA",
    judicial: "Processo Judicial",
  };

  const handleTipo = (e: string) => {
    if (e) {
      const labelSelected = options.find((option) => option.value === e);
      if (labelSelected && labelSelected.value !== "document") {
        setLabel(labels[labelSelected.value]);
      } else {
        setLabel("");
      }
    }
  };

  const handleSubmitSemFiltro = () => {
    if (formRef.current) {
      formRef.current.setFieldValue("tipo", "document");
      formRef.current.clearField("valor");
      formRef.current.submitForm();
      setLabel("");
    }
  };

  const handleReloadSearch = () => {
    formRef.current?.submitForm();
  };

  const submitAttendance = async () => {
    var contributorDocment = (document.getElementById("contributorDocment") as HTMLInputElement).value.replace(/\D/g,'');

    const contributor_document = contributorDocment;
    const contributor_attendance_histories = {'attendance_place':'Portal do Contribuinte - Consultar Dívidas',
                                              'attendant':'Assitente Virtual',
                                              'attendance_subject':'portal_of_taxpayer_consult_debt'};

    const attendanceData = {contributor_document, contributor_attendance_histories}

    await ConsultarService
      .attendanceRegistration(attendanceData)

  };

  const handleSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      setData(undefined);
      setError("");
      setLoading(true);

      formRef.current?.setErrors({});

      const schema = Yup.object<FormData>().shape({
        documento: Yup.string()
          .test("cpfOrCnpj", "CPF/CNPJ é inválido", (value) => {
            if (typeof value === "string") {
              return isCpfOrCnpj(value);
            }

            return false;
          })
          .required("CPF/CNPJ é obrigatório"),
        tipo: Yup.string().required("Tipo é obrigatório"),
        valor: Yup.string().when("tipo", {
          is: (value) => value !== "document",
          then: Yup.string().required("Preenchimento obrigatório"),
          otherwise: Yup.string().notRequired(),
        }),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      await ConsultarService
        .debitos(formData.documento, formData.tipo, formData.valor)
        .then((response) => {
          if (response.over_max_debts) {
            setOverMaxDebts(true);
            setOverMaxDebtsData(response);
          } else {
            setOverMaxDebts(false);
            setOverMaxDebtsData('');
            setData(response);
            if (response.is_update){
              submitAttendance();
            }else{
              var targetinfo = (document.getElementById("targetInfo")as HTMLElement);
              targetinfo?.setAttribute("hidden", "true");
            };

            if(response){
              const currentTab = Object.keys(response.data)[0]
              document.getElementById(currentTab)?.classList.add("tabTipoReceitaSelectedConsultDebts")
              document.getElementById(currentTab+"Mobile")?.classList.add("tabTipoReceitaSelectedConsultDebts")
              setTabname(currentTab)
            }

            let totalIns = 0;
            let totalSaldo = 0;
            let totalPertribute: {type: string, balance: number};
            let totalPertributeRefis: {type: string, balance: number};
            let groupTributes: Array<any> = [];
            let groupTributesRefis: Array<any> = [];
            let tributeBalance;
            let tributeBalanceRefis;
            let isrefisEnabled: boolean = false;

            Object.keys(response.data).forEach((tipo) => {
              const situacoes = Object.keys(response.data[tipo]);
              totalIns += response.data[tipo][situacoes[0]].total_inscriptions;
              totalSaldo += response.data[tipo][situacoes[0]].total_balance;
              tributeBalance = response.data[tipo][situacoes[0]].total_balance;
              tributeBalanceRefis = response.data[tipo][situacoes[0]].balance_to_legal_action;
              totalPertribute = {type: tipo, balance: tributeBalance};
              totalPertributeRefis = {type: tipo, balance: tributeBalanceRefis};
              groupTributes.push(totalPertribute)
              groupTributesRefis.push(totalPertributeRefis)
              isrefisEnabled = response.data[tipo][situacoes[0]].refis_enabled;
              tributeBalance = 0;
              tributeBalanceRefis= 0;
            });

            setEnebledRefis(isrefisEnabled);
            setTotalTributesBalance(groupTributes);
            setTotalTributesBalanceRefis(groupTributesRefis);
            setTotalInscricoes(totalIns);
            setTotalSaldoDevedor(totalSaldo);
            setDocumento(formData.documento);
          }
        }).catch((err) => {
          if (isServerError(err)) {
            setError(err.error);
            if (err.error == "Débitos não encontrados para os dados informados."){
              submitAttendance();
            }
          } else {
            setError("Não foi possível estabelecer uma conexão com o servidor. Tente novamente mais tarde.");
            console.error(err);
          }
        }).finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<{}>((o, c) => ({ ...o, [c.path]: c.message }), {});
        formRef.current?.setErrors(errors);
      }
    }
  };

  const ActiveTab = (e: { currentTarget: { id: any; }; }) => {
    var tabName = e.currentTarget.id
    setTabname(tabName);
    setShow(!show)
    const selectedtab = document.querySelectorAll(".tabTipoReceitaConsultDebts")
    Array.from(selectedtab).map((item) => {
      item.classList.remove("tabTipoReceitaSelectedConsultDebts")
    })
    const elementId = document.getElementById(tabName);
    elementId?.classList.add("tabTipoReceitaSelectedConsultDebts");
  }

  const ActiveTabMobile = (e: { currentTarget: { id: any; }; }) => {
    var tabName = e.currentTarget.id
    setTabname(tabName);
    setShow(!show)
    const selectedtab = document.querySelectorAll(".tabTipoReceitaMobileConsultDebts")
    Array.from(selectedtab).map((item) => {
      item.classList.remove("tabTipoReceitaSelectedConsultDebts")
    })
    const elementId = document.getElementById(tabName);
    elementId?.classList.add("tabTipoReceitaSelectedConsultDebts");
  }

  const MoveCarousel = (currentarrow : any) => {
    const arrrowvalue = currentarrow.classList.value
    const lenghtofElement = (document.querySelectorAll(".MainContainner")).length
    var actualValue = value
    const maxValue = 201 * (lenghtofElement - 1)
    if (arrrowvalue === "arrowLeftConsultDebts" && value != 0) {
      setValue(actualValue + 201)
    }else if(arrrowvalue === "arrowRightConsultDebts" && value != (maxValue * -1)) {
      setValue(actualValue - 201)
    }
  }

  return (
    <Layout title="Consultar Dívidas" balcaoVirtual balcaoTitle={"Tem dúvidas?"} menuName="servicos">
      <a href="/servicos" className="link-direction">
        <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
        Ver todos os serviços
      </a>
      <span className="h3 container-title mt-4">Consultar Dívidas</span>
      <p>Você pode informar apenas um CPF ou CNPJ. Para uma busca mais detalhada, escolha outra opção de busca e preencha as demais informações:</p>
      <Form ref={formRef} onSubmit={handleSubmit} className="search">
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-4 mt-3">
            <Select
              label="Buscar por"
              name="tipo"
              searchable={false}
              defaultValue={options[0]}
              onchange={(e) => handleTipo(e)}
              placeholder="Selecione seu Status"
              options={options} />
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mt-3">
            <Input id={'contributorDocment'} label="CPF/CNPJ" name="documento" mask={cpfOrCnpjMask} autoFocus placeholder={"Digite seu CPF ou CNPJ"} />
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mt-3" hidden={!label}>
            <Input label={label} name="valor" mask={onlyAlphanumeric} placeholder={`Digite ${label}`} />
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mt-3 col-search">
            <Button label="Pesquisar" loading={loading} cansearch={data?.is_update} block />
          </div>
        </div>
      </Form>
      <p className="info-refis" id="targetInfo" >Para débitos tributários ainda não inscritos em Dívida Ativa, consulte o site da <a className="link-home" href="https://www.sefin.fortaleza.ce.gov.br/" target="_blank" rel="noopener noreferrer">SEFIN</a>.</p>
      {error && <p className="alert alert-warning">{error}</p>}
      {overMaxDebts && overMaxDebtsData &&
        <OverMaxDebts
          contributorName={overMaxDebtsData.contributor_name}
          contributorDocument={overMaxDebtsData.contributor_document}
          totalBalance={overMaxDebtsData.balance}
          receiptTypes={overMaxDebtsData.receipt_types_in_debts}
        />
      }
      {data && data.more_debts && (
        <div className="outros-debitos">
          <div>Existem outros débitos para esse CPF/CNPJ</div>
          <button className="btn btn-primary" onClick={handleSubmitSemFiltro}>Clique para visualizá-los</button>
        </div>
      )}
      {
        <Show if={data?.is_update as any}>
          <div className="row mb-1">
            <div className="col-12 mt-auto">
              <div className="total"><strong>Contribuinte:</strong> {contributorName} - {cpfOrCnpjMask(documento).mask()} </div>
              <div className="total"><strong>Total de inscrições em aberto:</strong> {totalInscricoes} </div>
              <div className="total"><strong>Saldo devedor:</strong> {Format.currency(totalSaldoDevedor)}</div>
            </div>
          </div>
        </Show>
      }
      {data?.is_update ?
        data &&
        <div className="tabsContentItensConsultDebts">
          <span className="arrowLeftConsultDebts" onClick={(e) => {MoveCarousel(e.currentTarget)}}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
          <div className="carouselVisibilityConsultDebts">
            <div className ="containerTabsConsultDebts" style={{transform: `translate(${(value)}px)`, transition: "transform 1s"}}>
              {data && Object.keys(data.data).map((tipoReceita) => (
                <div className="MainContainner">
                  <div className="tipoReceita">
                    <div className="tabTipoReceitaConsultDebts" id={tipoReceita} onClick={ActiveTab}>
                      <div className="recipTittle">{tipoReceita}</div>
                      {data && Object.values(data).map((item) => {
                        const itemObject = item[tipoReceita]
                        if (itemObject != null || itemObject !== undefined) {
                          const totalInscriptions = itemObject["Em aberto"].total_inscriptions;
                          const totalLegalAction = itemObject["Em aberto"].total_inscriptions_legal_action;
                          const totalProtested = itemObject["Em aberto"].total_inscriptions_protested;
                          return(
                            <span className="inscriptionsInfo">
                              <span className="recipInformation"><strong className="labelName">Inscrições em aberto: </strong> {totalInscriptions}</span>
                              <span className="recipInformation"><strong className="labelName">Inscrições protestadas: </strong> {totalProtested}</span>
                              <span className="recipInformation"><strong className="labelName">Inscrições ajuizadas: </strong> {totalLegalAction}</span>
                            </span>
                          )
                        }
                      })}
                      <Show if={tabname === tipoReceita}>
                        <div className="selectedTabConsultDebts"></div>
                      </Show>
                    </div>
                  </div>
                  <div>
                    <div className="tipoReceitaMobile">
                      <div className="tabTipoReceitaMobileConsultDebts" id={tipoReceita+"Mobile"} onClick={ActiveTabMobile}>
                        <div className="recipTittle">{tipoReceita}</div>
                        {data && Object.values(data).map((item) => {
                          const itemObject = item[tipoReceita]
                          if (itemObject != null || itemObject !== undefined) {
                            const totalInscriptions = itemObject["Em aberto"].total_inscriptions;
                            const totalLegalAction = itemObject["Em aberto"].total_inscriptions_legal_action;
                            const totalProtested = itemObject["Em aberto"].total_inscriptions_protested;
                            return(
                              <span className="inscriptionsInfo">
                                <span className="recipInformation"><strong className="labelName">Inscrições em aberto: </strong> {totalInscriptions}</span>
                                <span className="recipInformation"><strong className="labelName">Inscrições protestadas: </strong> {totalProtested}</span>
                                <span className="recipInformation"><strong className="labelName">Inscrições ajuizadas: </strong> {totalLegalAction}</span>
                              </span>
                            )
                          }
                        })}
                        <Show if={tabname+"Mobile" === tipoReceita+"Mobile" || tabname === tipoReceita+"Mobile"}>
                          <div className="selectedTabConsultDebts"></div>
                        </Show>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <span className="arrowRightConsultDebts" onClick={(e) => {MoveCarousel(e.currentTarget)}}>
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </div>:
        <ContributorForm
          contributor_name={data?.contributor_name}
          is_update={data?.is_update}
          attendance_place={'Portal do Contribuinte - Consultar Dívidas'}
        />
      }
      {
        data?.is_update ?
        data && Object.keys(data.data).map((tipoReceita) => (
          <div className="ContentTypeConsultDebts">
            <Show if={tabname === tipoReceita || tabname === tipoReceita+"Mobile"}>
              <ItemTipoReceita
                key={tipoReceita}
                documento={documento}
                title={tipoReceita}
                status={data.data[tipoReceita]}
                reloadSearch={handleReloadSearch}
                totalBalances={totalTributesbalance}
                totalBalancesRefis={totalTributesbalanceRefis}
                enabledRefis={enabledRefis}
                showAddress={false}
              />
            </Show>
          </div>
        )) :
        <></>
      }
    </Layout>
  );
}
