import { faChevronDown, faChevronUp, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./style.scss";
import ConsultarService from "../../../services/ConsultarService";
import BalcaoVirtualService from "../../../services/BalcaoVirtualService";
import { cpfOrCnpjMask } from "../../../helpers/Masks";

interface ContributorData {
  document: string;
  name: string
}
interface Props {
  userType: string,
  balcaoId: string;
  updateDocument: (document: string) => void;
  getContributorInfos: (contributorInfos: ContributorData[]) => void;
}

export function MultipleDocuments(props: Props) {
  const [contributorDocument, setContributorDocument] = useState('');
  const [contributorName, setContributorName] = useState('');
  const [itemSelected, setItemSelect] = useState('');
  const [contributorData, setContributorData] = useState<ContributorData[]>([]);
  const [contributorDataAttendant, setContributorDataAttendant] = useState<ContributorData[]>([]);
  const [contributorNames, setContributorNames] = useState<string[]>(['']);
  const [docFromDataBase, setDocFromDataBase] = useState<string[]>([''])
  const [iconType, setIconType] = useState(faChevronDown);

  useEffect(() => {
    loadBalcao();
  }, []);

  const documentList = JSON.parse(localStorage.getItem("documents") as string)

  const loadBalcao = () => {
    BalcaoVirtualService.balcaoPorID(props.balcaoId)
      .then((res) => {
        setDocFromDataBase(JSON.parse(res.contributor_document as string))
      });
  };

  useEffect(() => {
    if (props.userType == 'contributor'){
      setContributorDocument(cpfOrCnpjMask(documentList[0]).mask());
      contributorData.map((contributorName) => {
        if(contributorName.document == documentList[0]) {
          setContributorName(contributorName.name)
        }
      })
    } else {
      setContributorDocument(cpfOrCnpjMask(docFromDataBase[0]).mask());
      props.getContributorInfos(contributorDataAttendant)
      contributorDataAttendant.map((contributorName) => {
        if(contributorName.document == docFromDataBase[0]) {
          setContributorName(contributorName.name)
        }
      })
    }
  }, [docFromDataBase, contributorData, contributorDataAttendant]);

  useEffect(() => {
    getContributorName();
  }, [docFromDataBase]);

  const getContributorName = async () => {
    if (props.userType == 'contributor') {
      let names = ['']
      let completeInfos: { document: string; name: string; }[] = []
      documentList.map((doc: string) => {
        ConsultarService
          .contributorStatus({contributor_document: doc.replace(/\D/g,'')})
          .then((response) => {
            names.push(response.contributor_name)
            completeInfos.push({document: doc, name:response.contributor_name})
            setContributorData(completeInfos);
          })
        })
        setContributorNames(names);
    } else {
      let names = ['']
      let completeInfos: ContributorData[] = []
      docFromDataBase.map((doc: string) => {
        ConsultarService
          .contributor({contributor_document: doc})
          .then((response) => {
            names.push(response.data.name)
            completeInfos.push({document: doc, name:response.data.name})
            setContributorData(completeInfos);
            setContributorDataAttendant(completeInfos);
        })
      })
    }
  };

  const changeSelectedItem = (item: HTMLElement) => {
    setContributorDocument(item.id)
    setContributorName(item.nextElementSibling?.innerHTML as string)
    const elements = [].slice.call(document.getElementsByClassName('listItems'))
    elements.map((item: HTMLElement) => {
      const itemElement = item.firstElementChild?.firstChild as HTMLElement
      itemElement?.classList.remove('selectedItem')
    })
    item.classList.add("selectedItem")
  };


  const showHideDropDawn = () => {
    const dropDownItem = document.getElementById('dropDown') as HTMLElement
    if (dropDownItem.classList.contains('showDropDown')) {
      dropDownItem.classList.remove('showDropDown')
    } else {
      dropDownItem.classList.add('showDropDown')
    }
  };

  const showHideDropList = (element: HTMLDivElement) => {
    if (element.id) {
      const item = element.firstElementChild?.firstChild as HTMLElement
      setItemSelect(item.id);
      changeSelectedItem(item)
      props.updateDocument(item.id.replace(/\D/g,''))
      showHideDropDawn();
    } else {
      showHideDropDawn();
    }
    iconType == faChevronDown ? setIconType(faChevronUp) : setIconType(faChevronDown)
  }

  return (
    <div>
      <div className='infoBar' onClick={(e) => (showHideDropList(e.currentTarget))}>
        <div className="contributorInfoDocuments">
          <span className="contributorIconBar"><FontAwesomeIcon icon={faUser} /></span>
          <span className="contributorDocumentBar">{contributorDocument}</span>
          <span className="contributorNameMaskedBar">{contributorName}</span>
        </div>
        <div className="collapseIconBar">
          <FontAwesomeIcon icon={iconType} />
        </div>
      </div>
        <div id='dropDown' className="documentDropDownList">
          {contributorData.map((document, i) => (
            <>
              <div id='infoElement' className="listItems" onClick={(e) => (showHideDropList(e.currentTarget))}>
                <div>
                  <span id={cpfOrCnpjMask(document.document).mask()} className="listItemsDocument">{cpfOrCnpjMask(document.document).mask()}</span>
                  <span className="listItemsContributorName">{document.name}</span>
                </div>
              </div>
              {(props.userType == 'contributor' ? contributorData: contributorDataAttendant).length -1 != i
                && <span className="endLine"></span>}
            </>
          ))}
        </div>
    </div>
  )
};
