import React from 'react';
import logoPgm from "../../assets/images/landingPage/logo-prefeitura-PGM.svg";
import iconTurnOff from "../../assets/images/landingPage/icon-fone.svg";
import './style.scss';
import Show from '../Show';

type HeaderProps = {
  balcaoId: string;
  onClose: () => void;
  datetime: string;
  rate?: boolean;
}

export function Header(props: HeaderProps) {
  const turnOffCall = () => {
    props.onClose();
  }

  return (
    <div className='headerContainer row'>
      <div className='headerLeft col-12 col-sm-4'>
        <img src={logoPgm} alt="Logo da PGM" width={140} className='logo' />
        <div className='app'>
          <p className='appName'>Balcão Virtual</p>
          <p className='appVersion'>4.0</p>
        </div>
      </div>
      {
        props.balcaoId &&
        <div className='containerHeaderTimer col-12 col-sm-4'>
          {
            props.datetime.length > 0 && (
              <div className={`headerTimer ${props.rate && 'finishedCall'}`}>
                <span className='headerTimerTitle'>{props.rate ? 'Atendimento encerrado' : 'Tempo de atendimento'}</span>
                <span className='headerTimerNumber'>{props.datetime}</span>
              </div>
            )
          }
          <Show if={!props.rate}>
            <div className="thirdColumnClose" data-title={'Encerrar atendimento'}>
              <div className='iconCall' onClick={turnOffCall}>
                <img src={iconTurnOff} />
              </div>
            </div>
          </Show>
        </div>
      }
    </div>
  );
}
