import React from "react";
import { Link } from "react-router-dom";
import Institucional1 from "../../assets/images/foto-institucional-pgm-1.png"
import IconMissao from "../../assets/images/icon-missao-pgm.svg";
import IconValores from "../../assets/images/icon-valores-pgm.svg";
import IconNegocio from "../../assets/images/icon-negocio.svg";
import IconVisao from "../../assets/images/icon-visao-pgm.svg";
import Layout from "../../components/Layout";
import "./style.css";
import { TimeLine } from "./Timeline";
import { ValuesContent } from "./ValuesContent";

export default function APGM() {
  return (
    <Layout title="A PGM" className="apgm" balcaoVirtual balcaoTitle={"Tem dúvidas?"} container={false} menuName="institucional">
      <div className="container">
        <div className="my-5 px-3 layout-body">
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <span className="h3 container-title">
                A PGM
              </span>
            </div>
          </div>
          <p>
            A Procuradoria Geral do Município (PGM) é o órgão gestor do Sistema Jurídico Municipal,
            responsável pela defesa judicial e extrajudicial do Município de Fortaleza,
            pela consultoria jurídica dos órgãos municipais, bem como pela inscrição e cobrança da dívida ativa municipal.
          </p>
          <div className="row">
            <img src={Institucional1} className="d-block px-3 w-100" />
          </div>
          <div className="row mt-4">
            É responsável, em toda a sua plenitude, pela defesa de seus interesses em juízo e fora dele, bem como pelas funções de consultoria jurídica,
            ressalvadas as competências autárquicas, sob a égide dos princípios da legalidade e da indisponibilidade dos interesses públicos.
          </div>
        </div>
      </div>
      <div className="section-middle">
        <div className="container py-5 text-center">
          <div className="text-center">
            <p>
              <span className="h3">Missão, Visão, Negócio e Valores da PGM</span>
              <hr />
            </p>
            <p className="section-middle-sub-info">
              Conheça os alicerces da instituição
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-sm-12 col-lg-4 col-md-6">
              <img src={IconMissao} className="" alt="missão" />
              <h4>Missão</h4>
              <p className="mt-2 text-info-values">
                Promover a defesa do interesse público municipal na prestação de soluções em serviços jurídicos aos órgãos
                e demais entidades na recuperação dos créditos inscritos na dívida ativa, arrecadação e recuperação em decorrência das transações,
                meio ambiente e urbanismo, consultoria, pesquisa, contencioso e tecnologia da informação com comprometimento, efetividade, cooperação,
                proatividade, foco em prazos, ética e transparência, visando a segurança jurídica e a sustentabilidade organizacional.
              </p>
            </div>
            <div className="col-sm-12 col-lg-4 col-md-6">
              <img src={IconVisao} className="" alt="visão" />
              <h4>Visão</h4>
              <p className="mt-2 text-info-values">
                Consolidar-se, até 2028, como referência nacional na entrega de soluções em serviços jurídicos aos órgãos e demais entidades com foco na
                máxima efetividade da política pública de recuperação e arrecadação dos créditos tributários e não tributários, meio ambiente e urbanismo,
                consultoria, contencioso, pesquisa e tecnologia da informação com excelência na sustentabilidade organizacional.
              </p>
            </div>
            <div className="col-sm-12 col-lg-4 col-md-6">
              <img src={IconNegocio} className="" alt="negócio" />
              <h4>Negócio</h4>
              <p className="mt-2 text-info-values">
                Soluções em serviços jurídicos aos órgãos e demais entidades na defesa do interesse público municipal (recuperação dos créditos inscritos na dívida ativa,
                arrecadação e recuperação em decorrência das transações,
                meio ambiente e urbanismo, consultoria, pesquisa, contencioso e tecnologia da informação).
              </p>
            </div>
          </div>

          <div className="co-sm-12">
            <div>
              <img src={IconValores} alt="negócio" />
              <h2 className="values-header-h2">Valores</h2>
            </div>

            <ValuesContent
              title="Cooperação"
              children={<p>Postura participativa e colaborativa junto aos seus colegas, gestores e equipe de trabalho, bem como com outras unidades e parceiros agregando valor para alcançar os objetivos organizacionais.</p>}
            />

            <ValuesContent
              title="Comprometimento"
              children={<p>Compromisso dos Colaboradores no desempenho das suas atribuições e na busca de soluções para os problemas organizacionais, bem como o compromisso com o interesse público.</p>}
            />

            <ValuesContent
              title="Efetividade"
              children={<p>Busca da melhoria continuada no exercício da sua missão institucional sendo eficiente (fazendo o que deve ser feito) e eficaz (fazendo da melhor maneira o que deve ser feito).</p>}
            />

            <ValuesContent
              title="Ética e Transparência"
              children={<p>Acreditamos no trabalho e nas relações com ética, transparência e responsabilidade individual e coletiva em conformidade com a legalidade.</p>}
            />

            <ValuesContent
              title="Foco em prazos"
              children={<p>Capacidade para atingir os prazos requeridos nos processos organizacionais e no desempenho de suas atribuições, otimizando-se custos e perdas por decorrência de prazos.</p>}
            />

            <ValuesContent
              title="Proatividade"
              children={<p>Senso de iniciativa e prontidão, atitude e determinação frente aos problemas e soluções organizacionais.</p>}
            />

            <ValuesContent
              title="Segurança jurídica"
              children={<p>Assegurar a legalidade nas decisões e ações com previsibilidade e coerência, garantindo aos agentes públicos e aos cidadãos maior segurança em suas relações, mantidos os interesses públicos municipais.</p>}
            />

            <ValuesContent
              title="Sustentabilidade"
              children={<p>Empenho na busca da sustentabilidade do órgão, envolvendo práticas de gestão que promovam a transformação digital, a responsabilidade fiscal e socioambiental, e a longevidade organizacional.</p>}
            />
          </div>

          <div className="row">
            <div className="w-100 my-5 text-center mx-3 mx-md-0">
              <a href="/quem_e_quem" className="apgm-btn carrousel-btn-info carrousel-btn-info-left">
                Quem é Quem
              </a>
              <a href="/noticias" className="apgm-btn carrousel-btn-info carrousel-btn-info-right">
                Ver notícias
              </a>
            </div>
          </div>
        </div>
      </div >
      <div className="section-middle bg-light">
        <div className="container py-5 text-center">
          <div className="text-center">
            <strong className="h2 timeline-text">Procuradoria Geral do Município de Fortaleza</strong><br></br>
            <strong className="h2 timeline-text">NO TEMPO</strong>
            <hr className="image-separator-container image-separator-container-primary"/>
            <p className="section-middle-sub-info">
              Criação e gestão de Procuradores-gerais do Município de Fortaleza
            </p>

            <TimeLine />
          </div>
        </div>
      </div>

      <div className="section-middle history">
        <div className="container py-5 text-center">
          <div className="text-center">
            <strong className="h2 history-text">Histórico de concursos</strong>
            <hr className="image-separator-container image-separator-container-primary"/>
            <p className="section-middle-sub-info">
              Concursos para o cargo de Procurador-geral do Município
            </p>

            <div className="text-block">
              <h3>1º Concurso Público para provimento de cargos de Procurador do Município de Fortaleza</h3>
              <ul>
                <li>Edital - 13 de dezembro de 1990.</li>
                <li>Edital - Relacao dos Candidatos Aprovados de 19 de abril de 1991.</li>
                <li>Ato de Homologacoes e Convocacoes, de 07 de maio de 1991.</li>
              </ul>
            </div>

            <div className="text-block">
              <h3>2º Concurso Público para provimento de cargos de Procurador do Município de Fortaleza</h3>
              <ul>
                <li>Edital – 24 de maio de 1993. </li>
                <li>Relação dos Candidatos Aprovados, de 28 de setembro de 1993.</li>
                <li>Ato de Homologação, de 04 de outubro de 1993. </li>
                <li>Edital de Convocação, de 05 de outubro de 1993.</li>
              </ul>
            </div>

            <div className="text-block">
              <h3>3° Concurso Público para provimento de cargos de Procurador do Município de Fortaleza </h3>
              <ul>
                <li>Edital nº 65, de 12 de julho de 2012. </li>
                <li>Edital nº 5, de 7 de fevereiro de 2003 – Relação dos Candidatos Aprovados.</li>
                <li>Termo de Homologação, de 27 de fevereiro de 2003.</li>
                <li>Edital de Convocação, de 10 de março de 2003. </li>
              </ul>
            </div>

            <div className="text-block">
              <h3>4° Concurso Público para provimento de cargos de Procurador do Município de Fortaleza</h3>
              <ul>
                <li>Edital nº 1 – PGM, de 14 de dezembro de 2016.</li>
                <li>Edital nº 5, de 7 de fevereiro de 2003 – Relação dos Candidatos Aprovados.</li>
                <li>Edital nº 9 – PGM, de 22 de setembro de 2017 – Resultado final no Concurso.</li>
                <li>Edital de Convocação, de 10 de março de 2003. </li>
                <li>Edital de Convocação nº 01/2018, de 12 de abril de 2018.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section-middle bg-light law">
        <div className="container py-5 text-center">
          <div className="text-center">
            <strong className="h2 timeline-text">Institucionalização da PGM de Fortaleza <br></br> após a Constituição Federal de 1988</strong>
            <hr className="image-separator-container image-separator-container-primary"/>

            <div className="law-content">
              <h3 className="law-title">Lei Complementar nº 001, de 13 de setembro de 1990</h3>

              <span className="law-text"><strong>Ementa: </strong>
                Dispõe sobre a organização, competência, a estrutura da Procuradoria-Geral do Município, sobre o Regime Jurídico de seus servidores e dá outras providências.
              </span>

              <span className="law-text"><strong>Alteracoes/Modif./Revogacoes: </strong><br></br>
                Lei Complementar n.5, 25.09.91, DOM 29.10.1991, p.01, alteração, art. 100, anexos III e IV.<br></br>
                Lei n.7844 de 06.12.95, DOM 14.12.1995, legislação correlata.<br></br>
                Lei n.8640, 18.06.02, DOM 25.06.2002, p.01, alteração.<br></br>
                Lei n.8864, 29.06.04, DOM 29.06.2004, p.01, alteração.<br></br>
                Lei n.8941, 09.06.05, DOM 09.06.2005, p.01, legislação correlata.<br></br>
                Lei n.9101, 31.05.06, DOM 31.05.2006, p.02, legislação correlata.<br></br>
                Decreto n.14530, 08.11.19, DOM 19.11.2019, p.01, legislação correlata, art.104.<br></br>
                Lei Complementar n.315, 23.12.11, DOM 23.12.2021, p.01, legislação correlata.<br></br>
              </span>
            </div>

            <div className="law-content">
              <h3 className="law-title">Lei Complementar nº 001, de 13 de setembro de 1990</h3>

              <span className="law-text"><strong>Ementa: </strong>
                Dispõe sobre a organização, competência, a estrutura da Procuradoria-Geral do Município, sobre o Regime Jurídico de seus servidores e dá outras providências.
              </span>

              <span className="law-text"><strong>Alteracoes/Modif./Revogacoes: </strong><br></br>
                Lei complementar n.9, 29.06.94, DOM 30.06.1994, alteração.<br></br>
                Lei complementar n.10, 29.10.95, DOM 11.10.1995, p.01, alteração, art.6.<br></br>
                Lei n.7844, 06.12.95, DOM 14.12.1995, p.01, legislação correlata. <br></br>
                Decreto n.10015, 20.12.96, DOM 07.01.1997, p.03, regulamentação art. 24, inc.I. <br></br>
                Lei n.8748, 10.07.03, DOM 22.07.2003, p.04, legislação correlata art. 99, parágrafo único.<br></br>
                Lei Complementar n.016, 24.05.04, DOM 28.05.2004, p.01, alteração:anexo II, arts. 53, 90, 101 e 102; revogação art. 106; legislação correlata art. 4º itens 1.1 e 1.2 e art. 11.<br></br>
                Lei Complementar n.071, 23.11.09, DOM 30.11.2009, p.01, acréscimo art. 22A, Seção III, Capítulo VI, Título I; alteração arts. 3º, 4º, 6º, 10º, 12, 15, 18, 21, 22, 23, 28, 32, 33, 43 e 53; alteração anexo I e II inciso I e II; acréscido capítulo V, Título I, Seções IV, V, VI e VII e arts 13A, 13B, 13C, 13D e alteração arts. 15 e 18.<br></br>
                Lei complementar n.100, 26.12.11, DOM 02.01.2012, p.08, alteração.<br></br>
                Lei Complementar n.122, 30.11.12, DOM 12.12.2012, p.01, alteração art. 4º<br></br>
                Decreto n.13149, 29.04.13, DOM 30.04.2013, p.06, regulamentação<br></br>
                Lei Complementar n.151, 04.07.13, DOM 05.07.2013, p.13, alteração art. 33<br></br>
                Lei Complementar n.171, 27.11.14, DOM 02.12.2014, p.01, revogação art. 102 parágrafo único, alteração art.<br></br>
                4°, capítulo VI, título I.<br></br>
                Lei Complementar n.0176, 19.12.14, DOM 19.12.2014, p.07, legislação correlata. <br></br>
                Lei Complementar n.0185, 19.12.14, DOM 19.12.2014, p.34, alteração arts. 4°, 7°, § único, 12, 13-C, anexo I.<br></br>
                Lei Complementar n.0190, 22.12.14, DOM 22.12.2014, p.01, legislação correlata. <br></br>
                Decreto n.13601, 03.06.15, DOM 12.06.2015, p.01, regulamentação, Título I, Capítulo VI, Seção VIII. <br></br>
                Decreto n.13733, 28.12.15, DOM 29.12.2015, p.13, legislação correlata, art. 31-P, § 3°. <br></br>
                Lei Complementar n.0252, 03.07.18, DOM 12.07.2018, p.21, alteração arts. 12; 13-D; 31-B §§2º, 3º e 4º; 31-B2; 33; 34; 4º itens 2 e 4; acresce seção XII, art. 31-O; arts. 34-A, 34-B.<br></br>
                Lei Complementar n.0253, 03.07.18, DOM 12.07.2018, p.24, acresce §§ 4º e 5º ao art. 31-O.<br></br>
                Decreto n.14530, 08.11.19, DOM 19.11.2019, p.01, legislação correlata. <br></br>
                Lei Complementar n.0274, 20.12.19, DOM 23.12.2019, p.05, acresce art. 22-B; alteração anexo I.<br></br>
                Lei Complementar n.0283, 27.12.19, DOM 30.12.2019, p.01, legislação correlata.<br></br>
                Decreto n.14600, 27.02.20, DOM 27.02.2020, p.05, legislação correlata. (SEGER) Decreto n.14911, 20.01.21, DOM 21.01.2021, p.01, legislação correlata. (envio atos normativos)<br></br>
                Lei Complementar n.315, 23.12.11, DOM 23.12.2021, p.01, legislação correlata.<br></br>
              </span>
            </div>

            <div className="law-content">
              <h3 className="law-title"> Lei Complementar nº 009, de 29 de junho de 1994</h3>

              <span className="law-text"><strong>Ementa: </strong>
                Acresce dispositivos à Lei Complementar nº 006, de 29 de maio de 1992, renumera artigos e adota outras providências
              </span>

              <span className="law-text"><strong>Alteracoes/Modif./Revogacoes: </strong><br></br>
                Lei Complementar n.016, 24.05.04, DOM 28.05.2004, p.01, legislação correlata.<br></br>
              </span>
            </div>
          </div>

          <div className="law-content">
            <h3 className="law-title">Lei Complementar nº 010, de 29 de setembro de 1995</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Altera a Lei Orgânica da Procuradoria-Geral do Município.
            </span>

            <span className="law-text"><strong>Alteracoes/Modif./Revogacoes: </strong><br></br>
              Lei Complementar n.016, 24.05.04, DOM 28.05.2004, p.01, legislação correlata. art.1<br></br>
            </span>
          </div>


          <div className="law-content">
            <h3 className="law-title"> Lei Complementar nº 016, de 24 de maio de 2004</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Cria cargos de Procurador do Município de Fortaleza e altera dispositivos da Lei Orgânica da Procuradoria-Geral do Município de Fortaleza, Lei Complementar nº 006, de 29 de maio de 1992, com suas alterações posteriores, e dá outras providências.
            </span>

            <span className="law-text"><strong>Alteracoes/Modif./Revogacoes: </strong><br></br>
              Decreto n.14530, 08.11.19, DOM 19.11.2019, p.01, legislação correlata.<br></br>
            </span>
          </div>

          <div className="law-content">
            <h3 className="law-title"> Lei Complementar nº 071, de 23 de novembro de 2009 </h3>

            <span className="law-text"><strong>Ementa: </strong>
              Modifica a Lei Orgânica da Procuradoria-Geral do Município e dá outras providências.
            </span>

            <span className="law-text"><strong>Alteracoes/Modif./Revogacoes: </strong><br></br>
              Lei Complementar n.100, 26.12.11, DOM 02.01.2012, p.08, alteração, anexo I<br></br>
              Lei Complementar n.122, 30.11.2012, DOM 12.12.2012, p.01, alteração, Art. 22 A e anexo I<br></br>
              Decreto n.13149, 29.04.2013, DOM 30.04.2013, p.06, alteração, anexo I<br></br>
              Lei Complementar n.151, 04.07.13, DOM 05.07.2013, p.13, alteração art. 33<br></br>
              Decreto n.13222, 02.10.13, DOM 17.10.2013, p.03, legislação correlata.<br></br>
              Lei Complementar n.157, 19.12.13, DOM 26.12.2013, p.05, legislação correlata.<br></br>
              Decreto n.13365, 13.06.14, DOM 20.06.2014, p.08, legislação correlata.<br></br>
              Lei Complementar n.171, 27.11.14, DOM 02.12.2014, p.01, revogação art. 102.<br></br>
              Lei Complementar n.185, 19.12.14, DOM 19.12.2014, p.34, alteração.<br></br>
              Lei Complementar n.252, 03.07.18, DOM 12.07.2018, p.21, revogação art. 10.<br></br>
              Decreto n.13649, 25.08.15, DOM 31.08.2015, p.01, regulamentação.<br></br>
              Decreto n.14092, 27.09.17, DOM 04.10.2017, p.19, regulamentação. (matriz salarial)<br></br>
              Decreto n.14411, 02.05.19, DOM 10.05.2019, p.03, regulamentação. (matriz salarial)<br></br>
              Decreto n.14897, 29.12.20, DOM 31.12.2020, p.27, regulamentação. (matriz salarial)<br></br>
            </span>
          </div>

          <div className="law-content">
            <h3 className="law-title">Lei Complementar nº 100, de 26 de dezembro de 2011 </h3>

            <span className="law-text"><strong>Ementa: </strong>
              Modifica o Anexo I da Lei Complementar nº 0071/09, Lei Orgânica da Procuradoria-Geral do Município, e dá outras providências.
            </span>

            <span className="law-text"><strong>Alteracoes/Modif./Revogacoes: </strong><br></br>
            Decreto n.13149, 29.04.2013, DOM 30.04.2013, p.06, legislação correlata
            </span>
          </div>

          <div className="law-content">
            <h3 className="law-title"> Lei Complementar nº 122, de 30 de novembro de 2012</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Modifica a Lei Orgânica da Procuradoria-Geral do Município e dá outras providências.
            </span>

            <span className="law-text"><strong>Alteracoes/Modif./Revogacoes: </strong><br></br>
              Decreto n.13149, 29.04.2013, DOM 30.04.2013, p.06, legislação correlata
            </span>
          </div>

          <div className="law-content">
            <h3 className="law-title">Lei Complementar nº 151, de 04 de julho de 2013</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Altera o art. 33 da Lei Complementar n° 006, de 29 de maio de 1992, a Lei Orgânica da Procuradoria-Geral do Município de Fortaleza.
            </span>
          </div>


          <div className="law-content">
            <h3 className="law-title">  Lei Complementar nº 171, de 27 de novembro de 2014</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Altera a Lei Orgânica da Procuradoria-Geral do Município de Fortaleza e dá outras providências.
            </span>

            <span className="law-text"><strong>Alteracoes/Modif./Revogacoes: </strong><br></br>
              Decreto n. 13601, 03.06.15, DOM 12.06.2015, p. 01, regulamentação.<br></br>
              Decreto n. 13629, 10.07.15, DOM 29.07.2015, p. 09, legislação correlata.<br></br>
              Decreto n. 13716, 22.12.15, DOM 22.12.2015, p. 01, suplemento, legislação correlata.<br></br>
              Decreto n. 13733, 28.12.15, DOM 29.12.2015, p. 13, legislação correlata.<br></br>
              Lei Complementar n.0283, 27.12.19, DOM 30.12.2019, p.01, legislação correlata.<br></br>
              Lei n.11100, 06.04.21, DOM 07.04.2021, p.01, legislação correlata art 2º, §2º.<br></br>
              Decreto n. 14990, 20.04.21, DOM 20.04.2021, p. 01, legislação correlata.<br></br>
              Decreto n.15643, 01.06.23, DOM 02.06.2023, p.01, legislação correlata.<br></br>
            </span>
          </div>


          <div className="law-content">
            <h3 className="law-title">Lei Complementar nº 185, de 19 de dezembro de 2014</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Altera a Lei Orgânica da Procuradoria-Geral do Município (PGM) e dá outras providências.
            </span>
          </div>

          <div className="law-content">
            <h3 className="law-title">Lei Complementar nº 252, de 03 de julho de 2018</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Altera a Lei Orgânica da Procuradoria-Geral do Município de Fortaleza e dá outras providências.
            </span>
          </div>

          <div className="law-content">
            <h3 className="law-title"> Lei Complementar nº 274, de 20 de dezembro de 2019</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Modifica a Lei Orgânica da Procuradoria-Geral do Município de Fortaleza e dá outras providências.
            </span>
          </div>

          <div className="law-content">
            <h3 className="law-title">Lei Complementar nº 315, de 23 de dezembro de 2021</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Consolida e altera a Lei Orgânica da Procuradoria-Geral do Município e dá outras providências.
            </span>

            <span className="law-text"><strong>Alteracoes/Modif./Revogacoes: </strong><br></br>
              Decreto n.15220, 04.01.22, DOM 05.01.2022, p.01, regulamentação, art. 132.<br></br>
              Decreto n.15226, 10.01.22, DOM 10.01.2022, p.02, legislação correlata, art. 43, § 3º.<br></br>
              Decreto n.15444, 01.11.22, DOM 03.11.2022, p.10, legislação correlata, art. 42, § 1º.<br></br>
              Decreto n.15524, 09.01.23, DOM 09.01.2023, p.05, legislação correlata, art. 135. <br></br>
              Decreto n.15533, 18.01.23, DOM 19.01.2023, p.01, regulamentação, art. 132. Lei n.11364, 26.05.23, DOM 26.05.2023, p.01, legislação correlata, art. 42, § 1º.<br></br>
              Decreto n.15643, 01.06.23, DOM 02.06.2023, p.01, legislação correlata.<br></br>
              Lei Complementar n.0358, 19.06.23, DOM 19.06.2023, p.01, alteração, art. 5º; art. 13; art. 18; art. 20; art. 25; art. 30, § 1º; art. 32; art. 33 caput; art. 42-A acrescido; art. 46, inciso II; art. 53, inciso III, § 5º; art. 79.<br></br>
              Lei Complementar n.385, 26.12.23, DOM 26.12.2023, p.10, alteração, art. 6º, renumerado parágrafo único para numeração § 1º e acrescido § 2º; art. 30 acrescido § 3º; art. 46, inciso I; art. 47, inciso VII.<br></br>
              Lei Complementar n.386, 26.12.23, DOM 26.12.2023, p.10, alteração, caput art. 83; art.86, inciso I a III; art.84 (revogação).<br></br>
              Lei Complementar n.388, 28.12.23, DOM 28.12.2023, p.03, legislação correlata, art.42, § 1º.<br></br>
            </span>
          </div>

          <div className="law-content">
            <h3 className="law-title">Lei Complementar nº 320, de 27 de dezembro de 2021</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Institui as Câmaras de Prevenção e Resolução de Conflitos da Administração Pública municipal, na estrutura administrativa e organizacional da Procuradoria-Geral do Município de Fortaleza, e dá outras providências.
            </span>

            <span className="law-text"><strong>Alteracoes/Modif./Revogacoes: </strong><br></br>
              Lei Complementar n.334, 30.09.22, DOM 30.09.2022, p.03, Supl., legislação correlata.<br></br>
              Decreto n.15528, 10.01.23, DOM 10.01.2023, p.06, legislação correlata, art. 19,§§ 1º e 2º.<br></br>
              Decreto n.15538, 24.01.23, DOM 24.01.2023, p.12, legislação correlata, art. 12.<br></br>
              Decreto n.15636, 16.05.23, DOM 18.05.2023, p.02, regulamentação.<br></br>
              Lei n.11364, 26.05.23, DOM 26.05.2023, p.01, legislação correlata. <br></br>
              Decreto n.15643, 01.06.23, DOM 02.06.2023, p.01, legislação correlata.<br></br>
              Lei Complementar n.0358, 19.06.23, DOM 19.06.2023, p.01, alteração, art. 5º; art. 7º, § 3º.<br></br>
              Lei Complementar n.388, 28.12.23, DOM 28.12.2023, p.03, legislação correlata.<br></br>
            </span>
          </div>

          <div className="law-content">
            <h3 className="law-title">Lei Complementar nº 358, de 19 de junho de 2023</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Modifica as Leis Complementares n.º 311, de 16 de dezembro de 2021, n.º 315, de 23 de dezembro de 2021, e n.º 320, de 27 de dezembro de 2021, e dá outras providências.
            </span>
          </div>

          <div className="law-content">
            <h3 className="law-title">Lei Complementar nº 385, de 26 de dezembro de 2023</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Modifica as Leis Complementares n.º 315, de 23 de dezembro de 2021, e n.º 311, de 16 de dezembro de 2021, e dá outras providências.
            </span>
          </div>

          <div className="law-content">
            <h3 className="law-title">Lei Complementar nº 386, de 26 de dezembro de 2023</h3>

            <span className="law-text"><strong>Ementa: </strong>
              Altera a composição da remuneração do cargo de Procurador do Município, na forma que indica, e dá outras providências.
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
}
