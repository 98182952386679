import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faHouseUser  } from "@fortawesome/free-solid-svg-icons";
import Format from "../../helpers/Format";
import "./styles.css";
import { useQuery } from "../../helpers/Utils";
import Modal from "../Modal";
import Show from "../Show";

interface Props {
  inscriptionId: number;
  inscricaoAdministrativa: string;
  balanceToLegalAction: number;
  saldoDevedor: number;
  numeroCda: string;
  status: string;
  documento: string;
  numeroParcelamento: string | null;
  locked: boolean;
  selected: boolean;
  exercise: string;
  registry: string;
  registryAddress: string;
  registryPhone: string;
  phase: [];
  processNumber: string;
  onSelect(): void;
  groupPayment: string;
  refis_enabled: boolean;
  balance_to_l_action: number;
  full_address?: {
    street: string;
    street_number: string;
    neighborhood: string;
    city: string;
    state: string;
    cep: string;
  };
  receipt_type_id: number;
  attendantCounter: boolean;
}

export default function ItemInscricao({ inscriptionId, inscricaoAdministrativa, balanceToLegalAction, saldoDevedor,
                                        numeroCda, status, locked, selected, documento, numeroParcelamento, exercise,
                                        registry, registryAddress, registryPhone, phase, processNumber, onSelect, groupPayment,
                                        refis_enabled, balance_to_l_action, full_address, receipt_type_id, attendantCounter }: React.PropsWithChildren<Props>) {

  const klass: string[] = ["item-inscricao"];

  const query = useQuery();
  const digital = Boolean(query.get("digital"));
  const digitalQuery = digital ? "&digital=true" : "";
  const [showRegistryAddress, setShowRegistryAddress] = useState(false)

  if (selected) {
    klass.push("selected");
  }

  const showModal = () => {
    setShowRegistryAddress(true);
  }

  const hideModal = () => {
    setShowRegistryAddress(false);
  }

  const markInscription = (element: any) => {
    const parentElement = document.getElementById(element);
    const input = parentElement?.firstElementChild as HTMLInputElement;
    if(input.hasAttribute("checked")) {
      input.removeAttribute("checked");
    }else {
      input.setAttribute("checked", "true");
    }
  }

  const fullAddress = full_address && `${full_address.street}, ${full_address.street_number}, ${full_address.neighborhood},
  ${full_address.city}, ${full_address.state}, ${full_address.cep}`;

  return (
    <div className={klass.join(" ")}>
      <div className="item-inscricao-actions" id="itemIsnctiption">
        <div className="option">
          <div className="inscriptionInformation">
            <strong>
              <span id={numeroCda} onClick={(e) => {markInscription(e.currentTarget.id)}}>
                {
                  groupPayment == null ? <></> :
                  <input className="selectInscription"
                    type="checkbox" id="checkboxInscription"
                    data-value={saldoDevedor}
                    data-refisvalue={balanceToLegalAction}
                    data-id={inscriptionId}
                    data-grouppayment={groupPayment}
                    data-refisEnabled={refis_enabled as boolean}
                    onClick={onSelect}></input>
                  && saldoDevedor == 0 ? <></> :
                  <input className="selectInscription"
                    type="checkbox" id="checkboxInscription"
                    data-value={saldoDevedor}
                    data-refisvalue={balanceToLegalAction}
                    data-id={inscriptionId}
                    data-grouppayment={groupPayment}
                    data-refisEnabled={refis_enabled as boolean}
                    onClick={onSelect}></input>
                }
              </span>
              { locked ?
                <div>
                  <span className="administrativeInscriptionLoked">Inscrição Administrativa: {inscricaoAdministrativa}</span>
                  <Show if={attendantCounter && receipt_type_id == 4}>
                    <div className="thirdColumn" data-title={fullAddress}>
                      <span>
                        <FontAwesomeIcon icon={faHouseUser} />
                        <strong> Endereço</strong>
                      </span>
                    </div>
                  </Show>
                </div>:
                <div>
                  <span className="administrativeInscription">Inscrição Administrativa: {inscricaoAdministrativa}</span>
                  <Show if={attendantCounter && receipt_type_id == 4}>
                    <div className="thirdColumn" data-title={fullAddress}>
                      <span>
                        <FontAwesomeIcon icon={faHouseUser} />
                        <strong> Endereço</strong>
                      </span>
                    </div>
                  </Show>
                </div>
              }
            </strong>
            { locked ?
              <span className="valorLoked">
                <strong>{`${refis_enabled ? 'Saldo devedor REFIS:' : 'Saldo devedor:'}`}</strong>
                {Format.currency(saldoDevedor)}
                {refis_enabled && <span className="withoutRefisValue">{Format.currency(balance_to_l_action)}</span>}
              </span>:
              <span className="valor">
                <strong>{`${refis_enabled ? 'Saldo devedor REFIS:' : 'Saldo devedor:'}`}</strong>
                {Format.currency(saldoDevedor)}
                {refis_enabled && <span className="withoutRefisValue">{Format.currency(balance_to_l_action)}</span>}
              </span>
            }
          </div>
          <span className="line"></span>
          <div className="inscriptionContent">
            <div className="firstColumn">
              <span><strong className="labelInscription">CDA: </strong>{numeroCda}</span><br/>
              <span><strong className="labelInscription">Exercício: </strong>{exercise}</span><br/>
            </div>
            <div className="secoundColumn">
              <span>
                <strong className="labelInscription">Fase: </strong>
              <span className="phaseName"><strong>{status}</strong></span>
              {phase.map((phases) => {
                return(
                  <span className="phaseName"><strong>{phases == "protested" ? "Protestado" : <></> && phases == "legal_action" ? "Ajuizado" : <></> }</strong></span>
                )
              })
              }
              </span><br/>
              {status == 'Protestado' || status == 'Encaminhada para protesto' &&
                <>
                  <span><strong className="labelInscription">Cartório: </strong> {registry} </span>
                  { registry != undefined ?
                    <span className="addressModal"><span onClick={showModal}> Ver endereço</span></span> : <span>Insdisponível</span>
                  }
                </>
              }
              {<Modal show={showRegistryAddress} onHide={hideModal} centered={true}>
                  <div className="modalContentBody">
                    <div className="modalTittle">
                      <span>Endereço do Cartório</span>
                    </div>
                    <div className="modalContent">
                      <span><strong className="labelInscription">Cartório: </strong> {registry}</span>
                      <span><strong className="labelInscription">Endereço: </strong>{registryAddress}</span>
                      <span><strong className="labelInscription">Fone: </strong> {registryPhone}</span>
                    </div>
                  </div>
                </Modal>
              }
              {status == 'Ajuizado' ?
                <div>
                  <span><strong className="labelInscription">Número do Processo: </strong></span>
                  <span className="processNumber">{processNumber}</span>
                </div> : <></>
              }
              {phase.map((phases) => {
                return(
                  phases == "legal_action" ?
                  <div>
                    <span><strong className="labelInscription">Número do Processo: </strong></span>
                    <span className="processNumber">{processNumber}</span>
                  </div>
                  : <></> &&
                  phases == "protested" ?
                  <div>
                    <span><strong className="labelInscription">Cartório: </strong> <span className="registryName">{registry}</span></span>
                    <span className="addressModal"><span onClick={showModal}> Ver endereço</span></span>
                  </div>
                  : <></>
                )
              })
              }
            </div>
          </div>
        </div>
        {locked && (
          <div className="icon">
            <FontAwesomeIcon icon={faLock} title="Crédito não habilitado para negociação" />
          </div>
        )}
        {/* documento && numeroParcelamento && (
          <Link to={`/consultar/parcelamentos?d=${documento}&n=${numeroParcelamento}${digitalQuery}`} title="Emitir boleto do parcelamento">
            <FontAwesomeIcon icon={faBarcode} />
          </Link>
        )*/}
      </div>
    </div>
  );
}
