export const timeLineInfos = [
  {
    date: 'Maio de',
    year: '1979',
    management: 'Gestão do Procurador Geral',
    person: 'Wagner Barreira Filho',
    until: 'até março de 1983',
  },
  {
    date: 'Março de',
    year: '1983',
    management: 'Gestão do Procurador Geral',
    person: 'César Asfor Rocha',
    until: 'até julho de 1985'
  },
  {
    date: 'Agosto de',
    year: '1985',
    management: 'Gestão do Procurador Geral',
    person: 'Luiz Nivardo Cavalcante Neto',
    until: 'até dezembro de 1985'
  },
  {
    date: 'Janeiro de',
    year: '1989',
    management: 'Gestão do Procurador Geral',
    person: 'Fernando Luiz Ximenes Rocha',
    until: 'até abril de 1990'
  },
  {
    date: 'Abril de',
    year: '1990',
    management: 'Gestão do Procurador Geral',
    person: 'Valmir Pontes Filho',
    until: 'até dezembro de 1994'
  },
  {
    date: 'Janeiro de',
    year: '1995',
    management: 'Gestão do Procurador Geral',
    person: 'José Emmanuel Sampaio de Melo',
    until: 'até dezembro de 1996'
  },
  {
    date: 'Janeiro de',
    year: '1997',
    management: 'Gestão do Procurador Geral',
    person: 'Stênio Rocha Carvalho Lima',
    until: 'até maio de 1999'
  },
  {
    date: 'Maio de',
    year: '1999',
    management: 'Gestão da Procuradora Geral',
    person: 'Lucíola Maria de Aquino Cabral',
    until: 'até dezembro de 2000'
  },
  {
    date: 'Janeiro de',
    year: '2001',
    management: 'Gestão da Procuradora Geral',
    person: 'Lucíola Maria de Aquino Cabral',
    until: 'até maio de 2001'
  },
  {
    date: 'Maio de',
    year: '2001',
    management: 'Gestão do Procurador Geral',
    person: 'Rômulo Guilherme Leitão',
    until: 'até julho de 2004'
  },
  {
    date: 'Julho de',
    year: '2004',
    management: 'Gestão do Procurador Geral',
    person: 'Pedro Saboya Martins',
    until: 'até dezembro de 2004'
  },
  {
    date: 'Janeiro de',
    year: '2005',
    management: 'Gestão do Procurador Geral',
    person: 'Deodato José Ramalho Júnior',
    until: 'até março de 2006'
  },
  {
    date: 'Março de',
    year: '2006',
    management: 'Gestão do Procurador Geral',
    person: 'Martônio Mont’Alverne Barreto Lima',
    until: 'até dezembro de 2012'
  },
  {
    date: 'Janeiro de',
    year: '2013',
    management: 'Gestão do Procurador Geral',
    person: 'José Leite Jucá Filho',
    until: 'até dezembro de 2020'
  },
  {
    date: 'Janeiro de',
    year: '2021',
    management: 'Gestão do Procurador Geral',
    person: 'Fernando Antônio Costa de Oliveira',
    until: 'em exercício',
    last: true
  },
]
