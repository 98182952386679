import React, { useState} from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faShare, faPhone } from "@fortawesome/free-solid-svg-icons";
import whatsappIcon from "../../assets/images/icon-whatsapp-verde.svg";

import ModalBalcaoVirtual from "../../components/ModalBalcaoVirtual";
import ImageApp from "../../assets/images/landingPage/hotelaria-chave.jpg";

export default function Noticia() {

  const [showModalBalcaoVirtual, setShowModalBalcaoVirtual] = useState(false);

  const handleShowBalcaoVirtual = async () => {
    setShowModalBalcaoVirtual(true);
  }

  const handleHideBalcaoVirtual = () => {
    setShowModalBalcaoVirtual(false);
  }

  return (
    <>
      <Layout title="Notícia" className="" balcaoVirtual>
        <div className="">
          <div id="noticia" className="">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="noticia">
                  <div className="col-4 float-right mt-4">
                    <img className="img-news d-none d-sm-block align-items-center w-100 h-100 quadrangular" src={ImageApp} alt="" />
                  </div>
                  <div className="content d-block col-md-8 col-sm-12">
                    <a href="/noticias" className="link-direction">
                      <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                      Ver todas as notícias
                    </a>
                    <h3 className="title mt-4">Modalidade de Transação Tributária proposta para a Rede Hoteleira</h3>
                    <small className="data-new">24 de novembro de 2022</small>
                    <p className="description-new mt-4">
                      <strong>As concessões do Município de Fortaleza</strong> envolverão créditos tributários de
                      IPTU e de ISSQN inscritos em Dívida Ativa e <strong>poderão reduzir em até 100%</strong> o
                      valor das multas moratórias, dos juros de mora, da correção monetária e dos
                      honorários advocatícios, bem como, preveem um <strong>parcelamento em até 60
                      (sessenta) meses, com parcelas mensais, iguais e sucessivas;</strong> condicionado a <strong>parcela
                      mínima de R$350,00</strong> (trezentos e cinquenta reais).
                    </p>
                    <p className="description-new mt-4">
                      O cumprimento da contrapartida considerará os seguintes limites com
                      relação ao valor a ser investido pelo devedor:
                    </p>
                    <ul className="description-new">
                      <li>I- 80% dos gastos realizados com requalificação da infraestrutura ou com a modernização das instalações e dos serviços de hotelaria;</li>
                      <li>II- 20% dos gastos realizados com a capacitação e o treinamento do quadro de funcionários do empreendimento hoteleiro.</li>
                    </ul>
                    <p className="description-new mt-4">
                      <strong>O interessado deverá preencher o Requerimento de Adesão à Transação Tributária </strong>
                      a ser formalizado pelo responsável legal do CNPJ, acompanhado dos
                      documentos listados no Edital de Transação Tributária por Adesão nº01/2022,
                      item 4.3.
                    </p>
                    <br/>
                    <a href="/Edital_de_Transação_Tributária_por_Adesão_nº012022.pdf" className="link-direction">
                      Edital de Transação Tributária por Adesão nº01/2022
                    </a>
                    <br/>
                    <a href="/Requerimento_de_Adesão_à_Transação_Tributária.pdf" className="link-direction">
                      Requerimento de Adesão à Transação Tributária
                    </a>
                    <br/>
                    <a href="/Dec_15538-2023_Altera_Dec_15444-2022_Transacao_Tributaria_por_adesao.pdf" className="link-direction">
                      DECRETO Nº 15.538, DE 24 DE JANEIRO DE 2023
                    </a>
                    <br/>
                    <br/>
                    <h4 className="title">Contato</h4>
                    <p className="description-new mt-4">
                      Atendimento de Dívida Ativa <br/>
                      (Procuradoria da Fazenda)
                    </p>
                    <div className="contact-div">
                      <FontAwesomeIcon icon={faPhone} /> | <img src={whatsappIcon} alt="WhatsApp" width={25} /> <span>(85) 98419-2045</span>
                    </div>
                    <div className="contact-div">
                      <FontAwesomeIcon icon={faPhone} /> | <img src={whatsappIcon} alt="WhatsApp" width={25} /> <span>(85) 98419-2042</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalBalcaoVirtual show={showModalBalcaoVirtual} onHide={handleHideBalcaoVirtual} />
      </Layout>
    </>
  );
}
