import React, { useState } from "react";
import Show from "../../components/Show";
import Container from "../../components/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

export default function DuvidasFrequentes() {
  const [show, setShow] = useState(true);

  return (
    <Container items={[{ label: "Página Inicial", to: "/" }, { label: "Dúvidas Frequentes", active: true }]}>
      <div className="duvidas-frequentes">
        <div className="duvida">
          <button onClick={() => setShow(!show)}>
            <FontAwesomeIcon icon={show ? faChevronDown : faChevronRight} />
            Dúvidas gerais sobre a Dívida Ativa
          </button>
          <Show if={show}>
            <div className="content">
              <h3>O que é Dívida Ativa do município?</h3>
              <p>
                É o conjunto dos créditos devidos à Fazenda Pública Municipal que,
                quando não pagos até a data de seu vencimento, são cobrados pela Procuradoria da Dívida Ativa,
                administrativa ou judicialmente. Esses créditos podem ser tributários, provenientes do não pagamento dos tributos,
                bem como não tributários, tendo sua liquidez e certeza apuradas pelo órgão que os originou.
                Os créditos são inscritos em um livro eletrônico e deles é extraída uma Certidão de Dívida Ativa, conhecida como CDA.
              </p>
              <p>
                Legislação: art. 1º, §1º, §2º e art. 6º, caput, do decreto nº 13.601/2015.
              </p>

              <h3>Existe um valor mínimo para inscrição do débito na Dívida Ativa do município?</h3>
              <p>
                Não. Contudo, somente serão cobrados judicialmente créditos cujo valor consolidado por
                tributo seja superior a R$ 5.554,68 (cinco mil quinhentos e cinquenta e quatro reais e
                sessenta e oito centavos). Valores inferiores são cobrados por via administrativa: entre elas, o protesto em Cartório.
              </p>
              <p>
                Legislação: art. 203, caput, do Código Tributário Municipal.
              </p>

              <h3>Onde emitir o boleto para pagar uma dívida ainda não inscrita?</h3>
              <p>
                Junto ao órgão que originou o crédito (por exemplo, SEFIN, AMC, SEUMA ou outro), quando
                o débito ainda não houver sido remetido para cobrança pela PGM. Depois que o órgão de origem
                houver requerido a inscrição em dívida ativa, o boleto deve ser emitido pessoalmente na PGM,
                na Av. Santos Dumont 5335, 10º andar, pelo portal da PGM no endereço http://portal.pgm.fortaleza.ce.gov.br,
                na internet, ou ainda através dos APP disponível para IOS e Android.
              </p>
              <p>Legislação: art. 2º, caput do decreto nº 13.601/2015.</p>

              <h3>O que é o Protesto?</h3>
              <p>
                Protesto é um dos instrumentos que a Fazenda Pública tem para cobrar um crédito
                inscrito em Dívida Ativa. O devedor protestado fica com crédito negativo junto aos
                Bancos e demais instituições financeiras, o que lhe impossibilita de contrair
                empréstimos. Por isso, é tão importante manter em dia as obrigações perante a Fazenda Pública.
              </p>

              <h3>Onde pagar um crédito encaminhado para protesto ou protestado?</h3>
              <p>
                Quando o crédito é enviado para protesto, a PGM não pode mais emitir Boleto dentro do mês
                do envio. Essa atividade fica a cargo do Cartório que, recebendo o valor do débito e de
                suas custas no prazo estabelecido em boleto, não protestará o devedor.
              </p>
              <p>
                Ultrapassado mês do envio e não pago o crédito perante o Cartório no prazo que este
                assinalar, ocorre o protesto e o devedor fica com as restrições perante o mercado.
                Neste caso, o contribuinte deve procurar um dos canais de atendimento da PGM para pagar
                ou parcelar o débito, devendo ainda recolher as custas do Cartório. Sem esse recolhimento,
                o devedor continua negativado.
              </p>
              <p>Legislação: art. 20, caput, § 2º e art. 22 do decreto nº 13.601/2015.</p>

              <h3>Falecendo o contribuinte, a dívida fica cancelada?</h3>
              <p>
                Não. O espólio responde pelos tributos devidos até a data da abertura da sucessão.
                O sucessor e o cônjuge meeiro respondem pelos tributos até a data da partilha ou adjudicação.
              </p>
              <p>
                Legislação: art. 44, incs. II e III do Código Tributário Municipal.
              </p>

              <h3>O que é a Certidão Negativa de Débito (CND)?</h3>
              <p>
                Certidão Negativa de Débito (CND) é um documento em que a Administração Pública informa a
                situação de regularidade fiscal do contribuinte. Quando requerida sua emissão,
                a Administração Tributária tem o prazo de 10 (dez) dias para expedi-la, a contar
                da data do protocolo do pedido. O único órgão que expede CND – ou a Certidão Positiva
                com Efeitos de Negativa (CPEN) – é a Secretaria de Finanças. O pagamento e a extinção de
                débitos por outros motivos (prescrição, cancelamento, remissão e etc) são comunicados
                de forma online pela PGM à SEFIN.
              </p>
              <p>
                Legislação: art. 209 do Código Tributário Municipal e art. 29 do decreto nº 13.601/2015.
              </p>

              <h3>O que é a Certidão Positiva de Débitos com Efeitos de Negativa?</h3>
              <p>
                É um documento no qual a Administração Pública informa que, apesar do
                contribuinte possuir débitos pendentes com a Fazenda Pública Municipal,
                estes estão com sua exigibilidade suspensa ou não são exigíveis, não podendo,
                portanto, ser objeto de cobrança judicial nem de protesto em cartório.
              </p>
              <p>
                O exemplo mais comum de suspensão da exigibilidade é quando o contribuinte
                parcela débitos vencidos. Outro exemplo é quando há um mandado judicial
                que concede medida liminar em favor do contribuinte. Já os créditos não
                exigíveis são, por exemplo, aqueles valores parcelados que ainda irão
                vencer. Vale salientar que, essa certidão terá os mesmos efeitos de uma Certidão Negativa de Débitos (CND).
              </p>
              <p>
                Legislação: arts. 70 e art. 210 do Código Tributário Municipal.
              </p>

              <h3>O crédito tributário pode ter a sua cobrança suspensa?</h3>
              <p>
                Sim. Desde que ocorra pelo menos uma das seguintes hipóteses: moratória,
                parcelamento, depósito do montante integral, recurso em processo administrativo,
                concessão de medida liminar em mandado de segurança, ou concessão de medida
                liminar ou de tutela antecipada, em outras espécies de ação judicial.
              </p>
              <p>
                Legislação: art. 70, incs. I ao VI, do Código Tributário Municipal.
              </p>

              <h3>Os débitos perante a Fazenda Municipal podem ser parcelados?</h3>
              <p>
                Sim. Créditos de competência da Dívida Ativa podem ser pagos em até 30 (trinta)
                parcelas, mensais e sucessivas, desde que as parcelas não sejam
                inferiores a R$ 75,00 (setenta e cinco reais), para os parcelamentos
                concedidos a pessoa física, e R$ 350,00 (trezentos e cinquenta reais),
                para os parcelamentos concedidos a pessoa jurídica.
              </p>
              <p>
                Legislação: art. 42, Parágrafo Único, incs. I,II e do art. 49 caput, incs. I e II, do decreto nº 13.601/2015.
              </p>

              <h3>Como solicitar o parcelamento?</h3>
              <p>
                O contribuinte deverá requerer à Procuradoria Geral do Município o
                parcelamento de seu débito, o que implica a confissão formal de sua dívida.
                Ao assinar esse requerimento, o sujeito passivo indicará o débito e o número de
                parcelas que deseja pagar. No entanto, esse pedido ficará sujeito, por exemplo:
                à entrega da documentação solicitada pela PGM, bem como à assinatura no Termo de
                Reconhecimento, Confissão e Acordo para Pagamento Parcelado de Dívida para com o
                município de Fortaleza, o qual será submetido ao crivo judicial para homologação.
              </p>
              <p>
                Legislação: art. 52, caput, §1º ao §3º do decreto nº 13.601/2015.
              </p>

              <h3>Em que situação o parcelamento é perdido?</h3>
              <p>
                Quando o contribuinte deixa de pagar 03 (três) parcelas, consecutivas ou não,
                ou se não efetuar o pagamento da primeira parcela. Isso acarreta a perda
                imediata de qualquer desconto ou benefício concedido. Nesse caso, o devedor
                será notificado para pagar integralmente o débito, no prazo de até 30 (trinta)
                dias, contados da notificação. É importante lembrar que as parcelas pagas serão
                consideradas mera amortização da dívida anterior ao ajuste.
              </p>
              <p>
                Legislação: art. 38, caput, §1º, §3º e art. 56 caput, §2º do decreto nº 13.601/2015.
              </p>

              <h3>É possível fazer um reparcelamento?</h3>
              <p>
                Sim. No entanto, existe uma penalidade, além da cobrança de correção
                monetária e juros sobre a dívida: o contribuinte terá a quantidade de parcelas
                reduzidas, em relação ao parcelamento original que firmou. Exemplo: se o primeiro
                parcelamento foi firmado em 30 vezes, agora só poderá ser reparcelado em 24 vezes,
                obedecidos os critérios de valor mínimo da parcela.
              </p>
              <p>
                Legislação: art. 56, §1º do decreto nº 13.601/2015.
              </p>
            </div>
          </Show>
        </div>
      </div>
    </Container>
  );
}
