import React, { MouseEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./style.css";

interface Props {
  id: string;
  title: any;
  btnStyle?: string;
  contentStyle?: string;
  separator?: boolean;
}

export default function CollapseItem({ id, title, children, btnStyle, contentStyle, separator }: React.PropsWithChildren<Props>) {

  function renderIcon(event: MouseEvent) {
    const btn = document.querySelector(`#${event.currentTarget.id}`);
    const expanded = btn?.getAttribute("aria-expanded");
    const divIcons = btn?.querySelector(".icons")
    const iconUp = divIcons?.firstElementChild
    const iconDown = divIcons?.lastElementChild
    if (expanded == "true") {
      iconUp?.setAttribute("class", "d-block")
      iconDown?.setAttribute("class", "d-none")
    } else {
      iconUp?.setAttribute("class", "d-none")
      iconDown?.setAttribute("class", "d-block")
    }
  }

  return (
    <>
      <div className="row mt-4">
        <a id={`btnHeading${id}`} onClick={renderIcon} className={`btn btn-link col-12 ${btnStyle ? btnStyle : ""}`} data-toggle="collapse" data-target={`#collapse${id}`} aria-expanded="true" aria-controls={`collapse${id}`}>
          <div className="headingThrid content-button-collapse">
            <div className="text-left">
              <span className="subtitle title-internal-collapse">{title}</span>
            </div>
            <div className="icons icons-internal-collapse">
              <span className="d-none"><FontAwesomeIcon icon={faChevronUp} /></span>
              <span><FontAwesomeIcon icon={faChevronDown} /></span>
            </div>
          </div>
        </a>
      </div>
      <div id={`collapse${id}`} className={`collapse flex-column ${contentStyle ? contentStyle : ""}`} aria-labelledby={`heading${id}`} data-parent="#accordionExample">
        {children}
      </div>
      {separator && <hr className="line-bottom-container" />}
    </>
  );
}
