import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import Button from "../Form/Button";
import "./styles.scss";
import { Parcela } from "../../services/ConsultarService";
import Format from "../../helpers/Format";

interface Props {
  handleOnClick(value: number[]): void;
  installmentNumber: string;
  installmentsOverdue: Parcela[];
  selectedInstallments: Parcela[];
  loadingBoleto: boolean;
  totalParcelas: number;
}

const sumSelected = (selectedInstallments: number[]) => {
  const total = selectedInstallments.reduce(
    (accumulatedValue, installmentValue) => accumulatedValue + installmentValue, 0
  );
  return total;
};

export default function AlertOverdueInstallmentModal({
  installmentNumber,
  installmentsOverdue,
  selectedInstallments,
  loadingBoleto,
  handleOnClick,
  totalParcelas
}: Props) {
  return (
    <div className="content-alert">
      <div className="title">
        <FontAwesomeIcon icon={faExclamation} className={"exclamation-icon"} />
        <h4>Olá, contribuinte!</h4>
      </div>
      <div className="content-info">
        <p>
          Verificamos que você tentou gerar um boleto na negociação {installmentNumber},<br />
          para qual há {installmentsOverdue.length} parcelas em atraso (
          {installmentsOverdue.map((io) => io.installment_number).join("° / ")}º).
        </p>
        <div className="info">
          <p>A negociação poderá ser perdida se:</p>
          <ul>
            <li>- O atraso em uma parcela superar 90 dias.</li>
            <li>- Houver atraso em 3 parcelas conssecutivas.</li>
            <li>- Houver atraso na última parcela.</li>
          </ul>
        </div>
        <br />
        <p>
          Em razão disso, é indicado quitar primeiramente as parcelas em atraso.
        </p>
        <table className="table">
          <thead>
            <tr>
              <th>Parcela Atrasada</th>
              <th>Valor</th>
              <th>Vencimento</th>
              <th>Boleto</th>
            </tr>
          </thead>
          <tbody>
            {installmentsOverdue.map((io) => (
              <tr>
                <td>{io.installment_number} / {totalParcelas}</td>
                <td>{Format.currency(io.total)}</td>
                <td>{Format.date(io.due_date)}</td>
                <td>
                  <Button
                    label="Gerar Boleto"
                    loadingLabel="Gerando boleto..."
                    loading={loadingBoleto}
                    onClick={() => handleOnClick([io.installment_number])}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h4>Ou</h4>
        <p className="message">
          Clique no botão abaixo para gerar o boleto da(s) parcela(s)
          selecionadas(s)
          <br />({selectedInstallments.map((si) => `${si.installment_number}/${totalParcelas}`).join(", ")}) no
          valor de {Format.currency(sumSelected(selectedInstallments.map((si) => si.total)))}
        </p>
      </div>
      <div className="actions">
        <Button
          label="Gerar Boleto"
          loadingLabel="Gerando boleto..."
          loading={loadingBoleto}
          onClick={() =>
            handleOnClick(selectedInstallments.map((si) => si.installment_number))
          }
        />
      </div>
      <p className="observation">
        Observação: NÃO ACEITAMOS pagamentos via transferência bancária ou PIX.
      </p>
    </div>
  );
}
