import React from "react";
import Container from "../../components/Container";

import whatsappIcon from "../../assets/images/wsp-icon.png";
import "./style.css";

export default function AtendimentosEspecializados() {
  return (
    <Container items={[{ label: "Página Inicial", to: "/" }, { label: "Atendimentos especializados", active: true }]}>
      <div className="atendimento-contribuinte">
        <div className="emails">
          <h2>
            Atendimentos<br />
            <span>especializados</span>
          </h2>
          <p>
            <strong>Judiciário e MP</strong><br/>
            <a className="link-home" href="mailto:intimacoesurgentes@pgm.fortaleza.ce.gov.br">intimacoesurgentes@pgm.fortaleza.ce.gov.br</a>
          </p>

          <p>
            <strong>Procuradoria Judicial</strong><br/>
            <a className="link-home" href="mailto:projud@pgm.fortaleza.ce.gov.br">projud@pgm.fortaleza.ce.gov.br</a>
          </p>

          <p>
            <strong>Procuradoria de Meio Ambiente e Urbanismo</strong><br/>
            <a className="link-home" href="mailto:pgm.prourma@pgm.fortaleza.ce.gov.br">pgm.prourma@pgm.fortaleza.ce.gov.br</a>
          </p>

          <p>
            <strong>Procuradoria Jurídico Administrativa (Aposentadorias)</strong><br/>
            <a className="link-home" href="mailto:pja@pgm.fortaleza.ce.gov.br">pja@pgm.fortaleza.ce.gov.br</a>
          </p>

          <p>
            <strong>Procuradoria Fiscal</strong><br/>
            <a className="link-home" href="mailto:fiscal.pgm@pgm.fortaleza.ce.gov.br">fiscal.pgm@pgm.fortaleza.ce.gov.br</a>
          </p>

          <p>
            <strong>Gabinete do Procurador Geral</strong><br/>
            <a className="link-home" href="mailto:gabinete.pgm@pgm.fortaleza.ce.gov.br">gabinete.pgm@pgm.fortaleza.ce.gov.br</a><br/>
            <img src={whatsappIcon} alt="WhatsApp" width={18} /> <a className="link-home" href="https://api.whatsapp.com/send?phone=558532651613" target="_blank">(85) 3265-1613</a>
          </p>
        </div>
      </div>
    </Container>
  );
}
