import { Hash } from "crypto";
import { HasType, isConstructorDeclaration } from "typescript";
import { get, post } from "../config/api";
import { ServerData } from "../helpers/Service";

export interface ResponseDebitos {
  contributor_in?: any;
  results: number;
  more_debts: boolean;
  data: TipoReceita;
  is_update : boolean;
  contributor_name : string
  contributor_name_without_mask : string;
  over_max_debts: boolean;
}

export interface TipoReceita {
  [key: string]: Status;
}

export interface Status {
  [key: string]: StatusData;
}

export interface StatusData {
  total_inscriptions: number;
  total_balance: number;
  data: Inscription[];
  balance_to_legal_action: number;
  refis_enabled: boolean;
}

export interface Inscription {
  phases: [];
  id: number;
  group_payment: string;
  refis_enabled: boolean;
  judicial_process_number: string;
  administrative_inscription: string;
  cda: string;
  state_pt_br: string;
  inscription_date: string;
  balance: number;
  payment_request_code: string | null;
  has_restriction: boolean;
  selected?: boolean;
  debts: Debito[];
  with_state_restriction: boolean;
  penalty: boolean;
  balance_to_legal_action: number;
  requested_at: string;
  receipt_type_id: number;
  full_address: {
    street: string;
    street_number: string;
    neighborhood: string;
    city: string;
    state: string;
    cep: string;
  };
}

export interface Debito {
  protests_info: {
    0: {
      registry_name: string;
      full_address: string;
      registry_phone: string;
    }
  };
  id: number;
  fiscal_date: string;
  status: string;
  debt_balance: number;
}

interface ResponseBoleto {
  data: Billet;
}

export interface Billet {
  id: number;
  token: string;
  barcode: string;
  due_date: string;
  value: number;
  type: string;
}

export interface ResponseParcelamentos {
  results: number;
  payment_requests: Parcelamento[];
  is_updated : boolean;
  contributor_name : string;
  contributor_name_without_mask: string;
}

export interface Parcelamento {
  id: number;
  code: string;
  number_installments: number;
  requested_at: string;
  balance: number;
  amount_paid_main: number;
  status: string;
  status_pt_br: string;
  refis_enabled: boolean;
}

export interface ResponseParcelas {
  data: ResponseDataParcelas;
}

export interface ResponseDataParcelas {
  payment_request_id: number;
  paid_installments: Parcela[];
  unpaid_installments: Parcela[];
}

export interface Parcela {
  installment_number: number;
  total: number;
  due_date: string;
  generate_billet_date: string;
  selected?: boolean;
}

export interface ResponseNegociacaoInscriptions {
  inscriptions: Inscricao[];
}

export interface Inscricao {
  id: number;
  administrative_inscription: string;
  inscription_date: string;
  balance: number;
}

export interface ResponseSimulacaoParcelamento {
  installments_simulation: OpcaoParcelamento[];
}

export interface OpcaoParcelamento {
  num_installments: number;
  simulation: string;
  value_saved: string;
  refis_rule_id: number;
  discount_percent: number;
}

export interface ResponseGerarParcelamento {
  payment_request: Parcelamento;
}

export interface ResponseCancelarParcelamento {
  payment_request: Parcelamento;
  inscriptions: Inscription[];
}

interface attendanceRegistration {
  contributor_document: string;
  contributor_attendance_histories: {
    attendance_place: string;
    attendant: string;
    attendance_subject: string;
  };

}

interface attendanceRegistrationData{
  contributor_document: string;
  contributor_attendance_histories: {
    attendance_place: string;
    attendant: string;
    attendance_subject: string;
  };

}

interface ContributorNewContact {
  contributor_document: string;
  contributor_contact_histories : {
    telephone : string;
    cell_phone: string;
    email : string;
    current_contact: boolean;
  }

}

interface ContributorNewAddress {
  contributor_document: string;
  address: {
    street: string;
    street_number: string;
    neighborhood: string;
    city : string
    state : string;
    cep : string;
    address_complement : string;
    main: boolean;
  };
}

interface ContributorStatus {
  contributor_document: string | string[];
}

interface ServerResponse {
  status: number;
  message: string;
}

interface ServerResponseData {
  updated_contributor: boolean;
  contributor_name: string;
}

type ContributorAddress = {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  cep: string;
  city: string;
  state: string;
}

type ContributorContact = {
  email: string;
  cellphone: string;
}

export type Contributor = {
  data: {
    name: string;
    gender: string;
    cpf: string;
    cnpj: string;
    birth_date: Date;
    address: ContributorAddress
    contact: ContributorContact
  }
}

interface attendantData {
  id: number;
  email: string;
  name: string;
}

interface installmentData {
  contributor_document: string;
  installment_code: string;
  emailData: string
}

interface ApiCodeResponse {
  message: string;
  success: boolean;
}

export default class ConsultarService {
  public static async debitos(documento: string, tipo: string, valor: string) {
    return get<ResponseDebitos>("/consultar/debitos", {
        documento, tipo, valor,
      }).then((response) => response);
  }

  public static async gerarBoleto(idsInscricoes: number[]) {
    return post<ResponseBoleto>("/boletos/a_vista/gerar", {
        ids_inscricoes: idsInscricoes,
      }).then((response) => response.data);
  }

  public static async gerarBoletoParcelamento(idParcelamento: number, parcelas: number[]) {
    return post<ResponseBoleto>("/boletos/negociacoes/gerar", {
        id_negociacao: idParcelamento,
        num_parcelas: parcelas,
      }).then((response) => response.data);
  }

  public static async parcelamentos(documento: string, numeroParcelamento: string) {
    return get<ResponseParcelamentos>("/consultar/parcelamentos", {
        documento, numero_parcelamento: numeroParcelamento,
      });
  }

  public static async parcelas(parcelamentoId: number) {
    return get<ResponseParcelas>(`/negociacoes/${parcelamentoId}/parcelas`)
      .then((response) => response.data);
  }

  public static async inscricoes(parcelamentoId: number) {
    return get<ServerData<ResponseNegociacaoInscriptions>>(`/negociacoes/${parcelamentoId}/inscricoes`)
      .then((response) => response.data.inscriptions);
  }

  public static async simulacaoParcelamento(idInscricao: number[]) {
    return post<ServerData<ResponseSimulacaoParcelamento>>("/negociacoes/simular", { id_inscricoes: idInscricao })
      .then((response) => response.data.installments_simulation);
  }

  public static async gerarParcelamento(idsInscricoes: number[], quantidadePparcelas: number, refisRuleId: number, attendant_id?: number, document?: string) {
    return post<ServerData<ResponseGerarParcelamento>>("/negociacoes/gerar", {
      id_inscricoes: idsInscricoes, qtd_parcelas: quantidadePparcelas, refis_rule_id: refisRuleId, attendant_id, document
      }).then((response) => response.data.payment_request);
  }

  public static async cancelarParcelamento(idsInscricoes: number[], numeroParcelamento: string) {
    return post<ServerData<ResponseCancelarParcelamento>>("/negociacoes/cancelar", {
      ids_inscricoes: idsInscricoes, numero_parcelamento: numeroParcelamento,
    }).then((response) => response.data);
  }

  public static async attendanceRegistration(attendanceData : attendanceRegistration) {
    return post<ServerResponse>("/update_contributor/attendance", attendanceData).then((response) => response);
  }

  public static async contributorNewContact(contactData: ContributorNewContact) {
    return post<ServerResponse>("/update_contributor/contact", contactData ).then((response) => response);
  }

  public static async contributorAddress(adressData: ContributorNewAddress) {
    return post<ServerResponse>("/update_contributor/address", adressData).then((response) => response);
  }

  public static async contributorStatus(contributorData: ContributorStatus) {
    return get<ServerResponseData>("/consultar/contributors", contributorData).then((response) => response);
  }

  public static async contributor(contributorData: ContributorStatus) {
    return get<Contributor>("/consultar/contributor", contributorData).then((response) => response);
  }

  public static async attendant(attendantData: number) {
    return get<attendantData>("consultar/attendant", attendantData).then((response) => response);
  }

  public static async sendEmailConfirmation(installmentData: installmentData) {
    return get<ApiCodeResponse>("consultar/send_mail", installmentData).then((response) => response);
  };
}
