import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  name: string;
  mask?(value: string): { unmask(): string; mask(): string };
}

export default function Input({ label, name, mask, ...rest }: React.PropsWithChildren<Props>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, error } = useField(name);
  const klass = ["form-control"];

  if (error) {
    klass.push("is-invalid");
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value && inputRef.current && mask) {
      inputRef.current.value = mask(value).mask();
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue(ref: HTMLInputElement) {
        if (mask) {
          return mask(ref.value).unmask();
        }
        return ref.value;
      },
    });
  }, [fieldName, registerField, mask]);

  return (
    <div className="form-group">
      {label && <label className="form-label">{label}</label>}
      <input className={klass.join(" ")} ref={inputRef} onChange={onChange} {...rest} />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}
