import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'
import logoPgm from "../../../assets/images/landingPage/logo-prefeitura-PGM.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "./style.css";
interface Props {
  menuName?: string;
}

export default function Navbar({ menuName }: React.PropsWithChildren<Props>) {
  const location = useLocation();

  function currentPageActive() {
    let idLink = location.pathname.replace('/', '').concat("-link");
    if (menuName) {
      idLink = menuName.concat("-link");
    }
    const link = document.getElementById(idLink);
    link?.classList.add("active-link")
  }

  useEffect(() => {
    const idLink = sessionStorage.getItem("idLink")
    if (idLink) {
      const elem = document.getElementById(idLink)
      if (elem) {
        elem.scrollIntoView()
      }
    }
    currentPageActive();
  }, [location])

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light pt-3">
        <a className="navbar-brand pl-3 pt-2" href="/">
          <img src={logoPgm} alt="Logo da PGM" width={140} />
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse mb-auto" id="navbarNav">
          <ul className="navbar-nav ml-auto mr-5">
            <li className="nav-item px-2">
              <Link to="/" id="home-link" className="nav-link nav-link-default" title="Home">
                Home
              </Link>
            </li>
            <li className="nav-item px-2">
              <div className="dropdown dropdown-menu-main" >
                <button className="btn dropdown-toggle nav-link dropdown-nav-link "
                  type="button" id="servicos-link"
                  data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  Serviços
                </button>
                <ul className="dropdown-menu flex-column" aria-labelledby="servicos-link">
                  <li>
                    <Link to="/consultar/debitos/em_aberto" className="dropdown-item" title="Consultar Dívidas">
                      Consultar Dívidas
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <Link to="/consultar/parcelamentos" className="dropdown-item" title="Consultar Parcelamentos">
                      Consultar Parcelamentos
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <Link to="/consultar/protestos" className="dropdown-item" title="Títulos Protestados">
                      Títulos Protestados
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <Link to="/validar-documento" className="dropdown-item" title="Validar Assinatura">
                      Validar Assinatura
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <a
                      href="https://signon.thomsonreuters.com/?productid=EREAD&viewproductid=EREAD&returnto=https%3A%2F%2Fproview.thomsonreuters.com%2Flogin.html&culture=pt-BR&lr=0&bhcp=1"
                      className="dropdown-item"
                      target="_blanck"
                    >
                      Biblioteca Digital
                    </a>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <Link to="/servicos" className="dropdown-item" title="Ver todos">
                      Ver todos
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item px-2">
              <div className="dropdown dropdown-menu-main" >
                <button className="btn dropdown-toggle nav-link dropdown-nav-link"
                  type="button" id="institucional-link"
                  data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  Institucional
                </button>
                <ul className="dropdown-menu flex-column" aria-labelledby="servicos-link">
                  <li>
                    <Link to="/apgm" className="dropdown-item" title="A PGM">
                      A PGM
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <Link to="/quem_e_quem" className="dropdown-item" title="Notícias">
                      Quem é quem
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <Link to="/leis_regulamentadoras" className="dropdown-item" title="Leis regulamentadoras">
                      Leis regulamentadoras
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <Link to="/central_licitacao" className="dropdown-item" title="Central de Licitações de Fortaleza">
                      Central de Licitações de Fortaleza
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <Link to="/noticias" className="dropdown-item" title="Notícias">
                      Notícias
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <a href="/Manual_Codigo_de_Etica.pdf" className="dropdown-item" title="Notícias" target="_blank">
                      Código de ética
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item px-2">
              <Link id="duvidas-link" to="/duvidas" className="nav-link nav-link-default" title="Dúvidas">
                Dúvidas
              </Link>
            </li>
            <li className="nav-item px-2">
              <Link id="contatos-link" to="/contatos" className="nav-link nav-link-default" title="Contatos">
                Contatos
              </Link>
            </li>
            <li className="nav-item px-2">
              <Link to="/login" id="login-link" className="nav-link nav-link-default" data-tip data-for="login-tooltip">
                <FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon>
              </Link>
              <ReactTooltip backgroundColor={"#fff"} className="tooltip-navbar" id="login-tooltip" place="bottom" effect="solid">
                Login Área do Atendente
              </ReactTooltip>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
